// slices/sessionSlice.tsx

import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState, AppDispatch } from '../store';
import { startTimeout, resetTimeout } from '../components/timeoutUtils';

interface SessionState {
  sessionExpired: boolean;
  timeoutId: number | null;
  error: string | null;
};

const initialState: SessionState = {
  sessionExpired: false,
  timeoutId: null,
  error: null,
};

export const manageSessionTimeout = createAsyncThunk(
  'session/manageSessionTimeout',
  async (_, { dispatch, getState }) => {
    const { session } = getState() as { session: SessionState };
    if (session.timeoutId !== null) {
      clearTimeout(session.timeoutId);
    }
    const timeoutId = startTimeout(dispatch, () => dispatch(sessionExpired(true) as any));
    dispatch(setTimeoutId(Number(timeoutId)));
  }
);

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    sessionExpired: (state, action: PayloadAction<boolean>) => {
      state.sessionExpired = action.payload;
      if (action.payload) {
        // Clear the timeout if the session is expired to prevent memory leaks
        if (state.timeoutId !== null) {
          clearTimeout(state.timeoutId);
          state.timeoutId = null;
        }
        // state.sessionExpired = false; // Reset the session expired state
      }
    },
    setTimeoutId: (state, action: PayloadAction<number | null>) => {
      if (state.timeoutId !== null) {
        clearTimeout(state.timeoutId);
      }
      state.timeoutId = action.payload;
    },
    clearSessionTimeout: (state) => {
      if (state.timeoutId !== null) {
        clearTimeout(state.timeoutId);
        state.timeoutId = null;
      }
      state.sessionExpired = false; 
    },
    setSessionError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    // other thunks
  },
});

export const {
  sessionExpired,
  setTimeoutId,
  clearSessionTimeout,
  setSessionError
} = sessionSlice.actions;

export default sessionSlice.reducer;
