import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, TextField, Button, Select, MenuItem, FormControl, InputLabel, OutlinedInput, Chip } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { Organization, ShiftOptions } from '../../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface AddPatientModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  pcpName: string;
  setPcpName: (name: string) => void;
  appointmentDate: DateTime | null;
  setAppointmentDate: (date: DateTime | null) => void;
  appointmentLength: number;
  setAppointmentLength: (length: number) => void;
  contactMethods: ('text' | 'call')[];
  setContactMethod: (methods: ('text' | 'call')[]) => void;
  shift: ShiftOptions;
  setShift: (shift: ShiftOptions) => void;
};

const CreateAppointmentModal: React.FC<AddPatientModalProps> = ({ open, onClose, pcpName, setPcpName, appointmentDate, 
  setAppointmentDate, appointmentLength, setAppointmentLength, contactMethods, setContactMethod, onSuccess, shift, setShift }) => {
  const organization: Organization | null = useSelector((state: RootState) => state.organization.organization);
  const [physicianNames, setPhysicianNames] = useState<string[]>(organization?.physicianNames || []);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleSetAppointmentDate = (newDate: DateTime | null) => {
    if (!newDate) return;

    // if in the past, return and set error message
    if (newDate < DateTime.now()) {
      setErrorMessage('Appointment date cannot be in the past');
      return;
    }

    setAppointmentDate(newDate);
    setErrorMessage('');
  };

  useEffect(() => {
    if (appointmentDate && appointmentDate < DateTime.now()) {
      setAppointmentDate(DateTime.now().plus({ days: 2 }));
    }
  }, [appointmentDate]);

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage('');
      }, 5000);
    }
  }, [appointmentDate]);

  useEffect(() => {
    if (!organization) return;
    setPhysicianNames(organization.physicianNames || {});
  }, [organization?.physicianNames]);

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 400,
        borderRadius: 2,
        overflow: 'auto',
        maxHeight: '80vh'
      }}>
        <Typography variant="h6" sx={{ mb: 2 }}>Create Appointment</Typography>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="pcpName-label">Primary provider</InputLabel>
          <Select
            labelId="pcpName-label"
            id="pcpName"
            value={pcpName}
            label="Primary provider"
            onChange={(e: any) => setPcpName(e.target.value)}
          >
            { physicianNames.map((pt: string) => (
              <MenuItem value={pt} key={pt}>{pt}</MenuItem>
            )) }
          </Select>
        </FormControl>
        <DateTimePicker
          label="Appointment date"
          value={appointmentDate}
          onChange={handleSetAppointmentDate}
        />
        <br />
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="priority-label">Appointment length</InputLabel>
          <Select
            labelId="appointmentLength-label"
            id="priority"
            value={appointmentLength}
            label="appointmentLength"
            onChange={(e: any) => setAppointmentLength(parseInt(e.target.value))}
          >
            <MenuItem value={30}>30 mins</MenuItem>
            <MenuItem value={40}>40 mins</MenuItem>
            <MenuItem value={45}>45 mins</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="priority-label">AM / PM</InputLabel>
          <Select
            labelId="shift-label"
            id="shift"
            value={shift}
            label="Shift"
            onChange={(e: any) => setShift(e.target.value)}
          >
            <MenuItem value={ShiftOptions.AM}>AM</MenuItem>
            <MenuItem value={ShiftOptions.PM}>PM</MenuItem>
            <MenuItem value={ShiftOptions.anyShift}>AM/PM</MenuItem>
          </Select>
        </FormControl>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>Cancel</Button>
          <Button disabled={!!errorMessage} variant="contained" onClick={onSuccess}>Add</Button>
        </Box>
        { errorMessage && <Typography color="error">{errorMessage}</Typography> }
      </Box>
    </Modal>
  );
};

export default CreateAppointmentModal;
