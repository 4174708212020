import React from 'react';
import styled from 'styled-components';
import PersonOffIcon from '@mui/icons-material/PersonOff';

const StyledNoAssistantsCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  border-radius: 20px;
  padding: 20px;
  text-align: center;
  background: #f0f0f0;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  margin: 20px auto; // Center the card horizontally with some margin on top and bottom

  svg {
    font-size: 4rem;
    color: #9e9e9e; // A neutral icon color
    margin-bottom: 20px;
  }

  h2 {
    margin: 0;
    color: #757575; // Slightly darker text color for contrast
    font-size: 1.5rem;
  }

  p {
    color: #9e9e9e; // Match the icon color for a cohesive look
  }

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px); // Lift the card up slightly
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }
`;

const NoAssistantsCard = () => (
  <StyledNoAssistantsCard>
    <PersonOffIcon />
    <h2>No patients</h2>
    <p>Please try again with another appointment configuration.</p>
  </StyledNoAssistantsCard>
);

export default NoAssistantsCard;
