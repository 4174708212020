import React from 'react';
import styled from 'styled-components';
import { Avatar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { formatIsoToCustomDateStringWithEEEEHHMMA, statusColors, statusIcons } from '../../utils';
import { WaitlistPatient } from '../../types';
import { PatientInfo, PhysicianInfo, Row, StyledAvatar, StyledIconButton } from '../styles';

interface ArchivedTakenWaitlistAppointmentRowProps {
  physicianName: string;
  appointmentDate: string;
  onClick: () => void;
  appointmentLength: number;
  patient: WaitlistPatient;
}

const ArchivedTakenWaitlistAppointmentRow: React.FC<ArchivedTakenWaitlistAppointmentRowProps> = ({
  physicianName,
  appointmentDate,
  appointmentLength,
  onClick,
  patient,
}) => {
  return (
    <>
      { !!patient && (
        <Row onClick={onClick}>
          <PhysicianInfo>
            <div>{physicianName}</div>
            <div>{formatIsoToCustomDateStringWithEEEEHHMMA(appointmentDate)}</div>
            <div>{appointmentLength} minutes</div>
          </PhysicianInfo>
          <PatientInfo>
            <div>{patient?.patientFirstName} {patient?.patientLastName}</div>
            <StyledAvatar sx={{ bgcolor: statusColors[patient.status] }}>
              {statusIcons[patient.status]}
            </StyledAvatar>
          </PatientInfo>
        </Row>
      )}
    </>
  );
};

export default ArchivedTakenWaitlistAppointmentRow;
