import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { FrontendUser } from '../types';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { useSnackbar } from '../providers/SnackbarProvider';
import { ReactComponent as CalendlyIntegrationLogo } from "../assets/calendly.svg";
import FaxPlusIntegrationLogo from '../assets/faxplus.png';
import CalendlyIntegrationCard from '../components/cards/CalendlyIntegrationCard';
import FaxPlusIntegrationCard from '../components/cards/FaxPlusIntegrationCard';

const HorizontalBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '20px', // Adjust gap as needed
});

const Settings: React.FC = () => {
  const { showMessage } = useSnackbar();
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const [message, setMessage] = useState('');

  return (
    <>
      <div>
        <h1>Settings</h1>
        <h2>Integrations</h2>
        <HorizontalBox>
          <CalendlyIntegrationCard
            Icon={CalendlyIntegrationLogo}
            name={"Calendly"}
            subtitle={"Connect your calendar to your Somn Assistant"}
          />
          <FaxPlusIntegrationCard
            Icon={FaxPlusIntegrationLogo}
            name={"Fax.Plus"}
            subtitle={"Connect your faxes to your Somn Assistant"}
          />
        </HorizontalBox>
        {message && <p>{message}</p>}
      </div>
    </>
  );
  
};

export default Settings;
