import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, IconButton, Tooltip } from '@mui/material';
import { formatIsoToCustomDateStringWithEEEEHHMMA } from '../../utils';
import CloseIcon from '@mui/icons-material/Close';
import { Row } from '../styles';
import { OpenWaitlistAppointment, Organization, WaitlistOutcomes, WaitlistRun, WaitlistStatus } from '../../types';
import { useSelector } from 'react-redux';
import { selectWaitlistRuns } from '../../slices/waitlistRunsSlice';
import LoopIcon from '@mui/icons-material/Loop';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { RootState } from '../../store';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import LoadingWithSubtitle from '../subcomponents/LoadingWithSubtitle';

const Info = styled.div`
  color: #333; // Dark grey for text
  font-size: 16px; // Regular text size
`;

const StyledButton = styled(Button)`
  background-color: #3B5BEA;
  color: #ffffff;
  &:hover {
    background-color: #2e47c9;
  }
`;

interface PhysicianRowProps {
  appointment: OpenWaitlistAppointment;
  onClick: () => void;
  onDelete: () => void;
  loadingGettingWaitlistRuns: boolean;
}

// PhysicianRow component
const PhysicianRow: React.FC<PhysicianRowProps> = ({ appointment, onClick, onDelete, loadingGettingWaitlistRuns }) => {
  const { assistantId } = useParams<{ assistantId: string; }>();
  const waitlistRuns: WaitlistRun[] = useSelector(selectWaitlistRuns);
  const [correspondingWaitlistRun, setCorrespondingWaitlistRun] = useState<WaitlistRun | null>(waitlistRuns.find((waitlistRun: WaitlistRun) => waitlistRun.physicianName === appointment.pcpName &&
    waitlistRun.appointmentDate === appointment.appointmentDate) || null); // the corresponding waitlist run id is defined by a matching physician name and appointment date

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setCorrespondingWaitlistRun(waitlistRuns.find((waitlistRun: WaitlistRun) => waitlistRun.physicianName === appointment.pcpName &&
      waitlistRun.appointmentDate === appointment.appointmentDate) || null);
  }, [waitlistRuns, appointment.pcpName, appointment.appointmentDate]);

  useEffect(() => {
    setCorrespondingWaitlistRun(waitlistRuns.find((waitlistRun: WaitlistRun) => waitlistRun.physicianName === appointment.pcpName &&
      waitlistRun.appointmentDate === appointment.appointmentDate) || null);
  }, [waitlistRuns, appointment.pcpName, appointment.appointmentDate]);
    

  // assistants/:assistantId/runs/:waitlistRunId
  const handleGoToWaitlistRun = () => {
    if (!correspondingWaitlistRun || !correspondingWaitlistRun?.waitlistRunId) return;
  
    // Check if 'runs' is already in the current path
    const pathIncludesRuns = location.pathname.includes('/runs');
    const waitlistRunId = correspondingWaitlistRun.waitlistRunId;
  
    // Conditionally format the path
    const basePath = `/assistants/${assistantId}`;
    const newPath = pathIncludesRuns ? `${basePath}/${waitlistRunId}` : `${basePath}/runs/${waitlistRunId}`;
  
    navigate(newPath);
  };

  return (
    <Row onClick={handleGoToWaitlistRun}>
      <Info>
        <div>{appointment.pcpName}</div>
        <div>{formatIsoToCustomDateStringWithEEEEHHMMA(appointment.appointmentDate)}</div>
        <div>{appointment.appointmentLength} minutes</div>
        <div>{appointment.shift}</div>
      </Info>
      { loadingGettingWaitlistRuns && <LoadingWithSubtitle subtitle="Loading..." size={20} /> }
      { !correspondingWaitlistRun && !loadingGettingWaitlistRuns && (
        <StyledButton onClick={(event: any) => {
          event.stopPropagation();
          onClick();
        }}>
          Contact patients
        </StyledButton>
      )}
      { correspondingWaitlistRun && correspondingWaitlistRun?.status === WaitlistStatus.completed && correspondingWaitlistRun.outcome === WaitlistOutcomes.unscheduled && (
        <Tooltip title="Run is complete but everybody declined.">
          <IconButton>
            <RemoveDoneIcon />
          </IconButton>
        </Tooltip>
      )}
      { correspondingWaitlistRun && correspondingWaitlistRun?.status === WaitlistStatus.started && (
        <Tooltip title="Run is in progress">
          <IconButton>
            <LoopIcon />
          </IconButton>
        </Tooltip>
      )}
      { correspondingWaitlistRun && correspondingWaitlistRun?.status === WaitlistStatus.paused && (
        <Tooltip title="Run is paused">
          <IconButton>
            <PhonePausedIcon />
          </IconButton>
        </Tooltip>
      )}
      { correspondingWaitlistRun && correspondingWaitlistRun?.status === WaitlistStatus.notStarted && (
        <Tooltip title="Run has not started">
          <IconButton>
            <PlayCircleFilledWhiteIcon />
          </IconButton>
        </Tooltip>
      )}
      <IconButton onClick={(event: any) => {
        event.stopPropagation();
        onDelete();
      }}>
        <CloseIcon />
      </IconButton>
    </Row>
  );
};

export default PhysicianRow;
