import React, { useState, useEffect, ReactNode } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { FrontendUser } from '../../types';
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { StyledCircularProgress, StyledDisconnectButton, StyledDisconnectButtonFaxPlus, StyledFaxPATButton, StyledPasswordTextField, TextFieldWithBottomPadding } from '../styles';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { IntegrationCard, StyledProgressContainer } from '../styles';
import { encryptTokenWithPublicKey } from '../../utils';
import { sessionExpired } from '../../slices/sessionSlice';

interface IntegrationCardProps {
  Icon: ReactNode // Accepts both React elements and string URLs
  name: string;
  subtitle: string;
}

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0; // Ensure there's no top margin pushing content down
  padding-top: 0; // Ensure no padding at the top
`;

const TextContainer = styled.div`
  margin-top: 10px; // Reduced space between the image and text
`;

const FaxPlusIntegrationCard: React.FC<IntegrationCardProps> = ({ Icon, name, subtitle }) => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const { showMessage } = useSnackbar();
  const [token, setToken] = useState('');
  const [isIntegrated, setIsIntegrated] = useState(false);
  const [showAestheticField, setShowAestheticField] = useState(true);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    handleCheckIntegration();
  }, []);

  useEffect(() => {
    if (token === '') {
      setShowAestheticField(true); // Switch back to aesthetic field when text is cleared
    }
  }, [token]);

  const handleTokenUpload = async (token: string) => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      if (!backendUrl) {
        throw new Error('Backend URL not found');
      }

      if (!user?.id) {
        throw new Error('User ID not found');
        // logout
      }

      const userId = user.id;

      if (!token) {
        showMessage("Please enter a valid PAT token.");
        return;
      }

      const encryptedPatToken: string = await encryptTokenWithPublicKey(token);

      const response = await axios.post(`${backendUrl}/api/integrations/faxplus/token/upload`, 
      {
        userId: userId,
        encryptedPatToken: encryptedPatToken
      },
      {
        headers: {
          'Authorization': `Bearer ${user?.token}`,
        },
      });

      if (response.status === 200) {
        showMessage("Securely uploaded PAT token.");
        setIsIntegrated(true);
        setToken('');
      } else {
        // showMessage("Failed to upload PAT token. Please refresh the page and try again.");
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        showMessage('Session has expired', 'error');
        dispatch(sessionExpired(true) as any);
      } else {
        console.error('Error updating PAT token:', error);
        // showMessage("Failed to upload PAT token. Please refresh the page and try again.");
      }
    }
  };

  const handleCheckIntegration = async () => {
    try {
      setLoading(true);
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      if (!backendUrl) {
        throw new Error('Backend URL not found');
      }

      if (!user?.id) {
        throw new Error('User ID not found');
      }

      const userId: string = user.id;

      const response = await axios.get(`${backendUrl}/api/integrations/faxplus/token/check?userId=${userId}`, 
      {
        headers: {
          'Authorization': `Bearer ${user?.token}`,
        },
      });

      if (response.status === 200) {
        // setIsIntegrated(response.data.status);
        setIsIntegrated(true); // TODO: Change this to response.data.status
      } else {
        // showMessage("Failed to get Fax.plus integration status. Please refresh the page and try again.");
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        showMessage('Session has expired', 'error');
        dispatch(sessionExpired(true) as any);
      } else {
        console.error('Error checking Fax.plus integration:', error);
        // showMessage("Failed to get Fax.plus integration status. Please refresh the page and try again.");
      }
    }
    finally {
      setLoading(false);
    }
  };

  const handleDeleteIntegration = async () => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      if (!backendUrl) {
        throw new Error('Backend URL not found');
      }

      if (!user?.id) {
        throw new Error('User ID not found');
      }

      const userId: string = user.id;

      const response = await axios.delete(`${backendUrl}/api/integrations/faxplus/token/delete?userId=${userId}`,
      {
        headers: {
          'Authorization': `Bearer ${user?.token}`,
        },
      });

      if (response.status === 200) {
        showMessage("Successfully disconnected from Fax.plus.");
      } else {
        // showMessage("Failed to disconnect from Fax.plus. Please refresh the page and try again.");
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        showMessage('Session has expired', 'error');
        dispatch(sessionExpired(true) as any);
      } else {
        console.error('Error disconnecting from Fax.plus:', error);
        // showMessage("Failed to disconnect from Fax.plus. Please refresh the page and try again.");
      }
    }
  };

  const handleFocus = () => {
    setShowAestheticField(false); // Switch to editable TextField on focus
  };

  // const handleFocusForTextField = () => {
  //   setShowAestheticField(false);
  // }

  const handleTokenChange = (e: any) => {
    const newToken = e.target.value;
    setToken(newToken);
    if (newToken === '') {
      setShowAestheticField(true); // Switch back to aesthetic field when text is cleared
    }
  };

  return (
    <IntegrationCard>
      <CenteredDiv>
        {typeof Icon === 'string' ? (
          <img src={Icon as string} alt={name} style={{ width: '150px', height: '150px' }} />
        ) : (
          <>{Icon}</>
        )}
      </CenteredDiv>
      <div>
        <TextContainer>
          <h3 style={{ margin: '5px 0' }}>{name}</h3>
          <p style={{ margin: '5px 0' }}>{subtitle}</p>
        </TextContainer>
        {loading && (
          <StyledProgressContainer>
            <StyledCircularProgress size={12} />
          </StyledProgressContainer>
        )}
        {!loading && !isIntegrated && (
          <>
            <TextFieldWithBottomPadding
              label="Personal Access Token"
              variant="outlined"
              value={token}
              onChange={(e) => setToken(e.target.value)}
              fullWidth
            />
            <StyledFaxPATButton onClick={() => handleTokenUpload(token)}>
              Submit Token
            </StyledFaxPATButton>
          </>
        )}
        {!loading && isIntegrated && (
          <>
            {showAestheticField ? (
              <>
                <StyledPasswordTextField
                  variant="outlined"
                  type="text"
                  placeholder="●●●●●●●●"
                  onFocus={handleFocus}
                />
                <StyledDisconnectButtonFaxPlus onClick={handleDeleteIntegration}>
                  Disconnect Fax.Plus
                </StyledDisconnectButtonFaxPlus>
              </>
            ) : (
              <>
                <TextFieldWithBottomPadding
                  label="Personal Access Token (PAT)"
                  variant="outlined"
                  value={token}
                  onChange={handleTokenChange}
                  fullWidth
                  onFocus={handleFocus}
                />
                <StyledFaxPATButton onClick={() => handleTokenUpload(token)}>
                  Update token
                </StyledFaxPATButton>
              </>
            )}
          </>
        )}
      </div>
    </IntegrationCard>
  );
};

export default FaxPlusIntegrationCard;
