import styled from 'styled-components';
import { Card, CardContent, Typography, IconButton, Box, Divider, Button, TextField, ListItem, Grid, CardMedia, Tab, Tabs, AppBar, FormControl, CardActions, Checkbox, Modal, ToggleButton, Avatar, Link, Tooltip } from '@mui/material';
import { ReactComponent as CalendlyIntegrationLogo } from "../assets/calendly.svg";
import { CircularProgress } from '@mui/material';
import { CallActionProps, CallOutcomes, WaitlistPatientStatus, Consent } from '../types';
import { callOutcomeIcons, callOutcomeMessages, statusColors, statusIcons, statusMessages } from '../utils';
import { Colors } from '../Colors';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MessageIcon from '@mui/icons-material/Message';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AddIcon from '@mui/icons-material/Add';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { DateTime } from 'luxon';
import CloseIcon from '@mui/icons-material/Close';

export const InputField = styled(TextField)`
  margin-bottom: 16px; /* Spacing between input fields */
  width: 100%; /* Full width */
`;

export const TestButton = styled(Button)`
  margin-top: 16px; /* Spacing above the button */
  background-color: #1976d2; /* Example button color */
  color: white;

  &:hover {
    background-color: #115293;
  }
`;

export const LayoutContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const ContentContainer = styled(Box)`
  flex-grow: 1;
  margin-right: 20px; /* Adjust the margin as needed */
`;

export const AppBarContainer = styled(Box)`
  display: flex;
  justify-content: center;
  background-color: transparent;
  padding: 8px;
  margin-bottom: 16px;
`;

export const ToggleTabButton: any = styled(Button)<{ active: boolean }>`
  border-radius: 20px;
  border: 1px solid #d4d4d4;
  color: ${({ active }) => (active ? '#fff' : '#000')};
  background-color: ${({ active }) => (active ? '#1976d2' : 'transparent')};
  &:hover {
    background-color: ${({ active }) => (active ? '#1976d2' : '#f4f4f4')};
  }
  margin: 0 8px;
  padding: 6px 16px;
`;

export const StyledAssistantCard = styled(Card)`
  max-width: 400px;
  margin: 20px auto;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }
`;

export const AssistantInfoContainer = styled.div`
  display: flex;
  align-items: center; // Align items vertically in the middle
  margin-bottom: 20px; // Add some space below the container
`;

export const StyledAssistantName = styled(Typography)`
  margin-left: 32px; // Space between the image and the name
  padding-left: 16px; // Add some padding to the left of the name
  font-weight: bold; // Optional: make the name bold
`;

export const AssistantImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center; // Ensure the title and button are vertically aligned
`;

export const Title = styled.h1`
  margin: 0;
  margin-right: 8px; // Small space between the title and the refresh button
`;

// Assuming fetchData is your function to refresh the calls
export const RefreshButton = styled(IconButton)`
  padding: 8px;
  margin-top: 0px; // Adjust if necessary to vertically align with the title
  color: #757575; // Adjust the color to match your design
`;

export const StyledToggleButton = styled(ToggleButton)`
  margin-top: 20px;
  padding-right: 20px; // Space between toggle button and message

  &.MuiToggleButton-root {
    padding: 0px; // Adjust padding as needed
  }

  .MuiSvgIcon-root { // Target the icons within the toggle button
    font-size: 3rem; // Increase icon size
  }
`;

export const StyledToggleButtonVariantOne = styled(ToggleButton)`
  padding-right: 20px; // Space between toggle button and message

  &.MuiToggleButton-root {
    padding: 0px; // Adjust padding as needed
  }

  .MuiSvgIcon-root { // Target the icons within the toggle button
    font-size: 3rem; // Increase icon size
  }
`;

export const StatusMessage = styled(Typography)({
  marginTop: '16px', // Space between toggle button and message
  paddingTop: '16px', // Add some space above the message
  marginLeft: '20px', // Space between toggle button and message
  paddingLeft: '10px',
  display: 'inline-flex', // Align inline with the toggle button
  alignItems: 'center', // Center-align with the toggle button
  fontSize: '1rem', // Adjust the font size as needed
});

export const StatusMessageVariantOne = styled(Typography)({
  // paddingTop: '16px',
  paddingLeft: '10px',
  display: 'inline-flex',
  alignItems: 'center',
  fontSize: '1rem',
  fontWeight: 'bold',
});

interface CustomOvalOutlineListItemProps {
  maxWidth?: string;
}

export const IconWithHover = styled(IconButton)`
  &:hover {
    background-color: #f5f5f5; // Light grey background on hover
  }
`;

export const OvalOutlineListItem = styled(ListItem)<CustomOvalOutlineListItemProps>`
  display: flex;
  flex-direction: row; // use row for a horizontal layout since ListItem is typically horizontal
  align-items: center;
  border-radius: 50px; // gives an oval shape
  border: 1px solid #e0e0e0; // use a light grey border, for example
  padding: 8px 16px; // add padding as needed
  margin-bottom: 8px; // separates each ListItem from the next
  max-width: ${({ maxWidth = '100%' }) => maxWidth}; // ensure maxWidth always has a value
  cursor: pointer; // makes the entire ListItem appear clickable
  &:hover {
    background-color: #f5f5f5; // light grey background on hover, for instance
  }
`;

export const OvalOutlineListItemWithoutHover = styled(ListItem)<CustomOvalOutlineListItemProps>`
  display: flex;
  flex-direction: row; // use row for a horizontal layout since ListItem is typically horizontal
  align-items: center;
  border-radius: 50px; // gives an oval shape
  border: 1px solid #e0e0e0; // use a light grey border, for example
  padding: 8px 16px; // add padding as needed
  margin-bottom: 8px; // separates each ListItem from the next
  max-width: ${({ maxWidth = '100%' }) => maxWidth}; // ensure maxWidth always has a value
  cursor: pointer; // makes the entire ListItem appear clickable
`;

export const StyledTextField = styled(TextField)`
  margin-top: 16px;
`;

export const DetailItem = styled(Typography)`
  margin-bottom: 8px; // Spacing between items
`;

export const ErrorMessage = styled.p`
  color: red;
  margin-top: 1rem;
`;

export const SuccessMessage = styled.p`
  color: green;
  margin-top: 1rem;
`;

export const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
`;

export const CustomAppBar = styled(AppBar)`
  background-color: transparent;
  box-shadow: none;
`;

export const CustomTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: #000; // Change this to match your design
  }
`;

export const TabPanel = styled(Box)`
  padding: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 15px;
`;

export const AssistantCard = styled(Card)`
  width: 240px;
  margin-left: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 15px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; // This spreads out the children, you may change it to 'flex-start' if you want them closer
  width: 100%; // Ensures the Header takes up the full width
`;

export const TitleAndRefreshContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px; // This sets the spacing between the title and the button
`;

export const TestCard = styled(StyledAssistantCard)`
  /* Add any specific styles for this card if needed */
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;

export const CallActionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusBox = styled.div`
  padding: 4px 8px;
  margin-right: 8px; // Space between status and outcome
  background-color: #f3f3f3; // Adjust background color as needed
  border-radius: 4px;
  font-size: 0.875rem;
  color: #333; // Adjust text color as needed
`;

export const OutcomeBox = styled.div`
  padding: 4px 8px;
  background-color: #f3f3f3; // Adjust background color as needed
  border-radius: 4px;
  font-size: 0.875rem;
  color: #333; // Adjust text color as needed
`;

// Modify the CallStatusAndOutcome component to align horizontally
export const CallStatusAndOutcome = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto; // Push everything to the left of the refresh button
`;

export const CallRowContainer = styled(ListItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px; // reduced padding for a slimmer look
  margin-bottom: 8px; // adds margin between rows
  border-radius: 25px; // rounded corners, but not too pronounced
  border: 1px solid #e0e0e0; // subtle border color
  background-color: #fff; // assuming you want a white background
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // slight shadow for depth
  cursor: pointer;
  transition: background-color 0.2s; // smooth background color transition on hover
  &:hover {
    background-color: #f5f5f5; // change color on hover
  }
`;

export const AssistantAvatar = styled(Avatar)`
  margin-right: 16px;
`;

export const CallInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CallInfoPrimary = styled.span`
  font-size: 1rem;
  color: #333; /* Adjust color as needed */
`;

export const CallInfoSecondary = styled.span`
  font-size: 0.875rem;
  color: #666; /* Adjust color as needed */
`;

export const CallInfoTitle = styled.span`
  font-size: 0.875rem;
  font-style: italic;
  color: #666; /* Adjust color as needed */
`;

export const TranscriptContainer = styled.div`
  margin-top: 16px; // equivalent to theme.spacing(2) if the theme spacing value is 8
  padding: 16px; // equivalent to theme.spacing(2)
  border: 1px solid #e0e0e0; // a light grey border
  border-radius: 4px; // this is a common border-radius value, adjust as needed
  background-color: #fafafa; // a light background color, adjust as needed
`;

export const TranscriptButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: 1px solid #e0e0e0;
  padding: 8px 16px;
  margin: 16px 0;
  cursor: pointer;
  text-decoration: none; // Removes the underline from the link
  color: inherit; // Inherits the text color from the parent
  background-color: #ffffff;

  &:hover {
    background-color: #f5f5f5;
    text-decoration: none; // Keeps the underline away on hover
  }

  & .MuiSvgIcon-root { // Styles for the icon
    margin-right: 8px; // Adds space between the icon and the text
  }
`;

export const RowBackground = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
};

export const ButtonContainer = styled.div`
  margin-bottom: 20px; /* Adjust the value as needed */
`;

export const CSVRowsContainer = styled.div`
  margin-top: 20px; /* Adjust the value as needed */
`;

export const IntegrationContainer = styled.div`
  display: flex;
  flex-direction: column; // Align children vertically
  align-items: center; // Center-align the children horizontally
  margin-bottom: 20px; // Add some margin below the container
`;

export const StyledCalendlyIntegrationLogo = styled(CalendlyIntegrationLogo)`
  width: 50px; // Adjust the width as needed
  height: auto; // Maintain aspect ratio
`;

export const StyledDisconnectButton = styled.button`
  background-color: #d32f2f; // Use a red color for the button
  /* padding-bottom: 20px; */
  /* margin-top: 30px;
  padding-top: 30px; */
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px; // Margin to separate the button from the logo
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #b71c1c; // Darker shade on hover
  }

  &:active {
    background-color: #7f0000; // Even darker on active
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.5); // Adding a focus outline for accessibility
  }
`;

export const StyledIntegrateButton = styled.button`
  background-color: #006BFF; // Use a red color for the button
  /* padding-bottom: 20px; */
  /* margin-top: 30px;
  padding-top: 30px; */
  color: white;
  font-size: 1rem; // Adjust the font size as needed
  padding: 15px 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px; // Margin to separate the button from the logo
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease-in-out;

  /* &:hover {
    background-color: #0CE8F0 // Darker shade on hover
  }

  &:active {
    background-color: #0CE8F0; // Even darker on active
  } */

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.5); // Adding a focus outline for accessibility
  }
`;

export const StyledDisconnectButtonFaxPlus = styled.button`
  background-color: #d32f2f; // Use a red color for the button
  /* padding-bottom: -20px; */
  /* margin-top: 50px;
  padding-top: 50px; */
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px; // Margin to separate the button from the logo
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #b71c1c; // Darker shade on hover
  }

  &:active {
    background-color: #7f0000; // Even darker on active
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.5); // Adding a focus outline for accessibility
  }
`;

// The Card and other styled components remain the same
export const IntegrationCard = styled.div`
  position: relative;
  width: 250px;
  height: 380px;
  border-radius: 15px;
  padding: 16px;
  text-align: center;
  background: #fff;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease; */

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
  }
`;

export const StyledProgressContainer = styled.div`
  position: relative;
  width: 100%;  // Full width of the container to ensure centering
  height: 50px; // Fixed height for vertical space
  display: flex;
  justify-content: center; // Horizontally center the spinner
  align-items: center; // Vertically center the spinner
`;

export const StyledCircularProgress = styled(CircularProgress)`
  width: 24px !important;  // Override any external size styling
  height: 24px !important; // Ensure the spinner is exactly 24x24 pixels
`;

export const StyledFormBox = styled(Box)`
  margin-top: 10px;
  padding-top: 10px;
  min-width: 120px; // Apply the minimum width here as well
  margin-bottom: 3px;
  padding-bottom: 3px;
`;

export const StyledFaxPATButton = styled(Button)`
  background-color: #4caf50; // A more inviting green color
  color: white;
  margin-top: 20px;
  padding-top: 20px;
  &:hover {
    background-color: #388e3c; // Darker shade on hover
  }
  &:active {
    background-color: #2e7d32; // Even darker on active
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.5); // Adding a focus outline for accessibility
  }
`;

export const StyledPasswordTextField = styled(TextField)`
  & input {
    letter-spacing: 0.5em; /* Adjust spacing between dots */
    color: transparent; /* Make text color transparent */
    text-shadow: 0 0 0 black; /* Create fake text color for dots */
    caret-color: black; /* Set caret color to black or to match your design */
    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
      color: transparent;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color: transparent;
      opacity: 1;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: transparent;
      opacity: 1;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: transparent;
    }
    ::placeholder { /* Modern browsers */
      color: transparent;
    }
  }
`;

export const TextFieldWithBottomPadding = styled(TextField)`
  margin-bottom: 20px;
  padding-bottom: 20px;
`;

// Styled ModalOverlay
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black
  z-index: 1000; // High enough to cover the page but not higher than the content
`;

// Styled ModalContent
export const ModalContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%; // Adjusted to 80% of the screen width
  height: 80%; // Adjusted to 80% of the screen height
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  z-index: 1050; // Higher than the overlay
  overflow: auto; // Adds scrollbars if content is too large
`;

// Styled ModalHeader
export const ModalHeader = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
`;

// Styled ModalBody
export const ModalBody = styled.div`
  margin-bottom: 10px;
`;

// Styled ModalFooter
export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

// Styled Textarea
export const Textarea = styled.textarea`
  display: block; // Ensure it's not inline
  width: 100%; // Fill the width
  height: 50vh; // Use viewport height to adjust to a reasonable size
  padding: 20px; // Add padding for inner text spacing
  margin: 0; // Remove any default margin
  box-sizing: border-box; // Include padding and border in the width and height calculations
  resize: vertical; // Allow only vertical resizing
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px; // Improve readability
  line-height: 1.5; // Improve readability
`;

// Styled Button
export const EditPromptButton = styled.button`
  margin-left: auto; // Pushes the button to the far right
  padding: 6px 10px; // Smaller padding for a more compact size
  background-color: #dae3f3; // Lighter blue color for subtlety
  color: #1a237e; // Dark text color for readability on light background
  font-size: 12px; // Smaller font size
  border-radius: 20px; // Highly rounded corners
  box-shadow: none; // Remove any default shadows for a flatter appearance
  text-transform: none; // Prevent uppercase text transformation
  &:hover {
    background-color: #c5d1e8; // Slightly darker on hover for feedback
  }
`;

export const EditButtonContainer = styled.div`
  display: flex;
  flex-direction: column;  // Stacks children vertically
  gap: 10px;              // Adds space between the buttons
`;

export const StyledNumberInput = styled(TextField)`
  width: auto; // Adjust width to content
  flex-grow: 1; // Allow input to grow to fill available space
`;

export const StyledNumberInputWaitlist = styled(TextField)`
  width: 200px; // Adjust width to content
`;

// Container for input and button
export const InputButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px; // Adjust gap between the elements
`;

export const DatePickerContainer = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column; // Change to column to stack vertically
  max-width: 600px;
  /* align-items: center; // Center items horizontally */
  gap: 10px; // Adds space between the elements
`;

export const WaitlistDatePickerContainer = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column; // Change to column to stack vertically
  max-width: 700px;
  /* align-items: center; // Center items horizontally */
  gap: 10px; // Adds space between the elements
`;

// Styled component for the title
export const SecondaryTitle = styled.h2`
  margin: 0 0 10px 0; // Adds space below the title
  font-size: 20px; // Sets font size
  color: #333; // Sets text color
  text-align: center; // Centers the text horizontally
`;

export const PullFaxesButton = styled.button`
  margin-top: 10px;
  background-color: #007BFF; // A modern blue shade
  color: white; // White text for contrast
  padding: 10px 20px; // Appropriate padding for a button
  font-size: 16px; // Larger font size for readability
  border: none; // No border for a modern look
  border-radius: 5px; // Rounded corners
  cursor: pointer; // Cursor indicates it's clickable
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
  transition: background-color 0.3s, box-shadow 0.3s; // Smooth transitions on hover

  &:hover {
    background-color: #0056b3; // Darker blue on hover for interaction feedback
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); // Increased shadow on hover for a lifting effect
  }

  &:active {
    background-color: #004088; // Even darker blue when button is clicked
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Return to subtle shadow
  }

  &:focus {
    outline: none; // Removes the default focus outline
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); // Adds a focus ring for accessibility
  }
`;

export const DatePickerContainerWaitlist = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column; // Change to column to stack vertically
  max-width: 600px;
  /* align-items: center; // Center items horizontally */
`;

// Styled component for the title
export const SecondaryTitleWaitlist = styled.h2`
  margin: 0 0 10px 0; // Adds space below the title
  font-size: 20px; // Sets font size
  color: #333; // Sets text color
  text-align: center; // Centers the text horizontally
`;

export const PullWaitlistButton = styled.button`
  margin-top: 10px;
  background-color: #007BFF; // A modern blue shade
  color: white; // White text for contrast
  padding: 10px 20px; // Appropriate padding for a button
  font-size: 16px; // Larger font size for readability
  border: none; // No border for a modern look
  border-radius: 5px; // Rounded corners
  cursor: pointer; // Cursor indicates it's clickable
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
  transition: background-color 0.3s, box-shadow 0.3s; // Smooth transitions on hover

  &:hover {
    background-color: #0056b3; // Darker blue on hover for interaction feedback
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); // Increased shadow on hover for a lifting effect
  }

  &:active {
    background-color: #004088; // Even darker blue when button is clicked
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Return to subtle shadow
  }

  &:focus {
    outline: none; // Removes the default focus outline
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); // Adds a focus ring for accessibility
  }
`;

export const ContainerWaitlist = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f0f0f0;
  padding: 20px;
`;

export const InputButtonContainerWaitlist = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
`;

export const StyledNumberInputWaitlistWaitlist = styled(TextField)`
  width: 100%;
`;

export const CallAction = styled.div<CallActionProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  margin: 0 8px; /* Adjust spacing as needed */
  border: 1px solid ${props => props.color}; /* Dynamic border color */
  border-radius: 4px; /* Adjust border radius as needed */
`;

export const StyledIconButton = styled(IconButton)`
  padding: 8px;
  margin-top: 0px; // Adjust if necessary to vertically align with the title
  color: #757575; // Adjust the color to match your design
`;

export const PatientStatusAvatarTooltip: React.FC<{ patientStatus: WaitlistPatientStatus, sx?: object, consent?: Consent, latestConsentMessage?: string, latestConsentDate?: string }> = ({ patientStatus, sx, consent, latestConsentMessage, latestConsentDate }) => {
  const message = statusMessages[patientStatus as WaitlistPatientStatus];

  if (consent && consent !== Consent.given) {
    const tooltipMessage = consent === Consent.notYetGiven 
      ? 'Contact consent not yet given' 
      : 'Patient revoked contact consent';
    const tooltip = latestConsentMessage ? `${tooltipMessage}\n${latestConsentMessage}\n${DateTime.fromISO(latestConsentDate!).toLocaleString(DateTime.DATETIME_MED)}` :
      `${tooltipMessage}\n${DateTime.fromISO(latestConsentDate!).toLocaleString(DateTime.DATETIME_MED)}`;

    return (
      <Tooltip title={tooltip}>
        <Box component="span" sx={sx}>
          <Avatar sx={{ bgcolor: 'red' }}>
            <CloseIcon />
          </Avatar>
        </Box>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={message}>
      <Box component="span" sx={sx}>
        <Avatar sx={{ bgcolor: statusColors[patientStatus]}}>
          {statusIcons[patientStatus]}
        </Avatar>
      </Box>
    </Tooltip>
  );
};

export const CallOutcomeAvatarTooltip: React.FC<{ callOutcome: CallOutcomes, sx?: object }> = ({ callOutcome, sx }: { callOutcome: CallOutcomes, sx?: object }) => {
  const message = callOutcomeMessages[callOutcome];

  return (
    <Tooltip title={message}>
      <Box component="span" sx={sx}>
        <Avatar sx={{ backgroundColor: callOutcome === CallOutcomes.confirmed ? Colors.confirmedColor1 : Colors.otherColor1}}>
          {callOutcomeIcons[callOutcome]}
        </Avatar>
      </Box>
    </Tooltip>
  );
};

export const WaitlistActionAvatar = styled(Avatar)({
  marginLeft: '10px',
});

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: auto;
  max-width: 500px;
  text-align: center;
  overflow: auto;
  max-height: 80vh;
`;

interface ModalButtonProps {
  color: string;
  disabled?: boolean;
}

export const ModalButton = styled.button<ModalButtonProps>`
  margin: 10px;
  padding: 10px 20px;
  background-color: ${(props: ModalButtonProps) => (props.disabled ? 'grey' : props.color)};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: ${(props: ModalButtonProps) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props: ModalButtonProps) => (props.disabled ? 0.6 : 1)};
  &:hover {
    background-color: ${(props: ModalButtonProps) => (props.disabled ? 'grey' : darkenColor(props.color, 0.2))};
  }
`;

// Helper function to darken the color
export function darkenColor(hex: string, percent: number): string {
  const num = parseInt(hex.replace("#", ""), 16);
  const amt = Math.round(2.55 * percent * 100);
  const R = (num >> 16) + amt;
  const G = (num >> 8 & 0x00FF) + amt;
  const B = (num & 0x0000FF) + amt;
  return `#${(0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (G < 255 ? G < 1 ? 0 : G : 255) * 0x100 + (B < 255 ? B < 1 ? 0 : B : 255)).toString(16).slice(1).toUpperCase()}`;
}

// #53C43B


export interface TwoChoiceModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  message: string;
  actionMessageOne: string;
  actionMessageTwo: string;
}

export interface OneChoiceModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  message: string;
  actionMessage: string;
}

export const TwoChoiceModal: React.FC<TwoChoiceModalProps> = ({ isOpen, onCancel, onConfirm, message, actionMessageOne, actionMessageTwo }) => {
  if (!isOpen) return null;

  return (
    <ModalBackdrop>
      <ModalContainer>
        <p>{message}</p>
        <ModalButton color={Colors.grey2} onClick={onCancel}>{actionMessageOne}</ModalButton>
        <ModalButton color={Colors.blue1} onClick={onConfirm}>{actionMessageTwo}</ModalButton>
      </ModalContainer>
    </ModalBackdrop>
  );
};

export const OneChoiceModal: React.FC<OneChoiceModalProps> = ({ isOpen, onConfirm, message, actionMessage }) => {
  if (!isOpen) return null;

  return (
    <ModalBackdrop>
      <ModalContainer>
        <p>{message}</p>
        <ModalButton color={Colors.blue1} onClick={onConfirm}>{actionMessage}</ModalButton>
      </ModalContainer>
    </ModalBackdrop>
  );
};

export const GiantPhoneIcon = styled(LocalPhoneIcon)`
  font-size: 100px !important;
  color: #53C43B;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  margin-bottom: 20px;
  transition: transform 0.3s;
  border-radius: 20px;
  padding: 5px;

  &:hover {
    transform: scale(1.1);
  }
`;

export const GiantMessageIcon = styled(MessageIcon)`
  font-size: 100px !important;
  color: #007bff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  transition: transform 0.3s;
  border-radius: 20px;
  padding: 5px;

  &:hover {
    transform: scale(1.1);
  }
`;

export const GiantRobotIcon = styled(SmartToyIcon)`
  font-size: 100px !important;
  color: #6c757d;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  transition: transform 0.3s;
  border-radius: 20px;
  padding: 5px;

  &:hover {
    transform: scale(1.1);
  }
`;

export const GiantPlusIcon = styled(AddIcon)`
  font-size: 70px !important;
  color: #6c757d;
  margin-bottom: 20px;
  transition: transform 0.3s;
  padding: 5px;
`;

export const GiantEventAvailableIcon = styled(EventAvailableIcon)`
  font-size: 80px !important;
  color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  transition: transform 0.3s;
  border-radius: 60px;
  padding: 5px;

  &:hover {
    transform: scale(1.1);
  }
`;

export const GiantEventBusyIcon = styled(EventBusyIcon)`
  font-size: 100px !important;
  color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  transition: transform 0.3s;
  border-radius: 60px;
  padding: 5px;

  &:hover {
    transform: scale(1.1);
  }
`;

export const IconWithHoverTwo = styled('div')`
  font-size: 50px !important;
  cursor: pointer;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.2);
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  padding: 15px 20px;
  margin: 5px 0;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  border-radius: 8px;
  cursor: pointer; // Makes the mouse cursor a pointer on hover
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out; // Smooth transition for transform and box-shadow
  width: 100%;

  &:hover {
    transform: translateY(-2px) scale(1.02); // Moves the Row up slightly and scales it up
    box-shadow: 0 4px 6px rgba(0,0,0,0.15); // Enhances the shadow effect on hover
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #333;
  font-size: 16px;
`;

export const PhysicianInfo = styled(Info)`
  font-weight: 500;
`;

export const AppointmentInfo = styled(Info)`
  align-items: flex-start;
`;

export const PatientInfo = styled(Info)`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  flex-direction: row; // Ensure items are arranged in a row
`;

export const StyledAvatar = styled(Avatar)`
  background-color: #4CAF50;
  margin-left: 10px; // Added margin to the left of the avatar to space it from the name
`;

export const StyledIconButtonAppointmentRow = styled(IconButton)`
  color: #757575;
`;
