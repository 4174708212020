import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Typography, FormControlLabel, Checkbox, TextField } from '@mui/material';
import { Colors } from '../../../Colors';
import { IconWithHover, ModalBackdrop, ModalButton, ModalContainer, TwoChoiceModalProps } from '../../styles';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { WaitlistRun } from '../../../types';
import MessageIcon from '@mui/icons-material/Message';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AddIcon from '@mui/icons-material/Add';

const MemoizedDateTimePicker = React.memo(DateTimePicker);

const HybridCampaignConfirmationModal: React.FC<TwoChoiceModalProps & { 
  waitlistRun: WaitlistRun, 
  automatedTextingEnabled: boolean, 
  setAutomatedTextingEnabled: (bool: boolean) => void,
  cutoffDate: DateTime,
  setCutoffDate: (date: DateTime) => void,
}> = ({ isOpen, 
  onCancel, 
  onConfirm, 
  message, 
  actionMessageOne, 
  actionMessageTwo, 
  waitlistRun,
  automatedTextingEnabled,
  setAutomatedTextingEnabled,
  cutoffDate,
  setCutoffDate,
 }) => {
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const datePickerRef = useRef<HTMLDivElement | null>(null);
  const [numberOfMinutesToWaitBeforeCalling, setNumberOfMinutesToWaitBeforeCalling] = useState<number>(0);

  useEffect(() => {
    if (numberOfMinutesToWaitBeforeCalling < 0) {
      setErrorMsg("Number of minutes to wait before calling must be a positive number.");
      setNumberOfMinutesToWaitBeforeCalling(0);
    }

    if (numberOfMinutesToWaitBeforeCalling > 60) {
      setErrorMsg("Number of minutes to wait before calling must be less than 60.");
      setNumberOfMinutesToWaitBeforeCalling(60);
    }

    setErrorMsg(null);
  }, [numberOfMinutesToWaitBeforeCalling]);

  useEffect(() => {
    setCutoffDate(DateTime.fromISO(waitlistRun.appointmentDate).minus({ hour: 1 }));
    setErrorMsg(null);
  }, []);

  const handleSetCutoffDate = useCallback((newDate: DateTime | null) => {
    if (!newDate) {
      setCutoffDate(DateTime.now().plus({ days: 1 }));
    } else if (newDate < DateTime.fromISO(waitlistRun?.appointmentDate) && newDate > DateTime.now()) {
      setCutoffDate(newDate);
      setErrorMsg(null);
    } else if (newDate < DateTime.now()) {
      setErrorMsg("Cutoff date must be after the current date.");
      return;
    } else {
      setErrorMsg("Cutoff date must be before the appointment date.");
    }
  }, [waitlistRun?.appointmentDate]);

  if (!isOpen) return null;

  return (
    <ModalBackdrop>
      <ModalContainer>
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          <IconWithHover>
            <MessageIcon sx={{ fontSize: 100, color: Colors.blue1 }} />
            <AddIcon sx={{ fontSize: 100, color: Colors.grey2, mx: 1 }} />
            <LocalPhoneIcon sx={{ fontSize: 100, color: Colors.confirmedColor1 }} />
          </IconWithHover>
          <Typography variant="h4" component="div" sx={{ mb: 2, textAlign: 'center', fontWeight: 'bold' }}>
            Hybrid Campaign Confirmation
          </Typography>
          <p>{message}</p>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={automatedTextingEnabled}
                onChange={(e: any) => setAutomatedTextingEnabled(e.target.checked)}
                name="automatedText"
                color="primary"
              />
            }
            label="Enable AI texts"
          />
          {automatedTextingEnabled && (
            <>
              <MemoizedDateTimePicker
                label="Cutoff date"
                value={cutoffDate}
                onChange={handleSetCutoffDate}
              />
            </>
          )}
          <TextField
            id="outlined-basic"
            label="Minutes to wait before calling"
            variant="outlined"
            type="number"
            value={numberOfMinutesToWaitBeforeCalling}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNumberOfMinutesToWaitBeforeCalling(parseInt(event.target.value))}
          />
          <br />
        </Box>
        { errorMsg && (
          <Typography variant="body2" sx={{ color: Colors.otherColor1, textAlign: 'center', mb: 2 }}>
            {errorMsg}
          </Typography>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <ModalButton color={Colors.grey2} onClick={onCancel}>{actionMessageOne}</ModalButton>
          <ModalButton disabled={!!errorMsg} color={Colors.blue1} onClick={onConfirm}>{actionMessageTwo}</ModalButton>
        </Box>
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default HybridCampaignConfirmationModal;
