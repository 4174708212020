// CallRow.tsx
import React, { useEffect } from 'react';
import { ListItem, ListItemAvatar, Avatar, ListItemText, IconButton, Box } from '@mui/material';
import { formatDistanceStrict, parseISO } from 'date-fns';
import axios from 'axios';
import { AssistantMetadata, AssistantTitles, CallOutcomes, CallOutcomesUi, CallStatusesUi, FrontendCall } from '../../types';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { chooseColorBasedOnOutcome, chooseColorBasedOnStatus } from '../../utils';

interface CallActionProps {
  color: string;
}

const CallAction = styled.div<CallActionProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  margin: 0 8px; /* Adjust spacing as needed */
  border: 1px solid ${props => props.color}; /* Dynamic border color */
  border-radius: 4px; /* Adjust border radius as needed */
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const CallActionContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StatusBox = styled.div`
  padding: 4px 8px;
  margin-right: 8px; // Space between status and outcome
  background-color: #f3f3f3; // Adjust background color as needed
  border-radius: 4px;
  font-size: 0.875rem;
  color: #333; // Adjust text color as needed
`;

const OutcomeBox = styled.div`
  padding: 4px 8px;
  background-color: #f3f3f3; // Adjust background color as needed
  border-radius: 4px;
  font-size: 0.875rem;
  color: #333; // Adjust text color as needed
`;

// Modify the CallStatusAndOutcome component to align horizontally
const CallStatusAndOutcome = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto; // Push everything to the left of the refresh button
`;

const CallRowContainer = styled(ListItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px; // reduced padding for a slimmer look
  margin-bottom: 8px; // adds margin between rows
  border-radius: 25px; // rounded corners, but not too pronounced
  border: 1px solid #e0e0e0; // subtle border color
  background-color: #fff; // assuming you want a white background
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // slight shadow for depth
  cursor: pointer;
  transition: background-color 0.2s; // smooth background color transition on hover
  &:hover {
    background-color: #f5f5f5; // change color on hover
  }
`;

const AssistantAvatar = styled(Avatar)`
  margin-right: 16px;
`;

const CallInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const CallInfoPrimary = styled.span`
  font-size: 1rem;
  color: #333; /* Adjust color as needed */
`;

const CallInfoSecondary = styled.span`
  font-size: 0.875rem;
  color: #666; /* Adjust color as needed */
`;

const CallInfoTitle = styled.span`
  font-size: 0.875rem;
  font-style: italic;
  color: #666; /* Adjust color as needed */
`;

const RefreshButton = styled(IconButton)`
  margin-left: auto; /* Pushes the button to the right */
`;

interface CallRowProps {
  call: FrontendCall;
  assistant: AssistantMetadata;
  onClick: (call: any) => void;
}

const CallRow: React.FC<CallRowProps> = ({ call, assistant, onClick }) => {
  const [luxonParsedStartedAt, setLuxonParsedStartedAt] = React.useState<string | null>(null);
  const [callOutcome, setCallOutcome] = React.useState<string>('');
  const [callOutcomeColor, setCallOutcomeColor] = React.useState<string>('');

  useEffect(() => {
    if (!call || !assistant) return;
    const luxonParsedStartedAt = !!call?.createdAt ? DateTime.fromISO(call?.createdAt).setZone('local').toFormat('cccc, LLLL d, HH:mm') : null;
    const callOutcome: string = CallOutcomesUi[call?.outcome?.toLowerCase() as keyof typeof CallOutcomesUi] || call.outcome;
    const callOutcomeColor = chooseColorBasedOnOutcome(call?.outcome?.toLowerCase());

    setLuxonParsedStartedAt(luxonParsedStartedAt);
    setCallOutcome(callOutcome);
    setCallOutcomeColor(callOutcomeColor);
  }, [call, assistant]);

  return (
    !!call && !!assistant && (
      <CallRowContainer onClick={() => onClick(call)}>
        <AssistantAvatar src={assistant.avatarUrl} />
        <CallInfo>
          <CallInfoPrimary>{`${call.variables?.patientFirstName} ${call.variables?.patientLastName}`}</CallInfoPrimary>
          <CallInfoTitle>Called by: {assistant.name} ({AssistantTitles[assistant.title]}) on {luxonParsedStartedAt}</CallInfoTitle>
          <CallInfoTitle>Call id: {call.callId}</CallInfoTitle>
        </CallInfo>
        <Spacer />
        <CallActionContainer>
          <CallAction color={callOutcomeColor}>
            <span>Call outcome: {callOutcome || "unknown"}</span>
          </CallAction>
        </CallActionContainer>
      </CallRowContainer>
    )
  );  
};

export default CallRow;
