// CallRow.tsx
import React, { useState } from 'react';
import { ListItem, Avatar, IconButton, Box, Button, Modal, CircularProgress } from '@mui/material';
import axios from 'axios';
import { FrontendUser, ScheduledCall } from '../../types';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { StyledIconButton } from '../styles';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
};

interface ScheduledCallRowProps {
  scheduledCall: ScheduledCall;
  assistant: any;
  handleDeleteScheduledCall: (scheduledCallId: string) => void;
}

const Spacer = styled.div`
  flex-grow: 1;
`;

const CallActionContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StatusBox = styled.div`
  padding: 4px 8px;
  margin-right: 8px; // Space between status and outcome
  background-color: #f3f3f3; // Adjust background color as needed
  border-radius: 4px;
  font-size: 0.875rem;
  color: #333; // Adjust text color as needed
`;

const OutcomeBox = styled.div`
  padding: 4px 8px;
  background-color: #f3f3f3; // Adjust background color as needed
  border-radius: 4px;
  font-size: 0.875rem;
  color: #333; // Adjust text color as needed
`;

// Modify the CallStatusAndOutcome component to align horizontally
const CallStatusAndOutcome = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto; // Push everything to the left of the refresh button
`;

const CallRowContainer = styled(ListItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px; // reduced padding for a slimmer look
  margin-bottom: 8px; // adds margin between rows
  border-radius: 25px; // rounded corners, but not too pronounced
  border: 1px solid #e0e0e0; // subtle border color
  background-color: #fff; // assuming you want a white background
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // slight shadow for depth
  cursor: pointer;
  transition: background-color 0.2s; // smooth background color transition on hover
  &:hover {
    background-color: #f5f5f5; // change color on hover
  }
`;

const AssistantAvatar = styled(Avatar)`
  margin-right: 16px;
`;

const CallInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const CallInfoPrimary = styled.span`
  font-size: 1rem;
  color: #333; /* Adjust color as needed */
`;

const CallInfoSecondary = styled.span`
  font-size: 0.875rem;
  color: #666; /* Adjust color as needed */
`;

const CallInfoTitle = styled.span`
  font-size: 0.875rem;
  font-style: italic;
  color: #666; /* Adjust color as needed */
`;

const RefreshButton = styled(IconButton)`
  margin-left: auto; /* Pushes the button to the right */
`;

const ScheduledCallRow: React.FC<ScheduledCallRowProps> = ({ scheduledCall, assistant, handleDeleteScheduledCall }) => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState<DateTime | null>(DateTime.now());
  const [loading, setLoading] = useState(false);
  const { showMessage } = useSnackbar();

  const scheduledDate: string | null = scheduledCall.scheduledDate ? DateTime.fromISO(scheduledCall.scheduledDate).toLocaleString(DateTime.DATETIME_MED): null;

  const handleOpenScheduleModal = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation(); // Prevent triggering the view modal
    setOpenScheduleModal(true);
  };
  const handleCloseScheduleModal = () => setOpenScheduleModal(false);

  const handleDateChange = (date: DateTime | null) => {
    setSelectedDate(date);
  };

  const handleScheduleCall = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation(); // Prevent click event from bubbling up to the parent div
    setLoading(true);
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      if (!backendUrl) {
        console.error("No backend URL found");
        showMessage('No backend URL found. Please refresh the page.', 'error');
        return;
      }
  
      if (!user?.id) {
        console.error("No user id found");
        showMessage('No user found. Please refresh the page', 'error');
        return;
      }
  
      const userId = user.id;
  
      const response = await axios.post(`${backendUrl}/execute-call`, 
      {
        userId,
        scheduledCallId: scheduledCall.scheduledCallId,
      },
      {
        headers: {
          'Authorization': `Bearer ${user?.token}`,
        },
      });

      if (response.status === 200) {
        showMessage('Call successfully initiated', 'success');
      } else {
        // showMessage('Failed to initiate call. Please try again later.', 'error');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error getting queued calls:', error);
      // showMessage('Failed to initiate call. Please refresh and try again.', 'error');
      setLoading(false);
    }
  };

  return (
    <>
      <CallRowContainer>
      <AssistantAvatar src={assistant.avatarUrl} />
      <CallInfo>
        <CallInfoPrimary>{scheduledCall.variables?.patientFirstName} {scheduledCall.variables?.patientLastName}</CallInfoPrimary>
        {scheduledDate && (
        <CallInfoTitle>Scheduled for: {scheduledDate}</CallInfoTitle>
        )}
        {!scheduledDate && (
        <CallInfoTitle>Not yet processed</CallInfoTitle>
        )}
      </CallInfo>
      <Spacer />
      <CallActionContainer>
        <Button onClick={handleOpenScheduleModal}>Call patient now</Button>
        <StyledIconButton
          aria-label="delete call"
          onClick={() => handleDeleteScheduledCall(scheduledCall.scheduledCallId)}
        >
          <DeleteIcon />
        </StyledIconButton>
      </CallActionContainer>
    </CallRowContainer>
      <Modal
        open={openScheduleModal}
        onClose={handleCloseScheduleModal}
        // Other props
      >
        <Box sx={{ ...style, width: 400, height: 300 }}>
          <Button onClick={handleScheduleCall}>Call now</Button>
          { loading && <CircularProgress />}
        </Box>
      </Modal>
    </>
  );
};

export default ScheduledCallRow;
