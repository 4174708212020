import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

interface LoadingWithSubtitleProps {
  subtitle: string;
  size: number; // This will control the size of the CircularProgress
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledCaption = styled(Typography)`
  margin-top: 15px;
  padding-top: 15px;
  font-size: 1rem; /* Adjust font size as needed */
  color: #333; /* Optional: change the color */
  margin-top: 8px; /* Keep the original margin */
`;

const LoadingWithSubtitle: React.FC<LoadingWithSubtitleProps> = ({ subtitle, size }) => {
  return (
    <Container>
      <CircularProgress size={size} />
      <StyledCaption variant="caption">
        {subtitle}
      </StyledCaption>
    </Container>
  );
};

export default LoadingWithSubtitle;
