import React, { useEffect, useState } from 'react';
import { Typography, CircularProgress, Box, IconButton, Button, TextField, FormControl, InputLabel, Select, MenuItem, Modal, Table, TableBody, TableRow, TableCell, TableHead, List, ListItem, ListItemText, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { useNavigate, useParams } from 'react-router-dom';
import AssistantDetailCard from '../components/cards/AssistantDetailCard';
import { fetchAssistants, selectAssistants } from '../slices/assistantsSlice';
import { AppBarContainer, ContentContainer, LayoutContainer, ModalBackdrop, ModalButton, ModalContainer, ToggleTabButton } from '../components/styles';
import History from '../components/tabs/History';
import Queue from '../components/tabs/Queue';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AssistantMetadata, Integrations, AssistantDetailTabs, AssistantView, FrontendWaitlistPatient, OpenWaitlistAppointment, WaitlistActions, TakenWaitlistAppointment, Organization, ShiftOptions, Choices, WaitlistRun } from '../types';
import WaitlistRuns from '../components/tabs/WaitlistRuns';
import { useSnackbar } from '../providers/SnackbarProvider';
import { sessionExpired } from '../slices/sessionSlice';
import styled from 'styled-components';
import PhysicianRow from '../components/row/PhysicianRow';
import PatientRow from '../components/row/PatientRow';
import Papa from 'papaparse';
import RunWaitlistModal from '../components/subcomponents/RunPatientsModal';
import CreateAppointmentModal from '../components/subcomponents/CreateAppointmentModal';
import { DateTime } from 'luxon';
import axios from 'axios';
import MatchColumnsModal from '../components/subcomponents/MatchColumnsModal';
import AddPatientModal from '../components/subcomponents/AddPatientModal';
import { WebsocketEvents, convertToCallablePhoneNumber } from '../utils';
import TakenWaitlistAppointmentRow from '../components/row/TakenWaitlistAppointmentRow';
import { fetchOrganization } from '../slices/organizationSlice';
import UploadCSVModal from '../components/subcomponents/UploadCSVModal';
import LoadingWithSubtitle from '../components/subcomponents/LoadingWithSubtitle';
import { addWaitlistRun, deleteWaitlistRun, fetchArchivedTakenWaitlistAppointments } from '../slices/waitlistRunsSlice';
import { usePostHog } from 'posthog-js/react';
import EditPatientModal from '../components/subcomponents/EditPatientModal';
import ArchivedTakenWaitlistAppointmentRow from '../components/row/ArchivedTakenWaitlistAppointmentRow';
import CampaignActions from '../components/subcomponents/CampaignActions';

interface AssistantDetailProps {
  tab: AssistantView;
}

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  padding: 20px;
  margin: 10px;
  width: 350px; // Adjust width as per your layout needs
`;

const TakenAppointmentsBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  padding: 20px;
  margin: 10px;
  width: 400px; // Adjust width as per your layout needs
`;

const AppointmentContainer = styled.div`
  display: flex;
  flex-direction: column; // Keeps items in a vertical flow
  flex-wrap: wrap; // Allows wrapping into multiple columns
  align-content: flex-start; // Aligns content at the start of the flex container
  height: auto; // Adjust the height as needed
  width: 100%; // Takes full width of its container
`;

// Styled Button with Bold style
const BoldButton = styled(Button)`
  background-color: #3B5BEA;
  color: #ffffff;
  &:hover {
    background-color: #2e47c9;
  }
  margin-top: 10px;
`;

// Styled Button with Outline style
// const OutlinedButton = styled(Button)`
//   border: 1px solid #3B5BEA;
//   color: #3B5BEA;
//   &:hover {
//     background-color: #e9ebfe;
//   }
//   margin-top: 10px;
// `;

const RowDiv = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: start; // Aligns boxes at the top
  width: 100%; // Takes full width of the parent
`;

const SubRowDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;  // Ensures spacing between title and buttons
  padding: 16px;  // Adds padding around the entire SubRowDiv
  margin-bottom: 10px;  // Adds some margin below the div

  h3 {
    margin-right: auto;  // Pushes the h3 to the left and buttons to the right
  }
`;

const OutlinedButton = styled.button`
  margin: 0 8px;  // Adds horizontal spacing between buttons
  padding: 8px 16px;  // Pads the button text
  border: 1px solid blue;  // Example border for the outline
  background-color: white;
  color: blue;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: #f0f0f0;  // Slight background color change on hover
  }

  &:disabled {
    background-color: #f0f0f0;  // Grey background when disabled
    color: #ccc;  // Dimmed text color when disabled
    border-color: #ccc;  // Grey border when disabled
    cursor: not-allowed;  // Cursor indicates button is disabled
    opacity: 0.8;  // Slightly transparent to indicate it's disabled
  }
`;

const RunsContainer = styled.div`
  display: flex;
  flex-direction: column; /* Sets the direction of main axis to vertical */
  align-items: center; /* Centers items along the cross axis */
  margin: 20px;
  gap: 20px; /* Space between each card */
`;


const AssistantDetail: React.FC<AssistantDetailProps> = ({ tab }) => {
  const { assistantId, '*': selectedPageView } = useParams<{ assistantId: string; '*': string }>();
  const { user } = useSelector((state: RootState) => state.auth);
  const assistants = useSelector(selectAssistants);
  const loading = useSelector((state: RootState) => state.assistants.loading);
  const archivedTakenWaitlistAppointments = useSelector((state: RootState) => state.waitlistRuns.archivedTakenWaitlistAppointments);
  const archivedTakenAppointmentLoading = useSelector((state: RootState) => state.waitlistRuns.archivedTakenAppointmentLoading);
  const [assistant, setAssistant] = useState<AssistantMetadata | null>(assistants.find((assistant: AssistantMetadata) => assistant.assistantId === assistantId) || null);
  const [selectedView, setSelectedView] = useState<AssistantDetailTabs>(tab);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { showMessage } = useSnackbar();
  const [addPatientModalOpen, setAddPatientModalOpen] = useState<boolean>(false);
  const [patientName, setPatientName] = useState<string>('');
  const [primaryProvider, setPrimaryProvider] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('+1');
  const [appointmentLength, setAppointmentLength] = useState<number>(60);
  const [priority, setPriority] = useState<boolean>(false);
  const [patients, setPatients] = useState<FrontendWaitlistPatient[]>([]);
  const [uploadCSVModalOpen, setUploadCSVModalOpen] = useState<boolean>(false);
  const [matchingColumnsModalOpen, setMatchingColumnsModalOpen] = useState<boolean>(false);
  const [columns, setColumns] = useState<any>([]);
  const [dataRowsCount, setDataRowsCount] = useState(0);
  const [runWaitlistModalOpen, setRunWaitlistModalOpen] = useState<boolean>(false);
  const [createAppointmentModalOpen, setCreateAppointmentModalOpen] = useState<boolean>(false);
  const [pcpName, setPcpName] = useState<string>(Choices.noPreference);
  const [appointmentDate, setAppointmentDate] = useState<DateTime | null>(DateTime.now().plus({ weeks: 1 }));
  const [appointmentLengthCreateAppointment, setAppointmentLengthCreateAppointment] = useState<number>(60);
  const [contactMethods, setContactMethod] = useState<('text' | 'call')[]>(['text']);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [addPatientErrorMessage, setAddPatientErrorMessage] = useState<string>('');
  const [dataRows, setDataRows] = useState<any[]>([]);
  const [headerMappings, setHeaderMappings] = useState<any[]>([]);
  const [appointments, setAppointments] = useState<OpenWaitlistAppointment[]>([]);
  const [takenAppointments, setTakenAppointments] = useState<TakenWaitlistAppointment[]>([]);
  const [currentAppointment, setCurrentAppointment] = useState<OpenWaitlistAppointment | null>(null);
  const [visibleCount, setVisibleCount] = useState(3);
  const [visibleArchivedCount, setVisibleArchivedCount] = useState(3);
  const [sortedPatients, setSortedPatients] = useState<FrontendWaitlistPatient[]>([...patients]);
  const organization: Organization | null = useSelector((state: RootState) => state.organization.organization);
  const [physicianNames, setPhysicianNames] = useState<string[]>(organization?.physicianNames || []);
  const [addPatientLoading, setAddPatientLoading] = useState<boolean>(false);
  const [prefersShift, setPrefersShift] = useState<ShiftOptions>(ShiftOptions.anyShift);
  const [fetchPatientsLoading, setFetchPatientsLoading] = useState<boolean>(false);
  const [appointmentShift, setAppointmentShift] = useState<ShiftOptions>(ShiftOptions.anyShift);
  const [uploadCSVLoading, setUploadCSVLoading] = useState<boolean>(false);
  const [campaignActionsModalOpen, setCampaignActionsModalOpen] = useState<boolean>(false);
  const [uploadingWaitlistRunLoading, setUploadingWaitlistRunLoading] = useState<boolean>(false);
  const posthog = usePostHog();
  const [editingPatient, setEditingPatient] = useState<FrontendWaitlistPatient | null>(null);
  const [editingPatientLoading, setEditingPatientLoading] = useState<boolean>(false);
  const [editingPatientErrorMessage, setEditingPatientErrorMessage] = useState<string>('');
  const waitlistRuns: WaitlistRun[] = useSelector((state: RootState) => state.waitlistRuns.waitlistRuns);

  const loadingGettingRuns = useSelector((state: RootState) => state.waitlistRuns.loadingGettingRuns);

  const fetchOrganizationData = async () => {
    if (!organization && user?.id && user?.token) {
      await dispatch(fetchOrganization({ userId: user?.id, token: user?.token }) as any);
      await dispatch(fetchArchivedTakenWaitlistAppointments({ token: user?.token }) as any);
    }
  };

  useEffect(() => {
    fetchOrganizationData();
  }, [user?.token]);

  useEffect(() => {
    if (organization && !physicianNames) {
      setPhysicianNames(organization?.physicianNames || {});
    }
  }, [organization]);

  useEffect(() => {
    if (organization && !physicianNames) {
      setPhysicianNames(organization?.physicianNames || {});
    }
  }, []);

  useEffect(() => {
    setSortedPatients(patients.sort(compare));
  }, [patients]);
  

  const resetAddPatientFields = () => {
    const oneHour = 60;
    setPatientName('');
    setPrimaryProvider('');
    setPhoneNumber('+1');
    setAppointmentLength(oneHour);
    setPriority(false);
    setAvailableDates([]); 
    setPrefersShift(ShiftOptions.anyShift);
  };

  useEffect(() => {
    if (!organization) return;
    setPhysicianNames(organization?.physicianNames || {});
  }, [organization?.physicianNames]);

  useEffect(() => {
    const fetchOrg = async () => {
      if (user && user.id && user.token) {
        await dispatch(fetchOrganization({ userId: user.id, token: user.token }) as any);
      }
    }
    
    if (!organization) {
      fetchOrg();
    }
  }, []);

  useEffect(() => {
    if (patients.length > 0) {
      setSortedPatients(patients);
    }
  }, [patients]);

  const handleViewMore = () => {
    setVisibleCount(patients.length); // Increase the count by 3 each time button is clicked
  };

  const handleViewMoreArchivedAppointments = () => {
    setVisibleArchivedCount(archivedTakenWaitlistAppointments.length);
  };

  const compare = (a: FrontendWaitlistPatient, b: FrontendWaitlistPatient) => {
    // Priority comparison should happen first
    if (a.priority && !b.priority) return -1;  // a is priority and should come first
    if (!a.priority && b.priority) return 1;   // b is priority and should come first
  
    // Continue with other comparisons if priorities are the same
    if (assistant?.waitlistColumnRankings) {
      for (let key of assistant.waitlistColumnRankings) {
        let aValue = a[key as keyof FrontendWaitlistPatient];
        let bValue = b[key as keyof FrontendWaitlistPatient];
  
        // Check if a sub-priority exists for this key
        if (assistant?.variableSubRankings && assistant.variableSubRankings[key]) {
          const aIndex = assistant.variableSubRankings[key].indexOf(aValue as string);
          const bIndex = assistant.variableSubRankings[key].indexOf(bValue as string);
          if (aIndex !== bIndex) return aIndex - bIndex; // Sort by index in the sub-priority array
        }
    
        // Regular comparison if no sub-priority or values are the same in sub-priority
        if (aValue !== undefined && bValue !== undefined) {
          if (aValue < bValue) return -1;
          if (aValue > bValue) return 1;
        }
      }
    }
  
    return 0;  // Return equal if all else fails
  };  

  const fetchAlternateWaitlistPatients = async () => {
    if (user && user.id && user.token) {
      try {
        setFetchPatientsLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/get-alternate-waitlist-patients?userId=${user?.id}`, {
          headers: {
            'Authorization': `Bearer ${user.token}`,
          }
        });

        if (response.status === 200) {
          const waitlistPatientsReturned: FrontendWaitlistPatient[] = (response.data as FrontendWaitlistPatient[]).filter((patient: FrontendWaitlistPatient) => patient?.dates?.length > 0);
          setPatients(waitlistPatientsReturned.sort(compare));
        } else {
          // showMessage('Failed to fetch waitlist patients. Please try again later.', 'error');
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(sessionExpired(true) as any);
        } else {
          // showMessage('Failed to fetch waitlist patients. Please try again later.', 'error');
          console.error('Failed to fetch waitlist patients:', error.message);
        }
      } finally {
        setFetchPatientsLoading(false);
      }
    }
  };

  const fetchAppointments = async () => {
    if (user && user.id && user.token) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/waitlist-appointments?userId=${user?.id}`, {
          headers: {
            'Authorization': `Bearer ${user.token}`,
          }
        });

        if (response.status === 200) {
          setAppointments(response.data);
        } else {
          // showMessage('Failed to fetch waitlist appointments. Please try again later.', 'error');
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(sessionExpired(true) as any);
        } else {
          // showMessage('Failed to fetch waitlist appointments. Please try again later.', 'error');
          console.error('Failed to fetch waitlist appointments:', error.message);
        }
      }
    }
  };

  const fetchTakenAppointments = async () => {
    if (user && user.id && user.token) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/taken-waitlist-appointments?userId=${user?.id}`, {
          headers: {
            'Authorization': `Bearer ${user.token}`,
          }
        });

        if (response.status === 200) {
          setTakenAppointments(response.data);
        } else {
          // showMessage('Failed to fetch taken waitlist appointments. Please try again later.', 'error');
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(sessionExpired(true) as any);
        } else {
          // showMessage('Failed to fetch taken waitlist appointments. Please try again later.', 'error');
          console.error('Failed to fetch taken waitlist appointments:', error.message);
        }
      }
    }
  };

  useEffect(() => {
    if (!user?.id) { return };
    if (!user?.token) { return };

    const connectWebSocket = async () => {
      try {
        const socket = new WebSocket(`wss://${process.env.REACT_APP_BACKEND_URL_NO_HTTPS}/api/subscribe/${user.id}?token=${encodeURIComponent(user.token!)}`);

        socket.onopen = () => {
          console.log('Connected to WebSocket');
          socket.send(JSON.stringify({ action: 'subscribe', type: WebsocketEvents.TakenWaitlistAppointments }));
          socket.send(JSON.stringify({ action: 'subscribe', type: WebsocketEvents.OpenWaitlistAppointments }));
          socket.send(JSON.stringify({ action: 'subscribe', type: WebsocketEvents.AlternateWaitlistPatients }));
          socket.send(JSON.stringify({ action: 'subscribe', type: WebsocketEvents.DeletedTakenWaitlistAppointments }));
        };

        socket.onmessage = (event: any) => {
          getData();
        };

        socket.onclose = () => {
          console.log('Disconnected from WebSocket');
        };

        socket.onerror = (error) => {
          console.error('WebSocket error:', error);
          // showMessage('WebSocket error. Please try again.', 'error');
        };

        return () => {
          socket.close();
        };
      } catch (error: any) {
        if (error.response?.status === 401) {
          showMessage('Session has expired', 'error');
        } else {
          console.error('Error connecting to WebSocket:', error);
          showMessage('Please refresh the page.', 'error');
        }
      }
    };

    connectWebSocket();
  }, []);

  const getData = async () => {
    fetchAlternateWaitlistPatients();
    fetchAppointments();
    fetchTakenAppointments();
    fetchOrganizationData();
  };

  useEffect(() => {
    getData();
  }, []);

  const handlePatientNameChange = React.useCallback((event: any) => {
    setPatientName(event.target.value);
  }, []);
  
  const fetchGlobalAssistants = async () => {
    if (user && user.id && user.token) {
      try {
        await dispatch(fetchAssistants({ userId: user.id, token: user.token }) as any);
      } catch (error: any) {
        if (error.response?.status === 401) {
          showMessage('Session has expired', 'error');
          dispatch(sessionExpired(true) as any);
        } else {
          // showMessage('Failed to fetch calls. Please try again later.', 'error');
          console.error('Failed to fetch calls:', error.message);
        }
      }
    }
  };

  useEffect(() => {
    // fetchGlobalAssistants();
  }, [assistantId, user?.token]);

  useEffect(() => {
    if (!assistant) {
      setAssistant(assistants.find((assistant: AssistantMetadata) => assistant.assistantId === assistantId) || null);
    }
  }, [user, assistants.length]);

  if (loading) {
    return <CircularProgress />;
  }

  const handleBack = () => {
    navigate(`/assistants`); // Navigate back to the assistant history
  };

  const handleTabChange = (tab: AssistantDetailTabs) => {
    navigate(`/assistants/${assistantId}/${tab}`); // Navigate to the selected tab
    setSelectedView(tab);
  };

  interface AddPatientModalProps {
    open: boolean;
    onClose: () => void;
  }

  const handleSetAvailableDates = (newDates: string[]) => {
    setAvailableDates(Array.from(new Set([...availableDates, ...newDates])));
  };

  const handleRemoveDate = (index: number) => {
    setAvailableDates(prevDates => prevDates.filter((_, idx) => idx !== index));
  };

  const handleFileChange = (file: File) => {
    if (file) {
      Papa.parse(file, {
        complete: function(results: any) {
          const headers = results.data[0];
          const dataRows = results.data.slice(1); // Exclude the header row

          // Initialize columns with header names
          const columns = headers.map((header: any) => ({
            name: header,
            data: []
          }));

          // Generate mappings with indices
          const headerMappings = headers.map((header: any, index: any) => ({
            name: header,
            index: index,
            type: header
          }));

          setHeaderMappings(headerMappings);

          let nonEmptyRowCount = 0;

          dataRows.forEach((row: any) => {
            let isRowEmpty = row.every((cell: any) => cell.trim() === ''); // Check if every cell in the row is empty

            if (!isRowEmpty) {
              nonEmptyRowCount++;
              columns.forEach((column: any, index: number) => {
                column.data.push(row[index]);
              });
            }
          });

          setColumns(columns);
          setDataRowsCount(nonEmptyRowCount);
          setDataRows(dataRows);
        },
        header: false
      });
      setUploadCSVModalOpen(false);
      setMatchingColumnsModalOpen(true);
    }
  };

  const handleUploadAllButton = async (fullData: any) => {
    try {
      setUploadCSVLoading(true);
      if (!user || !user?.id || !user?.token) {
        showMessage('No user logged in', 'error');
        return;
      }

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/${assistantId}/upload-waitlist-patients/csv`, {
        data: fullData,
        headerMappings: headerMappings,
        userId: user?.id,
      }, {
        headers: {
          'Authorization': `Bearer ${user?.token}`,
        }
      });

      if (response.status === 200) {
        showMessage('All data uploaded successfully', 'success');
        setMatchingColumnsModalOpen(false);
        fetchAlternateWaitlistPatients();
      } else {
        // showMessage('Failed to upload data. Please try again later.', 'error');
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        dispatch(sessionExpired(true) as any);
      } else {
        console.error('Error uploading data:', error);
        // showMessage('Error occurred during upload. Please try again.', 'error');
      }
    } finally {
      setUploadCSVLoading(false);
    }
  };

  const handleUpdatePatient = async (updatedPatient: FrontendWaitlistPatient) => {
    try {
      if (!user || !user?.id || !user?.token) {
        showMessage('No user logged in', 'error');
        return;
      }

      setEditingPatientLoading(true);

      const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/update-waitlist-patient`, {
        patient: updatedPatient,
      },
      {
        headers: {
          'Authorization': `Bearer ${user.token}`,
        }
      });

      if (response.status === 200) {
        setEditingPatient(null);
        fetchAlternateWaitlistPatients();
        setEditingPatientErrorMessage('');
      } else {
        // showMessage('Failed to add patient. Please try again later.', 'error');
      } 
    } catch (error: any) {
      if (error.response?.status === 401) {
        dispatch(sessionExpired(true) as any);
      } else {
        setEditingPatientErrorMessage('Failed to update patient. Please try again later.');
        console.error('Failed to update patient:', error.message);
        // showMessage('Failed to update patient. Please try again later.', 'error');
      }
    } finally {
      setEditingPatientLoading(false);
    }
  };

  const handleAddPatient = async () => {
    if (!user || !user?.id || !user?.token) {
      showMessage('No user logged in', 'error');
      return;
    }
    const newPatient: FrontendWaitlistPatient = {
      name: patientName.trim(),
      pcpName: primaryProvider.trim(),
      patientPhoneNumber: convertToCallablePhoneNumber(phoneNumber),
      appointmentLength: appointmentLength,
      priority: priority,
      dates: availableDates,
      prefersShift: prefersShift,
    };

    if (!newPatient.name || !newPatient.pcpName || !newPatient.patientPhoneNumber || !newPatient.appointmentLength || !newPatient.dates.length) {
      showMessage('Please fill out all fields', 'error');
      setAddPatientErrorMessage('Please fill out all fields');
      return;
    }

    try {
      if (!user || !user?.id) { return; }
      setAddPatientLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/${assistantId}/upload-waitlist-patients`, {
        patient: newPatient,
        userId: user.id,
      },
      {
        headers: {
          'Authorization': `Bearer ${user.token}`,
        }
      });

      if (response.status === 200) {
        showMessage(response.data, 'success');
        setAddPatientModalOpen(false);
        // setPatients([...patients, newPatient].sort(compare));
        fetchAlternateWaitlistPatients();

        resetAddPatientFields();
        setAddPatientErrorMessage('');
      } else {
        // showMessage('Failed to add patient. Please try again later.', 'error');
      }
    } catch (error: any) {
      console.error("error: ", error);
      setAddPatientErrorMessage('Failed to add patient. Please try again later.');
      resetAddPatientFields();
      if (error.response?.status === 401) {
        dispatch(sessionExpired(true) as any);
      } else if (error.response?.status === 500 && error.response?.data === 'Patient already exists in waitlist') {
        showMessage('Patient already exists in waitlist.', 'error');
        setAddPatientErrorMessage('Patient already exists in waitlist.');
        console.error('Failed to add patient:', error.message);
      } else {
        // showMessage('An error ocurred. Please try again later.', 'error');
        console.error('An error ocurred. Please try again later:', error.message);
      }
    } finally {
      setAddPatientLoading(false);
    }
  };

  const handleRunWaitlist = async () => {
    try {
      setUploadingWaitlistRunLoading(true);
      if (!user || !user?.id || !user?.token) {
        showMessage('No user logged in', 'error');
        return;
      }

      if (!user) { return; }
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/${assistantId}/waitlist/${WaitlistActions.uploadWaitlistRun}`, {
        patients: sortedPatients,
        appointment: currentAppointment,
        userId: user?.id,
      },
      {
        headers: {
          'Authorization': `Bearer ${user.token}`,
        }
      });

      if (response.status === 200) {
        setAddPatientModalOpen(false);
        setCampaignActionsModalOpen(true);
        dispatch(addWaitlistRun(response.data) as any);
        navigate(`/assistants/${assistantId}/runs/${response.data.waitlistRunId}`);
      } else {
        // showMessage('Something went wrong. Please try again.', 'error');
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        dispatch(sessionExpired(true) as any);
      } else {
        // showMessage('Failed to run waitlist. Please try again later.', 'error');
        console.error('Failed to run waitlist:', error.message);
      }
    } finally {
      setRunWaitlistModalOpen(false);
      setUploadingWaitlistRunLoading(false);
    }
  };
  
  const handleOnAddPatientModalClose = () => {
    setPatientName('');
    setPrimaryProvider('');
    setPhoneNumber('+1');
    setAppointmentLength(0);
    setPriority(false);
    setAvailableDates([]);
    setAddPatientErrorMessage('');
    setAddPatientModalOpen(false);
  };

  // Function to apply column mappings to the full dataset
  const processData: any = (data: any[], mappings: any) => {
    return data.map((item: any) => {
      const newItem: any = {};
      mappings.forEach((mapping: any) => {
        newItem[mapping.type] = item[mapping.name];
      });
      return newItem;
    });
  };

  const handleCreateAppointment = async () => {
    try {
      if (!user || !user?.id || !user?.token) {
        showMessage('No user logged in', 'error');
        return;
      }

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/create-waitlist-appointment`, {
        userId: user.id,
        patientName: patientName,
        pcpName: pcpName,
        appointmentDate: appointmentDate?.toISO(),
        appointmentLength: appointmentLengthCreateAppointment,
        shift: appointmentShift
      }, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
        }
      });

      if (response.status === 200) {
        showMessage(response.data, 'success');
        setCreateAppointmentModalOpen(false);
        fetchAppointments();
      } else {
        // showMessage('Failed to create appointment. Please try again later.', 'error');
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        dispatch(sessionExpired(true) as any);
      } else {
        // showMessage('Failed to create appointment. Please try again later.', 'error');
        console.error('Failed to create appointment:', error.message);
      }
    }
  };

  const handleDeletePatient = async (patientPhoneNumber: string) => {
    try {
      if (!user || !user?.id || !user?.token) {
        showMessage('No user logged in', 'error');
        return;
      }

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/delete-waitlist-patient`, {
        patientPhoneNumber: patientPhoneNumber,
      }, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
        }
      });

      if (response.status === 200) {
        showMessage('Patient deleted successfully', 'success');
        setPatients(patients.filter((patient: FrontendWaitlistPatient) => patient.patientPhoneNumber !== patientPhoneNumber));
      } else {
        // showMessage('Failed to delete patient. Please try again later.', 'error');
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        dispatch(sessionExpired(true) as any);
      } else {
        // showMessage('Failed to delete patient. Please try again later.', 'error');
        console.error('Failed to delete patient:', error.message);
      }
    }
  };

  const handleDeleteAppointment = async (waitlistAppointmentId: string) => {
    try {
      if (!user || !user?.id || !user?.token) {
        showMessage('No user logged in', 'error');
        return;
      }

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/delete-waitlist-appointment`, {
        waitlistAppointmentId: waitlistAppointmentId,
      }, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
        }
      });

      if (response.status === 200) {
        showMessage('Waitlist appointment deleted successfully', 'success');
        await fetchAppointments();
        setAppointments(appointments.filter((appointment: OpenWaitlistAppointment) => appointment.id !== waitlistAppointmentId));
        setCurrentAppointment(null);

        // fully remove all state having to do with the old appointment or run id
        setCampaignActionsModalOpen(false);

        // delete associated waitlist run with redux
        dispatch(deleteWaitlistRun({ waitlistRunId: waitlistAppointmentId }) as any);
      } else {
        // showMessage('Failed to delete appointment. Please try again later.', 'error');
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        dispatch(sessionExpired(true) as any);
      } else {
        // showMessage('Failed to delete appointment. Please try again later.', 'error');
        console.error('Failed to delete appointment:', error.message);
      }
    }
  };

  const handleDeleteTakenAppointment = async (takenAppointmentId: string) => {
    try {
      if (!user || !user?.id || !user?.token) {
        showMessage('No user logged in', 'error');
        return;
      }

      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/taken-appointment/${takenAppointmentId}`, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
        }
      });

      if (response.status === 200) {
        showMessage('Taken appointment deleted successfully', 'success');
        setTakenAppointments(takenAppointments.filter((takenAppointment: TakenWaitlistAppointment) => takenAppointment.id !== takenAppointmentId));
      } else {
        // showMessage('Failed to delete taken appointment. Please try again later.', 'error');
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        dispatch(sessionExpired(true) as any);
      } else {
        // showMessage('Failed to delete taken appointment. Please try again later.', 'error');
        console.error('Failed to delete taken appointment:', error.message);
      }
    }
  };

  return (
    <>
    <Modal
      open={campaignActionsModalOpen}
      onClose={() => setCampaignActionsModalOpen(false)}
      title="Campaign Actions"
    >
      <>
        
        { !uploadingWaitlistRunLoading && (
          <>
          <ModalBackdrop>
            <ModalContainer>
              <Box sx={{ textAlign: 'center', mb: 3 }}></Box>
                <Typography variant="h4" component="div" sx={{ mb: 2, textAlign: 'center', fontWeight: 'bold' }}>
                  Start campaign
                </Typography>
                { uploadingWaitlistRunLoading && <LoadingWithSubtitle subtitle="Uploading waitlist run..." size={60} /> }
                <Button onClick={() => setCampaignActionsModalOpen(false)}>Validate patients</Button>
            </ModalContainer>
          </ModalBackdrop>
          </>
        )}
      </>
    </Modal>
      {!!assistant && assistant.workflowType !== Integrations.waitlist && (
        <Box>
          <IconButton onClick={handleBack} sx={{ mr: 2 }}>
            <ArrowBackIcon />
          </IconButton>
          <LayoutContainer>
            <ContentContainer>
              <AppBarContainer>
                {/* Hardcoded tabs for History and Queue */}
                {/* <ToggleTabButton
                  active={selectedView === AssistantView.history}
                  onClick={() => handleTabChange(AssistantView.history)}
                >
                  History
                </ToggleTabButton> */}
  
                {!assistant.integrations.includes(Integrations.waitlist) && (
                  <ToggleTabButton
                    active={selectedView === AssistantView.queue}
                    onClick={() => handleTabChange(AssistantView.queue)}
                  >
                    Queue
                  </ToggleTabButton>
                )}

                {assistant.integrations.includes(Integrations.waitlist) && (
                  <ToggleTabButton
                    active={selectedView === AssistantView.runs}
                    onClick={() => handleTabChange(AssistantView.runs)}
                  >
                    Runs
                  </ToggleTabButton>
                )}
  
                {/* Dynamic tabs based on assistant integrations */}
                {assistant.integrations.map((integration: AssistantDetailTabs, index: number) => (
                  <ToggleTabButton
                    key={index}
                    active={selectedView === integration.toLowerCase()}
                    onClick={() => handleTabChange(integration.toLowerCase() as Integrations)}
                  >
                    {integration}
                  </ToggleTabButton>
                ))}
              </AppBarContainer>
              <Box>
                {(() => {
                  switch (selectedView) {
                    // case AssistantView.history:
                    //   return <History />;
                    case AssistantView.queue:
                      return <Queue />;
                    case AssistantView.runs:
                      return <WaitlistRuns />;
                    default:
                      // map through the assistant's custom integrations
                      const selectedAssistant = assistants.find((assistant: AssistantMetadata) => assistant?.integrations.includes(selectedView as Integrations));
                      if (selectedAssistant) {
                        switch (selectedView) {
                          default:
                            return <Typography>Integration not implemented</Typography>;
                        }
                      } else {
                        return <Typography>Integration not found</Typography>;
                      }
                  }
                })()}
              </Box>
            </ContentContainer>
          </LayoutContainer>
        </Box>
      )}
      { !!assistant && assistant.workflowType === Integrations.waitlist && (
        <>
          <AddPatientModal
            open={addPatientModalOpen}
            onClose={() => {
              posthog?.capture('[SECRETARY] closed_add_patient_modal_without_uploading_patient', { assistantId: assistantId, userId: user?.id });
              handleOnAddPatientModalClose();
            }}
            onSuccess={() => {
              posthog?.capture('[SECRETARY] uploaded_patient_via_add_patient', { assistantId: assistantId, userId: user?.id, appointmentLength: appointmentLength, priority: priority, prefersShift: prefersShift, availableDates: availableDates });
              handleAddPatient();
            }}
            patientName={patientName}
            setPatientName={setPatientName}
            primaryProvider={primaryProvider}
            setPrimaryProvider={setPrimaryProvider}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            appointmentDates={availableDates}
            setAppointmentDates={handleSetAvailableDates}
            handleRemoveDate={handleRemoveDate}
            appointmentLength={appointmentLength}
            setAppointmentLength={setAppointmentLength}
            priority={priority}
            setPriority={setPriority}
            prefersShift={prefersShift}
            setPrefersShift={setPrefersShift}
            errorMessage={addPatientErrorMessage}
            loading={addPatientLoading}
          />
          { editingPatient && (
            <EditPatientModal
              open={!!editingPatient}
              onClose={() => {
                posthog?.capture('[SECRETARY] closed_edit_patient_modal_without_uploading_patient', { assistantId: assistantId, userId: user?.id });
                setEditingPatient(null);
              }}
              patient={editingPatient}
              setPatient={(editedPatient: FrontendWaitlistPatient) => setEditingPatient(editedPatient)}
              onSuccess={() => {
                posthog?.capture('[SECRETARY] updated_patient', { assistantId: assistantId, userId: user?.id });
                handleUpdatePatient(editingPatient);
              }}
              errorMessage={editingPatientErrorMessage}
              loading={editingPatientLoading}
            />
          )}
          <UploadCSVModal
            open={uploadCSVModalOpen}
            onClose={() => setUploadCSVModalOpen(false)}
            onFileUpload={handleFileChange}
          />
          <MatchColumnsModal
            open={matchingColumnsModalOpen}
            onClose={() => setMatchingColumnsModalOpen(false)}
            columns={columns}
            numRows={dataRowsCount}
            fullData={dataRows}
            onSuccess={() => handleUploadAllButton(dataRows)}
            loading={uploadCSVLoading}
          />
          {
            fetchPatientsLoading &&
            <CircularProgress />
          }
          { !fetchPatientsLoading && runWaitlistModalOpen &&
            <RunWaitlistModal
              open={runWaitlistModalOpen}
              onClose={() => {
                setSortedPatients(patients.sort(compare));
                setRunWaitlistModalOpen(false);
                setCurrentAppointment(null);
                setVisibleCount(3);
              }}
              patients={sortedPatients}
              setPatients={setSortedPatients}
              onRunWaitlist={() => {
                posthog?.capture('[SECRETARY] run_waitlist', { assistantId: assistantId, userId: user?.id });
                handleRunWaitlist();
              }}
              appointment={currentAppointment}
            />
          }
          <CreateAppointmentModal
            open={createAppointmentModalOpen}
            onClose={() => setCreateAppointmentModalOpen(false)}
            onSuccess={() => {
              posthog?.capture('[SECRETARY] create_appointment', { assistantId: assistantId, userId: user?.id });
              handleCreateAppointment();
            }}
            appointmentDate={appointmentDate}
            setAppointmentDate={setAppointmentDate}
            appointmentLength={appointmentLengthCreateAppointment}
            setAppointmentLength={setAppointmentLengthCreateAppointment}
            pcpName={pcpName}
            setPcpName={setPcpName}
            contactMethods={contactMethods}
            setContactMethod={setContactMethod}
            shift={appointmentShift}
            setShift={setAppointmentShift}
          />
          <Box>
          <IconButton onClick={handleBack} sx={{ mr: 2 }}>
            <ArrowBackIcon />
          </IconButton>
          {/* <LayoutContainer> */}
          <RunsContainer>
          </RunsContainer>
          { !showHistory && (
            <>
          <RowDiv>
            <TakenAppointmentsBox>
              <SubRowDiv>
                <h3>Taken Appointments ({takenAppointments.length})</h3>
              </SubRowDiv>
              <SubRowDiv>
              <AppointmentContainer>
              { takenAppointments.length > 0 && takenAppointments?.map((takenAppointment: TakenWaitlistAppointment, index: number) => (
                <TakenWaitlistAppointmentRow
                  physicianName={takenAppointment.pcpName}
                  appointmentDate={takenAppointment.appointmentDate}
                  appointmentLength={takenAppointment.appointmentLength}
                  onClick={() => {
                    posthog?.capture('[SECRETARY] clicked_taken_waitlist_appointment_row', { assistantId: assistantId, userId: user?.id, takenAppt: takenAppointment.id });
                    navigate(`/assistants/${assistantId}/runs/${takenAppointment.patient.waitlistRunId}`);
                  }}
                  patient={takenAppointment.patient}
                  onDelete={() => {
                    posthog?.capture('[SECRETARY] clicked_delete_taken_waitlist_appointment', { assistantId: assistantId, userId: user?.id, takenAppt: takenAppointment.id });
                    handleDeleteTakenAppointment(takenAppointment.id);
                  }}
                />
              ))}
            </AppointmentContainer>
            </SubRowDiv>
            </TakenAppointmentsBox>
            <StyledBox>
              <SubRowDiv>
                <h3>Open Appointments ({appointments.length})</h3>
                {
                  appointments.length > 0 ? (
                    <Tooltip title="Button enables at 8 AM" placement="top">
                      <div>
                        <OutlinedButton 
                          // disabled={DateTime.now() < DateTime.now().set({ hour: 8, minute: 0, second: 0 }) || DateTime.now() > DateTime.now().set({ hour: 21, minute: 0, second: 0 })}
                          onClick={() => {
                            posthog?.capture('[SECRETARY] clicked_create_waitlist_appointment (multiple running appointments)', { assistantId: assistantId, userId: user?.id });
                            setCreateAppointmentModalOpen(true);
                          }}
                        >
                          Create new
                        </OutlinedButton>
                      </div>
                    </Tooltip>
                  ) : (
                    <div>
                      <OutlinedButton 
                        // disabled={DateTime.now() < DateTime.now().set({ hour: 8, minute: 0, second: 0 }) || DateTime.now() > DateTime.now().set({ hour: 21, minute: 0, second: 0 })}
                        onClick={() => {
                          posthog?.capture('[SECRETARY] clicked_create_waitlist_appointment (single appointment)', { assistantId: assistantId, userId: user?.id });
                          setCreateAppointmentModalOpen(true)
                        }}
                      >
                        Create new
                      </OutlinedButton>
                    </div>
                  )
                }
              </SubRowDiv>
              <SubRowDiv>
              <AppointmentContainer>
                { appointments.length > 0 && appointments?.map((appointment: OpenWaitlistAppointment, index: number) => (
                  <PhysicianRow
                    appointment={appointment}
                    onClick={() => {
                      posthog?.capture('[SECRETARY] clicked_open_waitlist_appointment_row', { assistantId: assistantId, userId: user?.id, openAppt: appointment.id });
                      setCurrentAppointment(appointment);
                      setRunWaitlistModalOpen(true);
                    }}
                    onDelete={() => handleDeleteAppointment(appointment.id)}
                    loadingGettingWaitlistRuns={loadingGettingRuns}
                  />
                ))}
              </AppointmentContainer>
              </SubRowDiv>
            </StyledBox>
            <StyledBox>
              <SubRowDiv>
                <h3>Waitlist ({patients.length})</h3>
                <OutlinedButton onClick={() => setUploadCSVModalOpen(true)}>
                  Import
                </OutlinedButton>
                <OutlinedButton onClick={() => setAddPatientModalOpen(true)}>
                  Add new
                </OutlinedButton>
              </SubRowDiv>
              { patients.length > 0 && patients.slice(0, visibleCount).map((patient: FrontendWaitlistPatient, index: number) => (
                <PatientRow
                  index={index + 1}
                  patient={patient}
                  onClick={() => setEditingPatient(patient)}
                  onDelete={() => {
                    posthog?.capture('[SECRETARY] clicked_delete_waitlist_patient', { assistantId: assistantId, userId: user?.id, patient: patient.patientPhoneNumber });
                    handleDeletePatient(patient.patientPhoneNumber);
                  }}
                />
              ))}
              {visibleCount < patients.length && (
                <OutlinedButton onClick={() => {
                  posthog?.capture('[SECRETARY] clicked_view_more_waitlist_patients', { assistantId: assistantId, userId: user?.id });
                  handleViewMore();
                }}>
                  View more
                </OutlinedButton>
              )}
            </StyledBox>
          </RowDiv>
          </>
          )}
          {archivedTakenAppointmentLoading && <LoadingWithSubtitle subtitle="Loading archived taken appointments..." size={60} />}
          {archivedTakenWaitlistAppointments.length > 0 && (
            <Box>
              <h3>Archived taken appointments ({archivedTakenWaitlistAppointments.length})</h3>
              <Typography variant="body2" sx={{ mb: 2 }}>These are appointments that have been completed and are no longer in the queue.</Typography>
              <AppointmentContainer>
              {archivedTakenWaitlistAppointments.slice(0, visibleArchivedCount).map((takenAppointment: TakenWaitlistAppointment, index: number) => (
                takenAppointment && takenAppointment.id && takenAppointment.pcpName && takenAppointment.appointmentDate && takenAppointment.appointmentLength && takenAppointment.patient ? (
                  <ArchivedTakenWaitlistAppointmentRow
                    key={takenAppointment.id}
                    physicianName={takenAppointment.pcpName}
                    appointmentDate={takenAppointment.appointmentDate}
                    appointmentLength={takenAppointment.appointmentLength}
                    onClick={() => {
                      posthog?.capture('[SECRETARY] clicked_archived_taken_waitlist_appointment_row', { assistantId: assistantId, userId: user?.id, takenAppt: takenAppointment.id });
                      navigate(`/assistants/${assistantId}/runs/${takenAppointment.patient.waitlistRunId}`);
                    }}
                    patient={takenAppointment.patient}
                  />
                ) : null
              ))}
              </AppointmentContainer>
              {visibleArchivedCount < archivedTakenWaitlistAppointments.length && (
                <OutlinedButton onClick={() => {
                  posthog?.capture('[SECRETARY] clicked_view_more_archived_taken_waitlist_appointments', { assistantId: assistantId, userId: user?.id });
                  handleViewMoreArchivedAppointments();
                }}>
                  View more
                </OutlinedButton>
              )}
            </Box>
          )}
          </Box>
        </>
      )}
    </>
  );  
};

export default AssistantDetail;
