// slices/callsSlice.tsx

import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios from 'axios';
import { AssistantMetadata, FrontendCall } from '../types';
import { sessionExpired } from './sessionSlice';

export interface CallsSlice {
  calls: FrontendCall[];
  assistants: AssistantMetadata[];
  loading: boolean;
  lastEvaluatedKey: any;
  finalPage: boolean;
}

const initialState: CallsSlice = {
  calls: [],
  assistants: [],
  loading: false,
  lastEvaluatedKey: undefined,
  finalPage: false,
};

export const refreshCallsPaginated = createAsyncThunk<
  { calls: FrontendCall[]; lastEvaluatedKey: any }, // Expected return type
  { token: string; assistantIds?: string[]; }, // Arguments passed to the thunk
  { rejectValue: Error }
>("calls/fetchCalls/paginated/refresh", async ({ token, assistantIds }, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/calls/paginated`,
      { assistantIds },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data) {
      const calls: FrontendCall[] = response.data.calls;
      return {
        calls: calls,
        lastEvaluatedKey: response.data.lastEvaluatedKey,
      };
    }
    throw new Error("Invalid response data");
  } catch (error: any) {
    console.log("calls error: ", error);
    if (error.response?.status === 401) {
      sessionExpired(true);
    }
    return rejectWithValue(new Error("Failed to fetch calls"));
  }
});

export const nextCallsPaginated = createAsyncThunk<
  { calls: FrontendCall[]; lastEvaluatedKey: any }, // Expected return type
  { token: string; lastEvaluatedKey: any; assistantIds?: string[]; }, // Arguments passed to the thunk
  { rejectValue: Error }
>("calls/fetchCalls/paginated/next", async ({ token, lastEvaluatedKey, assistantIds }, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/calls/paginated`,
      { lastEvaluatedKey: lastEvaluatedKey, assistantIds },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data) {
      const calls: FrontendCall[] = response.data.calls;
      return {
        calls: calls,
        lastEvaluatedKey: response.data.lastEvaluatedKey,
      };
    }
    throw new Error("Invalid response data");
  } catch (error: any) {
    console.log("calls error: ", error);
    if (error.response?.status === 401) {
      sessionExpired(true);
    }
    return rejectWithValue(new Error("Failed to fetch calls"));
  }
});

const callsSlice = createSlice({
  name: 'calls',
  initialState,
  reducers: {
    clearCalls: (state) => {
      state.calls = [];
      state.lastEvaluatedKey = undefined;
      state.finalPage = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshCallsPaginated.pending, (state) => {
        state.loading = true;
        state.calls = [];
        state.finalPage = false;
        state.lastEvaluatedKey = undefined;
      })
      .addCase(refreshCallsPaginated.fulfilled, (state, action) => {
        const newCalls = action.payload.calls.filter((newCall) => !state.calls.some((existingCall) => existingCall.callId === newCall.callId));
        state.calls = [...state.calls, ...newCalls];
        state.lastEvaluatedKey = action.payload.lastEvaluatedKey;
        if (!action.payload.lastEvaluatedKey) {
          state.finalPage = true;
        } else {
          state.finalPage = false;
        }
        state.loading = false;
      })
      .addCase(refreshCallsPaginated.rejected, (state, action) => {
        state.loading = false;
        // Handle error state
      })
      .addCase(nextCallsPaginated.pending, (state) => {
        state.loading = true;
      })
      .addCase(nextCallsPaginated.fulfilled, (state, action) => {
        const newCalls = action.payload.calls.filter((newCall) => !state.calls.some((existingCall) => existingCall.callId === newCall.callId));
        state.calls = [...state.calls, ...newCalls];
        state.lastEvaluatedKey = action.payload.lastEvaluatedKey;
        if (!action.payload.lastEvaluatedKey) {
          state.finalPage = true;
        } else {
          state.finalPage = false;
        }
        state.loading = false;
      })
      .addCase(nextCallsPaginated.rejected, (state, action) => {
        state.loading = false;
        // Handle error state
      });
  },
});

export const { clearCalls } = callsSlice.actions;

export const selectCalls = (state: RootState) => state.calls.calls;

export default callsSlice.reducer;
