import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import posthog from 'posthog-js';
import { PostHogProvider} from 'posthog-js/react';
import { SnackbarProvider } from './providers/SnackbarProvider';
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import SessionCheck from './sessionCheck';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './auth/AuthContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LicenseInfo } from "@mui/x-license-pro";

if (!process.env.REACT_APP_PUBLIC_POSTHOG_KEY) {
  throw new Error('REACT_APP_PUBLIC_POSTHOG_KEY not set');
}

posthog.init(
  process.env.REACT_APP_PUBLIC_POSTHOG_KEY,
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (!process.env.REACT_APP_MUI_PRO_LICENSE) {
  throw new Error("REACT_APP_MUI_PRO_LICENSE not set");
}

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_LICENSE);


root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <PostHogProvider client={posthog}>
          <SnackbarProvider>
            <Router>
              <SessionCheck>
                <AuthProvider>
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <App />
                  </LocalizationProvider>
                </AuthProvider>
              </SessionCheck>
            </Router>
          </SnackbarProvider>
        </PostHogProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
