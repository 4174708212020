// timeoutUtils.tsx

import { AppDispatch } from '../store';
import { signOut } from '../auth/authSlice';

export const startTimeout = (dispatch: any, onExpire: () => void) => {
  const TIMEOUT_DURATION = 30 * 60 * 1000; // 30 minutes
  // const TIMEOUT_DURATION = 5 * 1000; // 5 seconds

  const timeoutId = setTimeout(() => {
    onExpire(); // Call the callback to show the session expired modal
  }, TIMEOUT_DURATION);

  return timeoutId;
};

export const resetTimeout = (timeoutId: NodeJS.Timeout, dispatch: AppDispatch, onExpire: () => void) => {
  clearTimeout(timeoutId);
  return startTimeout(dispatch, onExpire);
};
