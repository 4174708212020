// slices/organizationDetailsSlice.tsx

import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios from 'axios';
import { Organization } from '../types'; // Assuming you have types defined
import { sessionExpired } from './sessionSlice';

export interface OrganizationState {
  organization: Organization | null;
  loading: boolean;
}

const initialState: OrganizationState = {
  organization: null,
  loading: false,
};

export const fetchOrganization = createAsyncThunk<
  Organization,
  { userId: string, token: string },
  { rejectValue: Error }
>(
  'organizationDetails/fetchOrganizationDetails',
  async ({ userId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/organizations/user/${userId}`, 
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });

      if (response.data) {
        return response.data as Organization;
      }
      throw new Error('Invalid response data');
    } catch (error: any) {
      if (error.response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error('Failed to fetch organization details'));
    }
  }
);

const organizationSlice = createSlice({
  name: 'organizationDetails',
  initialState,
  reducers: {
    clearOrganization: (state) => {
      state.organization = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganization.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOrganization.fulfilled, (state, action) => {
        state.organization = action.payload;
        state.loading = false;
      })
      .addCase(fetchOrganization.rejected, (state, action) => {
        state.loading = false;
        console.error('Failed to fetch organization details: ', action.error?.message);
      });
  },
});

export const { clearOrganization } = organizationSlice.actions;

export const selectOrganizationDetails = (state: RootState) => state.organization.organization;

export default organizationSlice.reducer;
