// slices/waitlistRunsSlice.tsx

import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios from 'axios';
import { AssistantMetadata, FrontendCall, TakenWaitlistAppointment, WaitlistActions, WaitlistRun } from '../types';
import { sessionExpired } from './sessionSlice';
import { DateTime } from 'luxon';

export interface WaitlistRunsSlice {
  waitlistRuns: WaitlistRun[];
  archivedTakenWaitlistAppointments: TakenWaitlistAppointment[];
  loadingGettingRuns: boolean;
  loadingPatientDeletion: boolean;
  archivedTakenAppointmentLoading: boolean;
}

const initialState: WaitlistRunsSlice = {
  waitlistRuns: [],
  archivedTakenWaitlistAppointments: [],
  loadingGettingRuns: false,
  loadingPatientDeletion: false,
  archivedTakenAppointmentLoading: false,
};

let lastWaitlistRunFetch: DateTime | null = null;
const RATE_LIMTING_SECONDS = 5;

export const fetchWaitlistRun = createAsyncThunk<
  WaitlistRun,
  { waitlistRunId: string, token: string, assistantId: string },
  { rejectValue: Error }
>(
  'waitlistRuns/fetchWaitlistRun',
  async ({ waitlistRunId, token, assistantId }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/waitlist/${waitlistRunId}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        }
      );

      if (response.status === 200) {
        dispatch(updateWaitlistRuns(response.data));
        return response.data;
      } else {
        throw new Error('Failed to get waitlist run.');
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        dispatch(sessionExpired(true));
        return rejectWithValue(new Error('Session has expired'));
      }
      return rejectWithValue(new Error('Failed to get waitlist run.'));
    }
  }
);

export const fetchWaitlistRuns = createAsyncThunk<
  { waitlistRuns: WaitlistRun[] },
  { userId: string, token: string, assistantId: string },
  { rejectValue: Error }
>(
  'calls/fetchWaitlistRuns',
  async ({ userId, token, assistantId }, { getState, rejectWithValue }) => {
    try { // /api/assistants/:assistantId/waitlist/:waitlistRunId/:action
      const now = DateTime.now();

      if (
        lastWaitlistRunFetch &&
        now.diff(lastWaitlistRunFetch, "seconds").seconds < RATE_LIMTING_SECONDS
      ) {
        console.log("Fetch request rate limited");
        return rejectWithValue(new Error("Fetch request rate limited"));
      }

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/${assistantId}/waitlist/${WaitlistActions.getWaitlistRuns}`, 
      {
        userId: userId,
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }); 
      
      lastWaitlistRunFetch = now;

      if (response.data) {
        const waitlistRuns: WaitlistRun[] = response.data;
        return {
          waitlistRuns: waitlistRuns,
        };
      }
      throw new Error('Invalid response data');
    } catch (error: any) {
      if (error.response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error('Failed to fetch waitlist runs.'));
    }
  }
);

export const fetchArchivedTakenWaitlistAppointments = createAsyncThunk<
  { archivedTakenWaitlistAppointments: TakenWaitlistAppointment[] },
  { token: string },
  { rejectValue: Error }
>(
  'calls/fetchArchivedTakenWaitlistAppointments',
  async ({ token }, { rejectWithValue }) => {
    try { // /api/assistants/:assistantId/waitlist/:waitlistRunId/:action
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/deleted-taken-appointments`, 
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });  

      if (response.data) {
        const takenWaitlistAppointments: TakenWaitlistAppointment[] = response.data;
        return {
          archivedTakenWaitlistAppointments: takenWaitlistAppointments,
        };
      }
      throw new Error('Invalid response data');
    } catch (error: any) {
      if (error.response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error('Failed to fetch waitlist runs.'));
    }
  }
);

export const deleteWaitlistPatientFromWaitlist = createAsyncThunk<
  {},
  { waitlistRunId: string, waitlistPatientId: string, token: string },
  { rejectValue: Error }
>(
  'waitlistRuns/deleteWaitlistPatientFromWaitlist',
  async ({ waitlistRunId, waitlistPatientId, token }, { dispatch, rejectWithValue }) => {
    try {
      console.log("url: ", `${process.env.REACT_APP_BACKEND_URL}/api/waitlistRunId/${waitlistRunId}/waitlistPatientId/${waitlistPatientId}`);
      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/waitlistRunId/${waitlistRunId}/waitlistPatientId/${waitlistPatientId}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });  

      if (response.status === 200) {
        dispatch(deletePatientFromWaitlistRun({ waitlistRunId, waitlistPatientId }) as any);
      }

      throw new Error('Something went wrong');
    } catch (error: any) {
      if (error.response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(
        new Error('Failed to fetch waitlist runs.' + error?.response?.data?.message || error?.message || "")
      );
    }
  }
);

const waitlistRunsSlice = createSlice({
  name: 'waitlistRuns',
  initialState,
  reducers: {
    updateWaitlistRuns: (state, action: PayloadAction<WaitlistRun>) => {
      const newRun = action.payload;
      const index = state.waitlistRuns.findIndex((run: WaitlistRun) => run.waitlistRunId === newRun.waitlistRunId);
  
      if (index !== -1) {
        state.waitlistRuns[index] = newRun;
      } else {
        state.waitlistRuns = state.waitlistRuns.filter(run => run.waitlistRunId !== newRun.waitlistRunId);
        state.waitlistRuns.push(newRun);
      }
    },
    deleteWaitlistRun: (state, action: PayloadAction<{ waitlistRunId: string }>) => {
      state.waitlistRuns = state.waitlistRuns.filter(run => run.waitlistRunId !== action.payload.waitlistRunId);
    },
    deletePatientFromWaitlistRun: (state, action: PayloadAction<{ waitlistRunId: string, waitlistPatientId: string }>) => {
      const waitlistRun = state.waitlistRuns.find(run => run.waitlistRunId === action.payload.waitlistRunId);
      if (waitlistRun) {
        waitlistRun.patients = waitlistRun.patients.filter(patient => patient.waitlistPatientId !== action.payload.waitlistPatientId);
      }
    },
    addWaitlistRun: (state, action: PayloadAction<WaitlistRun>) => {
      const exists = state.waitlistRuns.some(
        (run) => run.waitlistRunId === action.payload.waitlistRunId
      );

      if (!exists) {
        state.waitlistRuns.push(action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWaitlistRun.pending, (state) => {
        state.loadingGettingRuns = true;
      })
      .addCase(fetchWaitlistRun.fulfilled, (state, action) => {
        state.loadingGettingRuns = false;
        const newRun = action.payload;
        const index = state.waitlistRuns.findIndex(run => run.waitlistRunId === newRun.waitlistRunId);
        if (index !== -1) {
          state.waitlistRuns[index] = newRun;
        } else {
          state.waitlistRuns.push(newRun);
        }
      })
      .addCase(fetchWaitlistRun.rejected, (state) => {
        state.loadingGettingRuns = false;
      })
      .addCase(deleteWaitlistPatientFromWaitlist.pending, (state) => {
        state.loadingPatientDeletion = true;
      })
      .addCase(deleteWaitlistPatientFromWaitlist.fulfilled, (state) => {
        state.loadingPatientDeletion = false;
      })
      .addCase(deleteWaitlistPatientFromWaitlist.rejected, (state) => {
        state.loadingPatientDeletion = false;
      })
      .addCase(fetchWaitlistRuns.pending, (state) => {
        state.loadingGettingRuns = true;
      })
      .addCase(fetchWaitlistRuns.fulfilled, (state, action) => {
        state.waitlistRuns = action.payload.waitlistRuns;
        state.loadingGettingRuns = false;
      })
      .addCase(fetchWaitlistRuns.rejected, (state, action) => {
        state.loadingGettingRuns = false;
      })
      .addCase(fetchArchivedTakenWaitlistAppointments.pending, (state) => {
        state.archivedTakenAppointmentLoading = true;
      })
      .addCase(fetchArchivedTakenWaitlistAppointments.fulfilled, (state, action) => {
        state.archivedTakenWaitlistAppointments = action.payload.archivedTakenWaitlistAppointments;
        state.archivedTakenAppointmentLoading = false;
      })
      .addCase(fetchArchivedTakenWaitlistAppointments.rejected, (state, action) => {
        state.archivedTakenAppointmentLoading = false;
      });
  },
});


export const { updateWaitlistRuns, deleteWaitlistRun, deletePatientFromWaitlistRun, addWaitlistRun } = waitlistRunsSlice.actions;

export const selectWaitlistRuns = (state: RootState) => state.waitlistRuns.waitlistRuns;

export default waitlistRunsSlice.reducer;
