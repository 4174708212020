import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { Colors } from '../../../Colors';
import { GiantMessageIcon, ModalBackdrop, ModalButton, ModalContainer, TwoChoiceModalProps } from '../../styles';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { WaitlistRun } from '../../../types';

const MemoizedDateTimePicker = React.memo(DateTimePicker);

const TextCampaignConfirmationModal: React.FC<TwoChoiceModalProps & { 
  waitlistRun: WaitlistRun, 
  automatedTextingEnabled: boolean, 
  setAutomatedTextingEnabled: (bool: boolean) => void,
  cutoffDate: DateTime,
  setCutoffDate: (date: DateTime) => void,
 }
  > = ({ isOpen, 
  onCancel, 
  onConfirm, 
  message, 
  actionMessageOne, 
  actionMessageTwo, 
  waitlistRun,
  automatedTextingEnabled,
  setAutomatedTextingEnabled,
  cutoffDate,
  setCutoffDate
 }) => {
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  useEffect(() => {
    setCutoffDate(DateTime.fromISO(waitlistRun.appointmentDate).minus({ hour: 1 }));
    setErrorMsg(null);
  }, []);

  const handleSetCutoffDate = useCallback((newDate: DateTime | null) => {
    if (!newDate) {
      setCutoffDate(DateTime.now().plus({ days: 1 }));
    } else if (newDate < DateTime.fromISO(waitlistRun?.appointmentDate) && newDate > DateTime.now()) {
      setCutoffDate(newDate);
      setErrorMsg(null);
    } else if (newDate < DateTime.now()) {
      setErrorMsg("Cutoff date must be after the current date.");
      return;
    } else {
      setErrorMsg("Cutoff date must be before the appointment date.");
    }
  }, [waitlistRun?.appointmentDate]);

  if (!isOpen) return null;

  return (
    <ModalBackdrop>
      <ModalContainer>
        <Box sx={{ textAlign: 'center', mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <GiantMessageIcon />
        </Box>
          <Typography variant="h4" component="div" sx={{ mb: 2, textAlign: 'center', fontWeight: 'bold' }}>
            Text campaign confirmation
          </Typography>
          <p>{message}</p>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={automatedTextingEnabled}
                onChange={(e) => setAutomatedTextingEnabled(e.target.checked)}
                name="automatedText"
                color="primary"
              />
            }
            label="Enable AI texts"
          />
          { automatedTextingEnabled && (
            <SmartToyIcon />
          )}
        </Box>
        {automatedTextingEnabled && (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <MemoizedDateTimePicker
                label="Cutoff date"
                value={cutoffDate}
                onChange={handleSetCutoffDate}
              />
            </Box>
            <br />
          </>
        )}
        {errorMsg && (
          <Box sx={{ textAlign: 'center', color: Colors.otherColor1 }}>
            {errorMsg}
          </Box>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <ModalButton color={Colors.grey2} onClick={onCancel}>{actionMessageOne}</ModalButton>
          <ModalButton disabled={!!errorMsg} color={Colors.blue1} onClick={onConfirm}>{actionMessageTwo}</ModalButton>
        </Box>
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default TextCampaignConfirmationModal;
