// src/components/Login.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../store'; // Import AppDispatch from your store setup
import { signIn } from '../auth/authSlice';
import { TemporaryUserData } from '../types';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../store'; // Import RootState from your Redux store
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { userPool } from '../auth/cognitoConfig'; // Import userPool from your Cognito configuration
import { Link } from 'react-router-dom';
import { Button, Typography, Box, TextField} from '@mui/material';
import { ReactComponent as SomnLogoSvg } from "../assets/somnLogo.svg";
import { usePostHog } from 'posthog-js/react'
import { error } from 'console';
import { set } from 'date-fns';
import { useSnackbar } from '../providers/SnackbarProvider';
import { sessionExpired } from '../slices/sessionSlice';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const dispatch = useDispatch<AppDispatch>(); // Use AppDispatch type here
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { showMessage } = useSnackbar();

  // Get the authentication state
  const { user, isSuccess, isError, message } = useSelector((state: RootState) => state.auth);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      posthog?.capture('clicked_log_in');
      const userData: TemporaryUserData = { email, password };
      dispatch(signIn(userData) as any);
      if (isError) {
        console.error(message);
        setErrorMessage(message);
        // showMessage('Something went wrong. Please try again later.', 'error');
      } 
      
      if (isSuccess) {
        sessionExpired(false);
      }
    } catch (error: any) {
      console.error('Failed to sign in', error);
      setErrorMessage('Failed to sign in. Please try again later.');
      // showMessage('Failed to sign in. Please try again later.', 'error');
    }
  };

  useEffect(() => {
    setErrorMessage(null);
  }, []);

  useEffect(() => {
    try {
      if (user) {
        navigate('/'); // Redirect to home page on successful login
      }

      if (isError) {
        console.error(message); // Log the error message
        setErrorMessage(message);
      }
    } catch (error: any) {
      console.error('Failed to sign in', error);
      setErrorMessage('Failed to sign in. Please try again later.');
      // showMessage('Failed to sign in. Please try again later.', 'error');
    }
  }, [isSuccess, isError, user, message, navigate]);

  return (
        <Box boxShadow={5} sx={{ p: 6, position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
          <SomnLogoSvg />
          <Box sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
          <Typography component="h1" variant="h5">Login</Typography>
          <form onSubmit={handleSubmit}>

            <Box sx={{ mt: 1 }}>
              <TextField
                fullWidth
                label="Email"
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                margin="normal"
              />
               <TextField
                fullWidth
                label="Password"
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                margin="normal"
              />
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} color="primary">
                Login
              </Button>
              { errorMessage && <Typography color="error">{errorMessage}</Typography> }
            </Box>
          </form>
            <p>
              Don't have an account? <Link to="/signup" style={{ textDecoration: 'none'}}>Sign up</Link>
            </p>
            <p>
              Or forgot your password? <Link to="/password-recovery" style={{ textDecoration: 'none'}}>Recover password</Link>
            </p>
        </Box>
        </Box>
  );
};

export default Login;
