import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import authReducer from './auth/authSlice';
import callsReducer from './slices/callsSlice';
import assistantsReducer from './slices/assistantsSlice';
import sessionReducer from './slices/sessionSlice';
import waitlistRunsReducer from './slices/waitlistRunsSlice';
import organizationReducer from './slices/organizationSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['calls', 'assistants', 'organizations']
};

const rootReducer = combineReducers({
  auth: authReducer,
  calls: callsReducer,
  assistants: assistantsReducer,
  session: sessionReducer,
  waitlistRuns: waitlistRunsReducer,
  organization: organizationReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

let isPurging = false; // Flag to indicate if purging is in progress

let unsubscribe: (() => void) | undefined;

// Listen to signOut action and purge the state of calls and assistants slices
unsubscribe = store.subscribe(() => {
  const state = store.getState();
  if (state.auth.user === null && !isPurging) { // Check if not already purging
    isPurging = true; // Set flag to indicate purging is in progress
    persistor.purge(); // Clear the Redux store
    if (unsubscribe) {
      unsubscribe(); // Unsubscribe from the store
    }
  }
});
