// src/auth/authSlice.tsx
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState, persistor } from '../store';
import { FrontendUser, TemporaryUserData } from '../types';
import { CognitoUser, AuthenticationDetails, CognitoUserSession } from 'amazon-cognito-identity-js';
import { userPool } from './cognitoConfig'; // Import userPool from your Cognito configuration
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { sessionExpired } from '../slices/sessionSlice';

const clientId = process.env.REACT_APP_CLIENT_ID;

if (!clientId) {
  throw new Error('Cognito Client ID must be defined');
}

export interface AuthState {
  user: FrontendUser | null;
  token: string | null;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  message: string;
  checkingAuth: boolean;
  calendlyOauthToken: string | null;
  signUpSuccess?: boolean;
}

const initialState: AuthState = {
  user: null,
  token: null,
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: '',
  checkingAuth: true,
  calendlyOauthToken: null
};

export const setCalendlyOAuthToken = createAsyncThunk<string, string, { rejectValue: Error }>(
  'auth/setCalendlyOAuthToken',
  async (token: string, { rejectWithValue }) => {
    try {
      // Perform any necessary actions or validations before setting the token
      return token; // Return the token to be set in the state
    } catch (error: any) {
      return rejectWithValue(new Error('Failed to set OAuth token'));
    }
  }
);

export const clearCalendlyOAuthToken = createAsyncThunk<void, void, { rejectValue: Error }>(
  'auth/clearCalendlyOAuthToken',
  async (_, { rejectWithValue }) => {
    try {
      // Perform any necessary actions before clearing the token
      return; // No return value needed
    } catch (error: any) {
      return rejectWithValue(new Error('Failed to clear OAuth token'));
    }
  }
);


export const signIn = createAsyncThunk<FrontendUser, TemporaryUserData, { rejectValue: Error }>(
  'auth/signIn',
  async (userData: TemporaryUserData, { rejectWithValue }) => {
    try {
      const authenticationDetails = new AuthenticationDetails({
        Username: userData.email,
        Password: userData.password,
      });

      const cognitoUser = new CognitoUser({
        Username: userData.email,
        Pool: userPool,
      });

      return await new Promise<FrontendUser>((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: async (session) => {
            // Ideally, store the tokens in a context/state here, for example:
            // setAuthTokens({ accessToken: session.getAccessToken().getJwtToken(), idToken: session.getIdToken().getJwtToken() });

            // Decode the JWT token to extract the 'sub' claim
            const decodedToken: { sub: string } = jwtDecode(session.getIdToken().getJwtToken());

            // Construct the user object using the 'sub' claim as the ID
            const user: FrontendUser = {
              id: decodedToken.sub, // Use the 'sub' claim as the user ID
              email: userData.email,
              token: session.getIdToken().getJwtToken(), // Consider how to securely manage the token
              // Add other fields as per your User interface
            };

            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/put-user`, {
              userId: user.id,
              email: user.email,
              // Include any other user details you need to save
            }, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user?.token}`,
              }
            });

            if (response.status !== 200) { // 200 even if user is returning
              reject(new Error('Failed to store user data'));
            }

            resolve(user);
          },
          onFailure: (err) => {
            reject(err);
          },
        });
      });
    } catch (error: any) {
      if (error.message) {
        return rejectWithValue(new Error(error.message));
      }
      return rejectWithValue(new Error('An unknown error occurred'));
    }
  }
);

export const signOut = createAsyncThunk<void, void, { rejectValue: Error }>(
  'auth/signOut',
  async (_, { rejectWithValue }) => {
      try {
          const user = userPool.getCurrentUser();
          if (user) {
              user.signOut();
              // Clear any stored tokens or user data
              localStorage.removeItem('token'); // Adjust based on how you're storing tokens
              localStorage.removeItem('userId');
          }
      } catch (error: any) {
          if (error.message) {
              return rejectWithValue(new Error(error.message));
          }
          return rejectWithValue(new Error('An unknown error occurred'));
      }
  }
);

export const signUp = createAsyncThunk(
  'auth/signUp',
  async (userData: TemporaryUserData, { rejectWithValue }) => {
    try {
      // Sign up user
      await new Promise((resolve, reject) => {
        userPool.signUp(userData.email, userData.password, [], [], (err, result) => {
          if (err) {
            console.error(err);
            reject(err);
          } else {
            resolve(result);
          }
        });
      });

      // Automatically log in the user to obtain a JWT token
      const cognitoUser = new CognitoUser({
        Username: userData.email,
        Pool: userPool,
      });

      // Construct the user object using the 'sub' claim as the ID
      const user: FrontendUser = {
        email: userData.email,
      };

      return user;
    } catch (error) {
      console.error("An error occurred during sign up or user data storage: ", error);
      return rejectWithValue(new Error('An error occurred during sign up or user data storage'));
    }
  }
);

export const recoverPassword = createAsyncThunk<void, string, { rejectValue: Error }>(
  'auth/recoverPassword',
  async (email: string, { rejectWithValue }) => {
    try {
      return new Promise((resolve, reject) => {
        const userData = {
          Username: email,
          Pool: userPool,
        };
        const cognitoUser = new CognitoUser(userData);
        cognitoUser.forgotPassword({
          onSuccess: () => {
            resolve();
          },
          onFailure: (err) => {
            reject(err);
          },
        });
      });
    } catch (error: any) {
        return rejectWithValue(new Error(error.message));
    }
  }
);

export const confirmUser = createAsyncThunk<
  string, // Success result type
  { email: string; code: string }, // Parameters type
  { rejectValue: Error } // RejectValue type
>(
  'auth/confirmUser',
  async ({ email, code }, { rejectWithValue }) => {
    try {
      const userData = { Username: email, Pool: userPool };
      const cognitoUser = new CognitoUser(userData);

      return await new Promise<string>((resolve, reject) => {
        cognitoUser.confirmRegistration(code, true, (err, result) => {
          if (err) {
            reject(err);
          } else {
            resolve(result); // Typically 'SUCCESS'
          }
        });
      });
    } catch (error: any) {
      return rejectWithValue(new Error(error.message || "An error occurred during confirmation."));
    }
  }
);


export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
    setUser: (state, action: PayloadAction<FrontendUser>) => {
      state.user = action.payload;
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
    },
    clearUser: (state) => {
      state.user = null;
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
    },
    setCheckingAuth: (state, action: PayloadAction<boolean>) => {
      state.checkingAuth = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(signIn.fulfilled, (state, action: PayloadAction<FrontendUser>) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.user = action.payload;
      sessionExpired(false);
    });
    builder.addCase(setCalendlyOAuthToken.fulfilled, (state, action: PayloadAction<string>) => {
      state.calendlyOauthToken = action.payload;
    });
    builder.addCase(clearCalendlyOAuthToken.fulfilled, (state) => {
      state.calendlyOauthToken = null;
    });
    builder.addCase(signIn.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;

      // Check if the payload is your custom SecretaryError type
      if (action.payload) {
        // Handle the case where the error is from rejectWithValue
        state.message = action.payload.message;
      } else {
        // Handle the case where the error is not from rejectWithValue
        state.message = action.error.message || 'An unknown error occurred';
      }
    });
    builder.addCase(signOut.fulfilled, (state) => {
      state.user = null;
      state.token = null;
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
    });
    builder.addCase(signUp.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    })
    // Handle signUp fulfilled state
    builder.addCase(signUp.fulfilled, (state, action: PayloadAction<FrontendUser>) => {
      state.isLoading = false;
      state.signUpSuccess = true; // Indicate sign-up success without implying authentication
      state.isError = false;
      state.message = 'Sign up successful. Please verify your email and log in.';
      // Optionally, keep user details for use in immediate next steps, if necessary
    })
    // Handle signUp rejected state
    builder.addCase(signUp.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.user = null;
      state.message = action.error.message || 'Failed to sign up';
    });
  },
});

export const { reset, setUser, clearUser, setCheckingAuth } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
