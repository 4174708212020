import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import Link from '@mui/material/Link';
import { Card, CardContent, Typography, IconButton, Box, Divider, List, ListItemIcon, ListItemText, ListItem, Avatar, CircularProgress, Icon, Tooltip, FormControlLabel, Checkbox, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import AudioPlayer from 'react-audio-player';
import { CallStatusesUi, AssistantTitles, CallOutcomesUi, CallOutcomes, FrontendUser, AssistantMetadata, FrontendCall, WaitlistOutcomes, WaitlistRun, WaitlistPatient, FrontendText, WaitlistPatientStatus, WaitlistStatusUi, WaitlistActions, WaitlistStatus, WaitlistUserOverrideAction, Consent } from '../types';
import Modal from '../components/Modal';
import AssistantModal from '../components/AssistantModal';
import styled from 'styled-components';
import ErrorIcon from '@mui/icons-material/Error';
import { usePostHog } from 'posthog-js/react';

// icons
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TimerIcon from '@mui/icons-material/Timer';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotesIcon from '@mui/icons-material/Notes';
import { OvalOutlineListItem, TranscriptContainer, TranscriptButton, PatientStatusAvatarTooltip, WaitlistActionAvatar, TwoChoiceModal, RefreshButton, StyledIconButton, OvalOutlineListItemWithoutHover, IconWithHover, ModalBackdrop, ModalContainer, ModalButton, TwoChoiceModalProps, GiantPhoneIcon, GiantMessageIcon } from '../components/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectCalls } from '../slices/callsSlice';
import { selectAssistants } from '../slices/assistantsSlice';
import { useSnackbar } from '../providers/SnackbarProvider';
import { AppDispatch, RootState } from '../store';
import { deleteWaitlistPatientFromWaitlist, fetchWaitlistRun } from '../slices/waitlistRunsSlice';
import axios from 'axios';
import { sessionExpired as sessionExpiredGlobally } from '../slices/sessionSlice';
import { statusMessages, formatIsoToCustomDateStringWithEEEEHHMMA, WebsocketEvents } from '../utils';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DateTime } from 'luxon';
import EventIcon from '@mui/icons-material/Event';
import CampaignActions from '../components/subcomponents/CampaignActions';
import DropdownStatusSelector from '../components/subcomponents/DropdownStatusSelector';
import LoadingWithSubtitle from '../components/subcomponents/LoadingWithSubtitle';
import PatientCircle from '../components/subcomponents/PatientCircle';

const WaitlistRunDetail: React.FC = () => {
  const { assistantId, waitlistRunId } = useParams<{ assistantId: string, waitlistRunId: string }>();
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const posthog = usePostHog();
  const [loading, setLoading] = useState(false);
  const { showMessage } = useSnackbar();
  const assistants: AssistantMetadata[] = useSelector(selectAssistants);
  const waitlistRuns: WaitlistRun[] = useSelector((state: RootState) => state.waitlistRuns.waitlistRuns);
  const [waitlistRun, setWaitlistRun] = useState<WaitlistRun | undefined>(waitlistRuns.find((run: WaitlistRun) => run.waitlistRunId === waitlistRunId));
  const [patients, setPatients] = useState<WaitlistPatient[] | undefined>(waitlistRun?.patients);
  const [assistant, setAssistant] = useState<AssistantMetadata | null>(assistants.find((assistant: AssistantMetadata) => assistant.assistantId === assistantId) || null);
  const [isAssistantModalOpen, setAssistantModalOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const lastFetchTimeRef = useRef<number>(0);
  const loadingDeletePatient = useSelector((state: RootState) => state.waitlistRuns.loadingPatientDeletion);
  const [loadingWaitlistRun, setLoadingWaitlistRun] = useState(false);

  useEffect(() => {
    const updatedRun: WaitlistRun | undefined = waitlistRuns.find((run: WaitlistRun) => run.waitlistRunId === waitlistRunId);
    if (updatedRun) {
      setWaitlistRun(updatedRun);
      const uniquePatientsMap = new Map<string, WaitlistPatient>();
      updatedRun.patients?.forEach(patient => {
        if (patient.waitlistPatientId && !uniquePatientsMap.has(patient.waitlistPatientId)) {
          uniquePatientsMap.set(patient.waitlistPatientId, patient);
        }
      });
      const noDuplicatesPatients: WaitlistPatient[] = Array.from(uniquePatientsMap.values());
      setPatients(noDuplicatesPatients);
    }
    return () => {
      setWaitlistRun(undefined);
      setPatients([]);
    };
  }, [waitlistRuns, waitlistRunId]);
  

  useEffect(() => {
    if (!user?.id) { return };
    if (!user?.token) { return };

    const connectWebSocket = async () => {
      try {
        const socket = new WebSocket(`wss://${process.env.REACT_APP_BACKEND_URL_NO_HTTPS}/api/subscribe/${user.id}?token=${encodeURIComponent(user.token!)}`);

        socket.onopen = () => {
          console.log('Connected to WebSocket');
          socket.send(JSON.stringify({ action: 'subscribe', type: WebsocketEvents.WaitlistRuns }));
          socket.send(JSON.stringify({ action: 'subscribe', type: WebsocketEvents.WaitlistPatients }));
        };

        socket.onmessage = (event: any) => {
          console.log("Waitlist run detail page message received");
          getData();
        };

        socket.onclose = () => {
          console.log('Disconnected from WebSocket');
        };

        socket.onerror = (error) => {
          console.error('WebSocket error:', error);
          // showMessage('WebSocket error. Please try again.', 'error');
        };

        return () => {
          socket.close();
        };
      } catch (error: any) {
        if (error.response?.status === 401) {
          showMessage('Session has expired', 'error');
        } else {
          console.error('Error connecting to WebSocket:', error);
          // showMessage('Please refresh the page.', 'error');
        }
      }
    };

    connectWebSocket();
  }, []);

  const getData = useCallback(async () => {
    await dispatch(fetchWaitlistRun({ waitlistRunId: waitlistRunId!, token: user?.token!, assistantId: assistantId! }) as any);
  }, [waitlistRunId, user?.token, assistantId, dispatch]);

  useEffect(() => {
    setLoading(true);
    getData();
    setLoading(false);
  }, []);

  const confirmedColor = "#53C43B"; // Green
  const errorColor = "#bc4a43"; // Red

  const renderIconBasedOnOutcome = (outcome: string) => {
    switch (outcome) {
      case WaitlistOutcomes.scheduled:
        return <CheckCircleIcon style={{ color: confirmedColor }} />;
      case WaitlistOutcomes.unscheduled:
        return <CloseIcon style={{ color: errorColor }} />;
      default:
        return null; // or any default icon
    }
  };

  const handleBack = () => {
    setWaitlistRun(undefined);
    navigate(`/assistants/${assistant?.assistantId}`); // Navigate back to the assistant history
  };

  // Function to handle opening/closing the assistant modal
  const toggleAssistantModal = () => {
    // register click in posthog
    posthog?.capture('assistant_modal_opened', { waitlist_run_id: waitlistRunId, assistant_id: assistant?.assistantId, source: 'waitlist_run_detail' });
    setAssistantModalOpen(!isAssistantModalOpen);
  };

  const navigateToPatientDetailView = (waitlistPatientId: string) => {
    navigate(`/assistants/${assistant?.assistantId}/runs/${waitlistRunId}/patient/${waitlistPatientId}`);
  };

  const onCloseClicked = (waitlistPatientId: string) => {
    if (!waitlistRunId) { return; };
    if (!waitlistPatientId) { return; }
    if (!user?.token) { return; }
    dispatch(deleteWaitlistPatientFromWaitlist({ waitlistRunId: waitlistRunId, waitlistPatientId: waitlistPatientId, token: user.token }) as any);

    posthog?.capture('[SECRETARY] Waitlist patient removed from waitlist after creation', { waitlist_run_id: waitlistRunId, assistant_id: assistant?.assistantId, waitlist_patient_id: waitlistPatientId, source: 'waitlist_run_detail' });
  };

  useEffect(() => {
    if (!waitlistRun || !waitlistRun.patients) { return; }
    if (waitlistRun.status !== WaitlistStatus.started) { return; }
  
    // make sure all patients that have given consent are contacted
    const allPatientsNotYetContacted = waitlistRun.patients.filter(
      (patient: WaitlistPatient) => (
        patient.status === WaitlistPatientStatus.notContacted &&
        patient.consentHistory.length > 0 &&
        patient.consentHistory[patient.consentHistory.length - 1].consent === Consent.given
      )
    );
  
    if (allPatientsNotYetContacted.length > 0) {
      const interval = setInterval(() => {
        getData();
      }, 2000);
  
      return () => clearInterval(interval);
    }
  
    return () => {};
  }, [waitlistRun]);

  const memoizedWaitlistRun = useMemo(() => {
    return waitlistRun;
  }, [waitlistRun]);

  return (
    <>
      {loading && <CircularProgress />}
      {!loading && waitlistRun && assistant && (
        <Box sx={{ display: 'flex', width: '100%' }}>
          {/* Main content section on the left */}
          <Box sx={{ flex: 1, paddingRight: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <IconButton onClick={handleBack} sx={{ mr: 2 }}>
                <ArrowBackIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography gutterBottom variant="h4" component="div" sx={{ my: 2 }}>
                {`Status: ${WaitlistStatusUi[waitlistRun?.status as keyof typeof WaitlistStatusUi] || waitlistRun.status}`}
              </Typography>
              <WaitlistActionAvatar>
                <Tooltip title="Refresh data">
                  <IconButton onClick={getData}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </WaitlistActionAvatar>
              { waitlistRun.status === WaitlistStatus.started && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <DropdownStatusSelector />
                </Box>
              )}
            </Box>
            <OvalOutlineListItem onClick={toggleAssistantModal} maxWidth="350px">
              <ListItemIcon>
                <Avatar src={assistant?.avatarUrl} alt={assistant?.name} sx={{ width: 30, height: 30 }} />
              </ListItemIcon>
              { assistant && 
                <ListItemText 
                  primary="Managed by" 
                  secondary={assistant ? `${assistant.name} (${AssistantTitles[assistant.title]})` : ''} 
                />
              }
            </OvalOutlineListItem>
            <ListItem>
              <ListItemIcon>
                <AccessTimeIcon />
              </ListItemIcon>
              <ListItemText primary="Created at" secondary={formatIsoToCustomDateStringWithEEEEHHMMA(waitlistRun.createdAt)} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FingerprintIcon />
              </ListItemIcon>
              { waitlistRun && <ListItemText primary="Waitlist Run ID" secondary={waitlistRun?.waitlistRunId} /> }
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText primary="Appointment date" secondary={formatIsoToCustomDateStringWithEEEEHHMMA(waitlistRun.appointmentDate)} />
            </ListItem>
            <ListItem>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <ListItemIcon>
                  {renderIconBasedOnOutcome(waitlistRun.outcome)}
                </ListItemIcon>
                <ListItemText primary="Waitlist Run Outcome" secondary={waitlistRun.outcome} />
              </Box>
            </ListItem>
            { loadingWaitlistRun && <LoadingWithSubtitle subtitle="Loading actions..." size={50} /> }
            {!loadingWaitlistRun && waitlistRun && (
              <CampaignActions
                waitlistRun={memoizedWaitlistRun!}
                onAction={getData}
              />
            )}
            
            {/* Assistant Information Modal */}
            <AssistantModal
              assistant={assistant}
              open={isAssistantModalOpen}
              onClose={toggleAssistantModal}
            />
          </Box>
      
          {/* Transcript and recording section on the right */}
          <Box
            sx={{
              flex: 1,
              paddingLeft: 2,
              maxWidth: '50%',
              alignSelf: 'flex-start' // Adjusts vertical alignment to be at the top of the flex container
            }}
            style={{
              marginTop: '64px' // Adjust this value to lower the right box as needed
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Box display="flex" alignItems="center">
                <Typography gutterBottom variant="h4" component="div" sx={{ my: 2 }}>
                  Patients
                </Typography>
                {loadingDeletePatient && <CircularProgress size={24} sx={{ ml: 2 }} />}
              </Box>
            </Box>
            <List>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', mb: 2 }}>
              { loadingWaitlistRun && <LoadingWithSubtitle subtitle="Loading patients..." size={50} /> }
                {
                  waitlistRun && patients && patients.map((patient: WaitlistPatient, index: number) => (
                    patient && (
                      <PatientCircle
                        key={patient.waitlistPatientId}
                        patient={patient}
                        waitlistRun={waitlistRun}
                        onClick={() => navigateToPatientDetailView(patient.waitlistPatientId)}
                        onCloseClicked={onCloseClicked}
                      />
                    )
                  ))
                }
              </Box>
            </List>
          </Box>
        </Box>
        )}
    </>
  );
};

export default WaitlistRunDetail;
