import React, { useState, useEffect } from 'react';
import { Modal, Button, Box, Typography, Table, TableHead, TableRow, TableCell, FormControl, InputLabel, Select, MenuItem, TextField, TableBody } from '@mui/material';
import { stringSimilarity } from 'string-similarity-js';
import styled from 'styled-components';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Colors } from '../../Colors';
import { convertToCallablePhoneNumber } from '../../utils';
import LoadingWithSubtitle from './LoadingWithSubtitle';


const CustomLoadingWithSubtitle = styled(LoadingWithSubtitle)`
  padding-top: 10px !important;
  margin-top: 10px !important;
`;

const UploadButton = styled(Button)`
  background-color: #2FCA8B;
  color: white;
  padding-bottom: 10px !important;
  &:hover {
    background-color: #2FCA8B;
  }
`;

const DetectedRowsNotification = styled.div`
  color: #2FCA8B;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
`;

const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

interface MatchColumnsModalProps {
  open: boolean;
  onClose: () => void;
  columns: { name: string, data: string[] }[];
  numRows: number;
  fullData: any[];
  onSuccess: (fullData: any[]) => void;
  loading: boolean;
}

const MatchColumnsModal: React.FC<MatchColumnsModalProps> = ({ open, onClose, columns, numRows, fullData, onSuccess, loading }) => {
  const columnTypes = ['Name', 'Phone Number', 'Priority', 'PT', 'Length', 'Dates', 'AM/PM'];

  const [columnMappings, setColumnMappings] = useState<any>([]);

  useEffect(() => {
    const mappings = columns.map((column: any) => {
      // Compute similarity for each column type and select the best match
      const scores = columnTypes.map(type => ({
        type,
        score: stringSimilarity(column.name, type)
      }));
      const bestMatch = scores.reduce((max, item) => max.score > item.score ? max : item, { type: '', score: 0 });
      return {
        name: column.name,
        type: bestMatch.type,  // Select the type with the highest score
        data: column.data
      };
    });
    setColumnMappings(mappings);
  }, [columns]);

  const handleTypeChange = (index: number, type: string) => {
    const newMappings = [...columnMappings];
    newMappings[index].type = type;
    setColumnMappings(newMappings);
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="match-columns-modal-title">
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        width: '80%',
        maxHeight: '90vh',
        overflowY: 'auto',
        borderRadius: 2,
      }}>
        <Typography id="match-columns-modal-title" variant="h6" component="h2" sx={{ mb: 3 }}>
          Match Columns
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Detected Columns</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Matched Column Type</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Samples</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {columnMappings.map((mapping: any, index: any) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">{mapping.name}</TableCell>
                <TableCell>
                  <FormControl fullWidth>
                    <InputLabel id={`select-label-${index}`}>Type</InputLabel>
                    <Select
                      labelId={`select-label-${index}`}
                      id={`select-${index}`}
                      value={mapping.type}
                      onChange={(e) => handleTypeChange(index, e.target.value)}
                      size="small"
                    >
                      {columnTypes.map((type, idx) => (
                        <MenuItem key={idx} value={type}>{type}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    value={mapping.data.join(', ')}
                    InputProps={{ readOnly: true }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/* Button Centering */}
        <Box sx={{
          display: 'flex',
          flexDirection: 'column', // Stacks children vertically
          alignItems: 'center',    // Centers children horizontally
          mt: 3                    // Margin top for spacing
        }}>
          <DetectedRowsNotification>
            <CheckCircleOutlineIcon />
            Detected {numRows} data rows
          </DetectedRowsNotification>
          <UploadButton
            onClick={() => onSuccess(fullData)} // Assuming onSuccess is your function
            variant="contained"
            endIcon={<CheckCircleOutlineIcon />}
            sx={{ width: '50%', mb: 2 }}
          >
            UPLOAD ALL
          </UploadButton>
        </Box>
          { loading && <CustomLoadingWithSubtitle subtitle="Uploading data..." size={40} />}
      </Box>
    </Modal>
  );
};

export default MatchColumnsModal;
