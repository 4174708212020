// WaitlistRunRow.tsx

import React, { useState } from 'react';
import { AssistantMetadata, AssistantTitles, FrontendUser, WaitlistActions, WaitlistOutcomes, WaitlistOutcomesUi, WaitlistRun, WaitlistStatus, WaitlistStatusUi } from '../../types';
import { DateTime } from 'luxon';
import { chooseColorBasedOnWaitlistRunOutcome, chooseColorBasedOnWaitlistRunStatus, formatIsoToCustomDateString, formatIsoToCustomDateStringWithEEEEHHMMA } from '../../utils';
import { AssistantAvatar, CallAction, CallActionContainer, CallInfo, CallInfoPrimary, CallInfoTitle, CallRowContainer, Spacer, StyledIconButton } from '../styles';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { AppDispatch, RootState } from '../../store';
import { useSnackbar } from '../../providers/SnackbarProvider';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { selectWaitlistRuns } from '../../slices/waitlistRunsSlice';
import { sessionExpired as sessionExpiredGlobally } from '../../slices/sessionSlice';

interface WaitlistRunRowProps {
  waitlistRun: WaitlistRun;
  assistant: AssistantMetadata;
  onDelete: () => void;
}

const WaitlistRunRow: React.FC<WaitlistRunRowProps> = ({ waitlistRun, assistant, onDelete }) => {
  const luxonParsedStartedAt = !!waitlistRun?.createdAt ? DateTime.fromISO(waitlistRun.createdAt).setZone('local').toFormat('cccc, LLLL d, HH:mm') : null;
  const waitlistRunOutcome: string = WaitlistOutcomesUi[waitlistRun?.outcome?.toLowerCase() as keyof typeof WaitlistOutcomesUi] || waitlistRun.outcome;
  const waitlistRunStatus: string = WaitlistStatusUi[waitlistRun?.status as keyof typeof WaitlistStatusUi] || waitlistRun.status;
  const waitlistOutcomeColor = chooseColorBasedOnWaitlistRunOutcome(waitlistRun?.outcome?.toLowerCase());
  const waitlistStatusColor = chooseColorBasedOnWaitlistRunStatus(waitlistRun?.status?.toLowerCase());
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const { showMessage } = useSnackbar();
  const dispatch = useDispatch<AppDispatch>();
  const waitlistRuns = useSelector(selectWaitlistRuns);

  const navigate = useNavigate();

  const onClick = (waitlistRun: WaitlistRun) => {
    navigate(`/assistants/${assistant?.assistantId}/runs/${waitlistRun.waitlistRunId}`);
  };

  

  return (
    <CallRowContainer onClick={() => onClick(waitlistRun)}>
      <AssistantAvatar src={assistant.avatarUrl} />
      <CallInfo>
        <CallInfoPrimary>{`Appointment slot: ${formatIsoToCustomDateStringWithEEEEHHMMA(waitlistRun.appointmentDate)}`}</CallInfoPrimary>
        <CallInfoTitle>Calling by: {assistant.name} ({AssistantTitles[assistant.title]}) on {luxonParsedStartedAt}</CallInfoTitle>
        <CallInfoTitle>Patient count: {waitlistRun.patients.length}</CallInfoTitle>
      </CallInfo>
      <Spacer />
      <CallActionContainer>
        { waitlistRun.status === WaitlistStatus.completed && (
          <CallAction color={waitlistOutcomeColor}>
            <span>Outcome: {waitlistRunOutcome || "unknown"}</span>
          </CallAction>
          )
        }
        <CallAction color={waitlistStatusColor}>
          <span>Status: {waitlistRunStatus || "unknown"}</span>
        </CallAction>
        <StyledIconButton
          aria-label="delete run"
          onClick={(event: any) => {
            event.stopPropagation(); // This stops the event from bubbling up to parent elements
            onDelete();
          }}
        >
          <DeleteIcon />
        </StyledIconButton>
      </CallActionContainer>
    </CallRowContainer>
  );
};

export default WaitlistRunRow;
