import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AppDispatch, RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { AssistantMetadata, FrontendUser } from '../types';
import CircularProgress from '@mui/material/CircularProgress';
import AssistantCard from '../components/cards/AssistantCard';
import NoAssistantsCard from '../components/NoAssistantsCard';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { usePostHog } from 'posthog-js/react';
import { useSnackbar } from '../providers/SnackbarProvider';
import { fetchAssistants, selectAssistants } from '../slices/assistantsSlice';
import { useNavigate } from 'react-router-dom';
import { HeaderContainer, RefreshButton, Title } from '../components/styles';
import { sessionExpired } from '../slices/sessionSlice';

const Assistants: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { showMessage } = useSnackbar();
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const assistants = useSelector(selectAssistants);
  const globalAssistantsLoading = useSelector((state: RootState) => state.assistants.loading);
  const [loading, setLoading] = useState(false);
  const posthog = usePostHog();
  const navigate = useNavigate();

  const fetchGlobalAssistants = async () => {
    setLoading(true);
    if (user && user.id && user.token) {
      try {
        await dispatch(fetchAssistants({ userId: user.id, token: user.token }) as any);
      } catch (error: any) {
        if (error.response?.status === 401) {
          showMessage('Session has expired', 'error');
          dispatch(sessionExpired(true) as any);
        } else {
          // showMessage('Failed to fetch calls. Please try again later.', 'error');
          console.error('Failed to fetch calls:', error.message);
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    try {
      if (assistants.length === 0 && user?.id && user?.token) {
        dispatch(fetchAssistants({ userId: user.id, token: user.token }) as any);
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        dispatch(sessionExpired(true) as any);
      } else {
        // showMessage('Failed to fetch calls. Please try again later.', 'error');
        console.error('Failed to fetch calls:', error.message);
      }
    }
  }, [dispatch, user, assistants.length]);

  const handleSelectAssistant = (assistantId: string) => {
    navigate(`/assistants/${assistantId}`);
  };

  return (
    <>
      <div>
      <HeaderContainer>
        <Title>Assistants</Title>
        <RefreshButton onClick={() => {
          fetchGlobalAssistants();
        }} aria-label="refresh">
            <RefreshIcon />
        </RefreshButton>
      </HeaderContainer>
        {/* {globalAssistantsLoading && <CircularProgress />} Show the loading indicator */}
        {loading && <CircularProgress />} {/* Show the loading indicator */}
        <div>
        {!!!assistants || (!!assistants && assistants.length === 0) && <NoAssistantsCard />}
        {!!assistants && assistants.length > 0 && assistants.map((assistant: AssistantMetadata, index: number) => (
          <AssistantCard
            key={assistant.assistantId}
            assistant={assistant}
            onClick={() => handleSelectAssistant(assistant?.assistantId!)}
            onRefresh={() => dispatch(fetchAssistants({ userId: user?.id!, token: user?.token! }) as any)}
          />
        ))}
        {!loading && !!!assistants && (
            <NoAssistantsCard />
          )
        }
        </div>
    </div>
    </>
  );
};

export default Assistants;
