// src/pages/SignOut.tsx

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signOut } from '../auth/authSlice';
import { AppDispatch, RootState, persistor } from '../store';
import { CircularProgress } from '@mui/material';

const SignOut: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    dispatch(signOut() as any); // Dispatch the signOut action from your auth store
    navigate('/login', { replace: true }); // Redirect to login page after sign out
    persistor.purge();
  }, [dispatch, navigate]);

  return (
    <div style={{ padding: '20px' }}>
      <CircularProgress />
    </div>
  );
};

export default SignOut;
