import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { AssistantMetadata, AssistantPurposesOneLiners, FrontendUser } from '../../types';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { useSnackbar } from "../../providers/SnackbarProvider";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { sessionExpired } from "../../slices/sessionSlice";
import { DateTime } from 'luxon';
import { DateTimePicker, PickerChangeHandlerContext } from '@mui/x-date-pickers';
import { usePostHog } from 'posthog-js/react';

interface StyledAssistantCardProps {
  isActive: boolean;
}

const StyledFormControl = styled(FormControl)`
  .MuiInputLabel-root {
    // Adjust these values as needed to center the label
    top: -8px;
    /* left: 12px; */
    /* position: absolute; */
    /* transform: none; */
  }

  .MuiSelect-select {
    padding-top: 8px;
    padding-bottom: 8px;
    // Optionally, adjust padding to ensure the dropdown arrow is positioned correctly
  }
`;

const SelectContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  width: 90%; // Make the container take up 90% of card width
  margin-bottom: 16px; // Adjust margin to give space above PhoneNumberInput and TestButton
  padding-top: 15px;
`;

// Adjusting the StyledAssistantCard to accommodate the select boxes above the card's bottom edge
const StyledAssistantCard = styled.div<StyledAssistantCardProps>`
  position: relative;
  width: 350px;
  border-radius: 20px;
  padding: 16px;
  text-align: center;
  background: #f0f0f0;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  cursor: pointer;
  padding-bottom: 60px; // Increase padding-bottom to prevent overlap with select boxes

  h2, p {
    margin: 0;
  }

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 1rem;
  }

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }
`;

const StatusTypography = styled(Typography)`
  position: absolute;
  bottom: 5px; // Adjust as needed
  width: 100%;
  text-align: center;
`;

const VoiceSelectBox = styled(Box)`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 120px;
`;

const PhoneNumberInput = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  font-size: 16px;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: dodgerblue;
    box-shadow: 0 0 5px rgba(30, 144, 255, 0.5);
  }
`;

const TestButton = styled.button`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: none;
  background-color: blue;
  color: white;
  cursor: pointer;
`;

const StyledInput = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  width: 100%;
  font-size: 16px;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: dodgerblue;
    box-shadow: 0 0 5px rgba(30, 144, 255, 0.5);
  }
`;

const StyledDateTimePicker = styled(DateTimePicker)`
  width: 100% !important;
  margin-top: 5px !important;
  margin-bottom: 15px !important;
  padding-top: 10px !important;
`;

interface AssistantCardProps {
  assistant: AssistantMetadata;
  onClick: () => void;
  onRefresh: (fromButtonClick: boolean) => void;
}

const variableNames: Record<string, string> = {
  patientFirstName: "Patient first name",
  patientLastName: "Patient last name",
  organizationName: "Organization name",
  pcpName: "Primary care provider name",
  callbackNumber: "Callback number",
  visitType: "Visit type",
  patientEmail: "Patient email",
};

const AssistantCard: React.FC<AssistantCardProps> = ({ assistant, onClick, onRefresh }) => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const [phoneNumber, setPhoneNumber] = useState('');
  const { showMessage } = useSnackbar();
  const dispatch = useDispatch<AppDispatch>();
  const posthog = usePostHog();

  const [appointmentDate, setAppointmentDate] = useState<DateTime | null>(DateTime.now().plus({ hours: 1 }));
  const [appointmentDateAlternateOne, setAppointmentDateAlternateOne] = useState<DateTime | null>(DateTime.now().plus({ hours: 2 }));
  const [appointmentDateAlternateTwo, setAppointmentDateAlternateTwo] = useState<DateTime | null>(DateTime.now().plus({ days: 1 }));

  const [inputs, setInputs] = useState<any>({});

  useEffect(() => {
    const fromButtonClick = false;
    onRefresh(fromButtonClick);
  }, [assistant.isActive]);

  const handleInputChange = (variableName: string, value: string) => {
    setInputs((prevState: any) => ({
      ...prevState,
      [variableName]: value
    }));
  };

  const inputComponents = assistant?.promptVariables?.map((variable: string) => {
    if (variable === 'appointmentDate') {
      return (
        <>
          <br />
          <StyledDateTimePicker
            key={variable}
            value={appointmentDate}
            onChange={(newDate: unknown, context: PickerChangeHandlerContext<any>) => setAppointmentDate(newDate as DateTime | null)}
            label="Appointment date"
          />
          <br />
      </>
      );
    } else if (variable === 'appointmentDateAlternateOne') {
      return (
        <>
          <StyledDateTimePicker
            key={variable}
            value={appointmentDateAlternateOne}
            onChange={(newDate: unknown, context: PickerChangeHandlerContext<any>) => setAppointmentDateAlternateOne(newDate as DateTime | null)}
            label="Alternate one"
          />
        </>
      );
    } else if (variable === 'appointmentDateAlternateTwo') {
      return (
        <>
          <StyledDateTimePicker
            key={variable}
            value={appointmentDateAlternateTwo}
            onChange={(newDate: unknown, context: PickerChangeHandlerContext<any>) => setAppointmentDateAlternateTwo(newDate as DateTime | null)}
            label="Alternate two"
          />
        </>
      );
    } else {
      return (
        <StyledInput
          key={variable}
          type="text"
          placeholder={variableNames[variable] || variable}
          value={inputs[variable] || ''}
          onChange={(e: any) => handleInputChange(variable, e.target.value)}
        />
      );
    }
  });

  // Sort the input components array based on sorting criteria
  inputComponents.sort((a: any, b: any) => {
    // Sort by patient first name first
    if (a.key === 'patientFirstName') return -1;
    if (b.key === 'patientFirstName') return 1;
    
    // Sort by patient last name second
    if (a.key === 'patientLastName') return -1;
    if (b.key === 'patientLastName') return 1;

    if (a.key === 'patientEmail') return -1;
    if (b.key === 'patientEmail') return 1;
    
    // Sort by appointment date third
    if (a.sortOrder < b.sortOrder) return -1;
    if (a.sortOrder > b.sortOrder) return 1;

    if (a.key === 'organizationName') return -1;
    if (b.key === 'organizationName') return 1;

    // Sort by PCP name fourth
    if (a.key === 'pcpName') return -1;
    if (b.key === 'pcpName') return 1;

    // Sort by callback number fifth
    if (a.key === 'callbackNumber') return -1;
    if (b.key === 'callbackNumber') return 1;

    // Sort by visit type last
    if (a.key === 'visitType') return -1;
    if (b.key === 'visitType') return 1;

    if (a.key === 'appointmentDate') return -1;
    if (b.key === 'appointmentDate') return 1;

    if (a.key === 'appointmentDateAlternateOne') return -1;
    if (b.key === 'appointmentDateAlternateOne') return 1;

    if (a.key === 'appointmentDateAlternateTwo') return -1;
    if (b.key === 'appointmentDateAlternateTwo') return 1;

    return 0;
  });

  const handlePhoneNumberChange = (event: any) => {
    const { value } = event.target;
    // Regex to validate phone number format
    setPhoneNumber(value);
  };

  const testAssistant = async () => {
    try {
      const assistantId = assistant?.assistantId;

      if (!assistantId) {
        console.error("No assistant ID found");
        showMessage('No assistant ID found. Please refresh the page', 'error');
        return;
      }

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/${assistantId}/test`, 
      {
        userId: user?.id,
        patientPhoneNumber: phoneNumber,
        appointmentDate: appointmentDate?.toISO(),
        appointmentDateAlternateOne: appointmentDateAlternateOne?.toISO(),
        appointmentDateAlternateTwo: appointmentDateAlternateTwo?.toISO(),
        ...inputs
      },
      {
        headers: {
          'Authorization': `Bearer ${user?.token}`,
        }
      });

      if (response.status === 200) {
        showMessage(`Scheduling call to ${phoneNumber}`, 'success');
        posthog?.capture(`Scheduled test call to ${phoneNumber}`);
      } else {
        // showMessage('Failed to schedule call.', 'error');
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        showMessage('Session has expired', 'error');
        dispatch(sessionExpired(true) as any);
      } else {
        // showMessage('Failed to schedule call', 'error');
        console.error('Failed to schedule call', error.message);
        posthog?.capture(`Error: Failed to schedule call to ${phoneNumber} with error: ${JSON.stringify(error)}`);
      }
    }
  };

  const handleTestButtonClick = () => {
    const phoneNumberRegex = /^\+1\d{10}$/;
    if (phoneNumberRegex.test(phoneNumber)) { // make sure the country code is there
      testAssistant();
    } else {
      showMessage('Phone number needs to be in the format +1xxxxxxxxxx', 'error');
    }
  };

  const assistantPurpose: string = AssistantPurposesOneLiners[assistant.purpose as keyof typeof AssistantPurposesOneLiners];

  return (
    <>
      <StyledAssistantCard isActive={assistant.isActive}>
        <div onClick={onClick}>
          <img src={assistant.avatarUrl} alt={assistant.name} />
          <h2>{assistant.name}</h2>
          <p>{assistantPurpose}</p>
        </div>
        {inputComponents}
        <PhoneNumberInput
          type="tel"
          placeholder="Phone number (+1xxxxxxxxxx)"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
        />
        <TestButton onClick={handleTestButtonClick}>Test</TestButton>
      </StyledAssistantCard>
    </>
  );
};

export default AssistantCard;
