import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AppDispatch, RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { AssistantMetadata, FrontendCall, FrontendUser } from '../types';
import CallRow from '../components/row/CallRow';
import CallDetail from '../components/CallDetail';
import CircularProgress from '@mui/material/CircularProgress';
import NoCallsCard from '../components/NoCallsCard';
import RefreshIcon from '@mui/icons-material/Refresh';
import { usePostHog } from 'posthog-js/react';
import { useSnackbar } from '../providers/SnackbarProvider';
import { HeaderContainer, Title, RefreshButton } from '../components/styles';
import { selectCalls, clearCalls, nextCallsPaginated, refreshCallsPaginated } from '../slices/callsSlice';
import { useNavigate } from 'react-router-dom';
import { sessionExpired } from '../slices/sessionSlice';
import { selectAssistants } from '../slices/assistantsSlice';
import { DateTime } from 'luxon';
import { Box, Button, Modal, Typography } from '@mui/material';

const getAssistantById = (assistantId: string, assistants: AssistantMetadata[]): any => {
  const assistant: AssistantMetadata | undefined = assistants.find((assistant: AssistantMetadata) => assistant.assistantId === assistantId);
  if (assistant === undefined) {
    return undefined;
  }

  return assistant;
};

const Calls: React.FC = () => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const posthog = usePostHog();
  const dispatch = useDispatch<AppDispatch>();
  const assistants = useSelector(selectAssistants);
  const calls: FrontendCall[] | null = useSelector((state: RootState) => state.calls.calls);
  const globalCallsLoading = useSelector((state: RootState) => state.calls.loading);
  const [selectedCall, setSelectedCall] = useState<FrontendCall | null>(null);
  const [showNoCallsMessage, setShowNoCallsMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const { showMessage } = useSnackbar();
  const navigate = useNavigate();
  const lastEvaluatedKey: any = useSelector((state: RootState) => state.calls.lastEvaluatedKey);
  const finalPage: boolean = useSelector((state: RootState) => state.calls.finalPage);

  const [assistantsFilter, setAssistantsFilter] = React.useState<string[] | undefined>(assistants.map((a) => a.assistantId));
  const [index, setIndex] = React.useState<number>(0);

  const pageSize = 8;

  useEffect(() => {
    if (selectedCall) {
      posthog?.capture('clicked_assistant_call', { call_id: selectedCall.callId, assistant_id: selectedCall.assistantId, call_outcome: selectedCall.outcome, source: 'calls_tab' });
      navigate(`/calls/${selectedCall.callId}`);
    }
  }, [selectedCall]);

  const getCallsPaginatedInitial = async (assistants?: string[]) => {
    if (!user || !user?.token) {
      clearCalls();
      setIndex(0);
      return;
    }
    await dispatch(refreshCallsPaginated({ token: user.token, assistantIds: assistants }) as any);
    setIndex(0);
  };

  const refreshCalls = async () => {
    if (!user || !user?.token) {
      return;
    }
    await dispatch(refreshCallsPaginated({ token: user.token, assistantIds: assistantsFilter }) as any);
    setIndex(0);
  };

  useEffect(() => {
    getCallsPaginatedInitial(assistantsFilter);
  }, [user?.token]);

  const endOfList = calls ? calls.length <= (index + 1) * pageSize : false;
  const pageCalls = calls ? calls.slice(index * pageSize, (index + 1) * pageSize) : [];

  const getCallsPaginated = async () => {
    if (!user || !user?.token) {
      clearCalls();
      setIndex(0);
      return;
    } else {
      await dispatch(nextCallsPaginated({ token: user?.token, lastEvaluatedKey, assistantIds: assistantsFilter }) as any);
      setIndex(index + 1);
    }
  };

  const prevPage = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  const nextPage = () => {
    // check to see if we already have the calls loaded
    if (calls && calls.length > (index + 1) * pageSize) {
      setIndex(index + 1);
    } else if (!finalPage) {
      getCallsPaginated();
    }
  };

  return (
    <>
      <HeaderContainer>
        <Title>Calls</Title>
        <RefreshButton onClick={() => {
          refreshCalls();
        }} aria-label="refresh">
            <RefreshIcon />
        </RefreshButton>
      </HeaderContainer>
      <div>
        {globalCallsLoading && <CircularProgress />}
        {!!!calls || (!!calls && calls.length === 0) && <NoCallsCard />}
        {pageCalls.map(call => {
          const assistant = getAssistantById(call.assistantId, assistants);
          return (
            <div key={call.callId}>
              {assistant ? (
                <CallRow
                  call={call}
                  assistant={assistant}
                  onClick={() => setSelectedCall(call)}
                />
              ) : (
                <Box>
                <Typography variant="h6" color="textSecondary">
                  This call is unavailable
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  This call was made by an assistant that is no longer a part of this organization
                </Typography>
              </Box>
              )}
            </div>
          );
        })}
        <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          <Button onClick={() => {
            posthog?.capture('[SECRETARY] clicked_refresh_calls', { source: 'calls_tab' });
            refreshCalls();
          }}
          >Refresh</Button>
          <Button onClick={() => {
            posthog?.capture('[SECRETARY] clicked_prev_page_calls', { source: 'calls_tab' });
            prevPage();
          }} disabled={index === 0}>
            Prev
          </Button>
          <Button onClick={() => {
            posthog?.capture('[SECRETARY] clicked_next_page_calls', { source: 'calls_tab' });
            nextPage();
          }}
          disabled={pageCalls.length === 0}>
            Next
          </Button>
        </div>
      </div>
    </>
  );
};

export default Calls;
