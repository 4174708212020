// src/components/Home.tsx
import React, { useState, useCallback, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupsIcon from '@mui/icons-material/Groups';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from '../auth/authSlice';
import { AppDispatch, RootState, persistor } from '../store';
import LogoutIcon from '@mui/icons-material/Logout';
import Settings from './Settings';
import Calls from './Calls';
import { Avatar } from '@mui/material';
// get somnLogo.png from the public folder
import logoImg from '../assets/somnLogo.svg';
import Assistants from './Assistants';
import { startTimeout, resetTimeout } from '../components/timeoutUtils';
import { usePostHog } from 'posthog-js/react';
import { FrontendUser, Tab, TabName } from '../types';
import styled from 'styled-components';
import { useSnackbar } from '../providers/SnackbarProvider';
import { Link, Outlet } from 'react-router-dom';
import { selectCalls } from '../slices/callsSlice';
import { fetchAssistants, selectAssistants } from '../slices/assistantsSlice';
import { manageSessionTimeout, clearSessionTimeout } from '../slices/sessionSlice';
import { sessionExpired as sessionExpiredGlobally } from '../slices/sessionSlice';
import { fetchWaitlistRuns } from '../slices/waitlistRunsSlice';
import { fetchOrganization as fetchOrganizationAction } from '../slices/organizationSlice';
import { ModalBackdrop, ModalButton, ModalContainer, TwoChoiceModalProps, TwoChoiceModal, OneChoiceModal } from '../components/styles';

const drawerWidth = 240;

const Home: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const posthog = usePostHog();
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const calls = useSelector(selectCalls);
  const assistants = useSelector(selectAssistants);
  const sessionExpired = useSelector((state: RootState) => state.session.sessionExpired);
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);
  const { showMessage } = useSnackbar();

  useEffect(() => {
    if (sessionExpired) {
      setShowSessionExpiredModal(true);
    }
  }, [sessionExpired]);
  
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null); // Using NodeJS.Timeout for Node.js environment. Use `number` for browser environment.

  useEffect(() => {
    const handleUserActivity = () => {
      dispatch(manageSessionTimeout() as any);
    };

    // Add event listeners for user activity
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    // Initiate the session timeout logic
    dispatch(manageSessionTimeout() as any);

    return () => {
      // Cleanup on component unmount
      dispatch(clearSessionTimeout() as any);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, [dispatch]);

  // identify user to posthog
  useEffect(() => {
    if (user) {
      posthog?.identify(user.id, {
        email: user.email,
      });
    }
  }, [posthog, user?.id, user?.email]);

  const [selectedTab, setSelectedTab] = useState<TabName | ''>(TabName.Calls);

  const handleSignOutClick = () => {
    // Directly show the logout modal without relying on `selectedTab`
    setShowLogoutModal(true);
  };

  const fetchGlobalWaitlistRuns = async () => {
    if (user && user.id && user.token) {
      try {
        await dispatch(fetchWaitlistRuns({ userId: user.id, token: user.token, assistantId: assistants?.[0].assistantId }) as any);
      } catch (error: any) {
        if (error.response?.status === 401) {
          showMessage('Session has expired', 'error');
          dispatch(sessionExpiredGlobally(true) as any);
        }
        console.error('Failed to fetch calls:', error.message);
      }
    }
  };

  const fetchOrganization = async () => {
    if (user && user.id && user.token) {
      try {
        await dispatch(fetchOrganizationAction({ userId: user.id, token: user.token }) as any);
      } catch (error: any) {
        if (error.response?.status === 401) {
          showMessage('Session has expired', 'error');
          dispatch(sessionExpiredGlobally(true) as any);
        }
        console.error('Failed to fetch calls:', error.message);
      }
    }
  };

  useEffect(() => {
    fetchGlobalCalls();
    fetchGlobalAssistants();
    fetchGlobalWaitlistRuns();
    fetchOrganization();
  }, [dispatch, user, calls?.length, assistants?.length]);

  const fetchGlobalCalls = async () => {
    if (user && user.id && user.token) {
      try {
        // await dispatch(fetchCalls({ userId: user.id, token: user.token }));
      } catch (error: any) {
        if (error.response?.status === 401) {
          showMessage('Session has expired', 'error');
          dispatch(sessionExpiredGlobally(true) as any);
        }
        console.error('Failed to fetch calls:', error.message);
      }
    }
  };

  const fetchGlobalAssistants = async () => {
    if (user && user.id && user.token) {
      try {
        await dispatch(fetchAssistants({ userId: user.id, token: user.token }) as any);
      } catch (error: any) {
        if (error.response?.status === 401) {
          showMessage('Session has expired', 'error');
          dispatch(sessionExpiredGlobally(true) as any);
        }
        console.error('Failed to fetch calls:', error.message);
      }
    }
  };

  const handleTabChange = (tab: TabName) => {
    posthog?.capture(`clicked_tab_${tab.toLowerCase()}`);
    if (sessionExpired) return;
    if (tab === TabName.SignOut) {
      handleSignOutClick();
    } else {
      setSelectedTab(tab);
    }
  };

  const handleSignOut = () => {
    persistor.purge(); // Clear the Redux store
    dispatch(signOut() as any);
    setShowSessionExpiredModal(false); // Close the modal on sign out
  };

  useEffect(() => {
    // Show the logout modal only when the SignOut tab is selected
    if (selectedTab === TabName.SignOut) {
      setShowLogoutModal(true);
    }
  }, [selectedTab]);

  const mainTabs: { name: TabName, icon: any, route: string }[] = [
    { name: TabName.Calls, icon: <CallIcon />, route: '/calls' },
    { name: TabName.Assistants, icon: <SmartToyIcon />, route: '/assistants' },
  ];

  const bottomTabs: { name: TabName, icon: any, route: string }[] = [
    { name: TabName.Settings, icon: <SettingsIcon />, route: '/settings'},
  ];

  return (
    <>
    <TwoChoiceModal
      isOpen={showLogoutModal}
      onCancel={() => setShowLogoutModal(false)}
      onConfirm={() => {
        handleSignOut();
        setShowLogoutModal(false); // Close the modal upon confirmation
      }}
      message="Are you sure you want to log out?"
      actionMessageOne='Cancel'
      actionMessageTwo='Log Out'
    />
    <OneChoiceModal
      isOpen={showSessionExpiredModal}
      onConfirm={handleSignOut}
      message="Your session has expired. Please log in again."
      actionMessage='Log Out'
    />
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
        >
          <Toolbar>
            <Typography variant="h6" noWrap component="div">
              Dashboard
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="permanent"
          anchor="left"
        >
          {/* <Toolbar /> */}
          <Box
            sx={{
              height: '64px', // Adjust the height as needed
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              // This places the logo at the center of the box, remove if you want it to the left
              // paddingLeft: '16px', // This adds some padding on the left side
              // Remove paddingLeft if you want the logo flush with the edge
            }}
          >
      <img
        src={logoImg} // Your imported logo image
        alt="Logo"
        style={{
          height: '40px', // Adjust the size as needed
          width: 'auto', // Keep the aspect ratio
        }}
      />
    </Box>
          <Divider />
            <Box sx={{ overflow: 'auto' }}>
              <List>
              {mainTabs.map((tab: Tab) => (
                <ListItem key={tab.name} disablePadding>
                  <ListItemButton
                    component={Link}
                    to={sessionExpired ? "/sign-out": tab.route}
                    sx={{
                      color: '#6c757d', // Or use your theme's color code
                      textDecoration: 'none', // This will remove the underline from the link
                      '&:hover, &:focus': {
                        color: '#6c757d', // Color for when the item is hovered over or focused
                        textDecoration: 'none', // Ensuring the underline is removed on hover/focus
                      }
                    }}
                    onClick={() => handleTabChange(tab.name)}
                  >
                    <ListItemIcon sx={{ color: 'grey' /* or your theme's default color for icons */ }}>
                      {tab.icon}
                    </ListItemIcon>
                    <ListItemText primary={tab.name} />
                  </ListItemButton>
                </ListItem>
              ))}
              </List>
            </Box>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Divider />
          <Box>
            <List>
            {bottomTabs.map((tab: Tab) => (
              <ListItem key={tab.name} disablePadding>
                <ListItemButton
                  component={tab.route ? Link : 'button'}
                  to={sessionExpired ? "/sign-out": tab.route}
                  sx={{
                    color: '#6c757d', // Set the text color to grey (or any other color you prefer)
                    textDecoration: 'none', // Removes the underline from the link
                    '&:hover, &:focus': {
                      color: '#6c757d', // Maintain the text color on hover or focus
                      textDecoration: 'none', // Ensures no underline on hover or focus
                      backgroundColor: 'transparent', // Optional: removes the background color on hover/focus if desired
                    },
                  }}
                  onClick={() => handleTabChange(tab.name)}
                >
                  <ListItemIcon
                    sx={{
                      color: 'grey', // Maintain the original icon color (adjust as needed)
                      // If the icons still turn blue, you might need '!important' here too
                    }}
                  >
                    {tab.icon}
                  </ListItemIcon>
                  <ListItemText primary={tab.name} />
                </ListItemButton>
              </ListItem>
            ))}
            <ListItem key={TabName.SignOut} disablePadding>
                <ListItemButton
                  component={'button'}
                  sx={{
                    color: '#6c757d', // Set the text color to grey (or any other color you prefer)
                    textDecoration: 'none', // Removes the underline from the link
                    '&:hover, &:focus': {
                      color: '#6c757d', // Maintain the text color on hover or focus
                      textDecoration: 'none', // Ensures no underline on hover or focus
                      backgroundColor: 'transparent', // Optional: removes the background color on hover/focus if desired
                    },
                  }}
                  onClick={() => handleTabChange(TabName.SignOut)}
                >
                  <ListItemIcon
                    sx={{
                      color: 'grey', // Maintain the original icon color (adjust as needed)
                      // If the icons still turn blue, you might need '!important' here too
                    }}
                  >
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary={TabName.SignOut} />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Drawer>
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
        >
          <Toolbar />
           <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default Home;
