import React from 'react';
import { Box, Typography } from '@mui/material';
import { Colors } from '../../../Colors';
import { ModalBackdrop, ModalButton, ModalContainer, TwoChoiceModalProps, GiantPhoneIcon } from '../../styles';

const CallConfirmationModal: React.FC<TwoChoiceModalProps> = ({
  isOpen,
  onCancel,
  onConfirm,
  message,
  actionMessageOne,
  actionMessageTwo,
}) => {
  if (!isOpen) return null;

  return (
    <ModalBackdrop>
      <ModalContainer>
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          <GiantPhoneIcon />
          <Typography variant="h4" component="div" sx={{ mb: 2, textAlign: 'center', fontWeight: 'bold' }}>
            Call Confirmation
          </Typography>
          <p>{message}</p>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <ModalButton color={Colors.grey2} onClick={onCancel}>{actionMessageOne}</ModalButton>
          <ModalButton color={Colors.confirmedColor1} onClick={onConfirm}>{actionMessageTwo}</ModalButton>
        </Box>
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default CallConfirmationModal;
