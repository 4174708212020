// src/App.tsx

import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './auth/ProtectedRoute';
import Login from './pages/Login';
import Home from './pages/Home';
import './App.css';
import { AuthProvider } from './auth/AuthContext';
import SignUp from './pages/Signup';
import ConfirmPage from './pages/ConfirmPage';
import CalendlyIntegration from './pages/CalendlyIntegration';
import PasswordRecovery from './pages/PasswordRecovery';
import Calls from './pages/Calls';
import CallDetail from './components/CallDetail';
import Settings from './pages/Settings';
import Assistants from './pages/Assistants';
import AssistantDetail from './detailPages/AssistantDetail';
import SignOut from './pages/SignOut';
import WaitlistRunDetail from './detailPages/WaitlistRunDetail';
import { AssistantView, FrontendUser } from './types';
import WaitlistPatientDetail from './detailPages/WaitlistPatientDetail';
import { usePostHog } from 'posthog-js/react';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import { useDispatch } from 'react-redux';
import { fetchOrganization } from './slices/organizationSlice';
import { fetchArchivedTakenWaitlistAppointments } from './slices/waitlistRunsSlice';
import { useEffect } from 'react';
import { WebsocketEvents } from './utils';

function App() {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const posthog = usePostHog();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user?.id) { return };
    if (!user?.token) { return };

    const connectWebSocket = async () => {
      try {
        console.log(`wss://${process.env.REACT_APP_BACKEND_URL_NO_HTTPS}/api/subscribe/${user.id}`);
        const socket = new WebSocket(`wss://${process.env.REACT_APP_BACKEND_URL_NO_HTTPS}/api/subscribe/${user.id}?token=${encodeURIComponent(user.token!)}`);

        socket.onopen = () => {
          console.log('Connected to WebSocket');
          socket.send(JSON.stringify({ action: 'subscribe', type: WebsocketEvents.DeletedTakenWaitlistAppointments }));
        };

        socket.onmessage = (event: any) => {
          fetchOrganizationData();
        };

        socket.onclose = () => {
          console.log('Disconnected from WebSocket');
        };

        socket.onerror = (error) => {
          console.error('WebSocket error:', error);
          // showMessage('WebSocket error. Please try again.', 'error');
        };

        return () => {
          socket.close();
        };
      } catch (error: any) {
        console.error('Failed to connect to WebSocket:', error.message);
      }
    };

    connectWebSocket();
  }, []);

  const fetchOrganizationData = async () => {
    if (!user || !user?.token || !user?.id) return;
    await dispatch(fetchOrganization({ userId: user?.id, token: user?.token }) as any);
    await dispatch(fetchArchivedTakenWaitlistAppointments({ token: user?.token }) as any);
  };

  useEffect(() => {
    fetchOrganizationData();
  }, [user?.id, user?.token]);

  // identify user in posthog
  useEffect(() => {
    if (!user || !user?.id) return;
    posthog?.identify(user.id);
    posthog?.people.set({ email: user.email });
    posthog?.capture("[SECRETARY] Logged in", { email: user.email });
  }, [user]);
  
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<ProtectedRoute element={<Home />} />} >
        <Route index element={<Navigate to="/calls" replace />} />
        <Route path="calls" element={<Calls />} />
        <Route path="calls/:callId" element={<CallDetail />} />
        <Route path="settings" element={<Settings />} />
        <Route path="assistants" element={<Assistants />} />
        <Route path="assistants/:assistantId" element={<AssistantDetail tab={AssistantView.history}/>} />
        <Route path="assistants/:assistantId/runs" element={<AssistantDetail tab={AssistantView.runs}/>} />
        <Route path="assistants/:assistantId/queue" element={<AssistantDetail tab={AssistantView.queue}/>} />
        <Route path="assistants/:assistantId/history" element={<AssistantDetail tab={AssistantView.history}/>} />
        <Route path="assistants/:assistantId/fax" element={<AssistantDetail tab={AssistantView.fax}/>} />
        <Route path="assistants/:assistantId/waitlist" element={<AssistantDetail tab={AssistantView.waitlist}/>} />
        <Route path="assistants/:assistantId/runs/:waitlistRunId" element={<WaitlistRunDetail />} />
        <Route path="assistants/:assistantId/runs/:waitlistRunId/patient/:waitlistPatientId" element={<WaitlistPatientDetail />} />
        <Route path="sign-out" element={<SignOut />} />
      </Route>
      <Route path="/signup" element={<SignUp />} />
      <Route path="/confirm" element={<ConfirmPage />} />
      <Route path="/password-recovery" element={<PasswordRecovery />} />
      <Route path="/calendly/auth" element={<CalendlyIntegration />} />
    </Routes>
  );
}

export default App;
