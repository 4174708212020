import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const PlayerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Button = styled.button`
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const Slider = styled.input`
  width: 300px;
  height: 25px;
  cursor: pointer;
`;

interface AudioScrubberProps {
  audioBuffer: AudioBuffer;
  audioContext: AudioContext;
}

const AudioScrubber: React.FC<AudioScrubberProps> = ({ audioBuffer, audioContext }) => {
  const [playbackTime, setPlaybackTime] = useState<number>(0);
  const sourceRef = useRef<AudioBufferSourceNode | null>(null);
  const startTimeRef = useRef<number | null>(null);  // Ref to store the start time
  const animationRef = useRef<number | null>(null);

  const updatePlaybackTime = () => {
    if (audioContext && sourceRef.current && !audioContext.state.includes('suspended') && startTimeRef.current !== null) {
      const currentTime = audioContext.currentTime - startTimeRef.current;
      setPlaybackTime(currentTime);
      animationRef.current = requestAnimationFrame(updatePlaybackTime);
    }
  };

  const play = (time: number) => {
    if (sourceRef.current) {
      sourceRef.current.disconnect();
      sourceRef.current.stop();
      cancelAnimationFrame(animationRef.current!);
    }

    const source = audioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(audioContext.destination);
    source.start(0, time);

    sourceRef.current = source;
    startTimeRef.current = audioContext.currentTime - time;  // Adjust start time

    source.onended = () => {
      setPlaybackTime(audioContext.currentTime - startTimeRef.current!);
      cancelAnimationFrame(animationRef.current!);
    };

    updatePlaybackTime();
  };

  const pause = () => {
    if (sourceRef.current) {
      sourceRef.current.stop();
      sourceRef.current.disconnect();
    }
  };

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newTime = parseFloat(event.target.value);
    setPlaybackTime(newTime);
  };

  const handleSliderCommit = () => {
    play(playbackTime);
  };

  return (
    <PlayerContainer>
      <Button onClick={() => play(playbackTime)}>Play</Button>
      <Button onClick={pause}>Pause</Button>
      <Slider
        type="range"
        min="0"
        max={audioBuffer.duration}
        value={playbackTime}
        step="0.01"
        onChange={handleSliderChange}
        onMouseUp={handleSliderCommit}
        onTouchEnd={handleSliderCommit}
      />
    </PlayerContainer>
  );
};

export default AudioScrubber;
