import { Modal, Box, Typography, Table, TableHead, TableRow, TableCell, TableBody, Button, IconButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FrontendWaitlistPatient, OpenWaitlistAppointment } from '../../types';
import { formatIsoToCustomDateStringWithMD, isDateInArray } from '../../utils';
import { filterPatient } from '../../utils';
import NoSortedPatientsCard from '../cards/NoSortedPatientsCard';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect, useCallback } from 'react';

interface RunWaitlistModalProps {
  open: boolean;
  onClose: () => void;
  patients: FrontendWaitlistPatient[];
  setPatients: any;
  onRunWaitlist: () => void;
  appointment: OpenWaitlistAppointment | null;
}

const RunWaitlistModal: React.FC<RunWaitlistModalProps> = ({
  open, onClose, patients, setPatients, onRunWaitlist, appointment
}) => {
  const LOCAL_TIMEZONE: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [sortedPatients, setSortedPatients] = useState<FrontendWaitlistPatient[]>(patients.filter((patient: FrontendWaitlistPatient) => 
    filterPatient(patient, appointment, LOCAL_TIMEZONE)));

  useEffect(() => {
    if (!sortedPatients) {
      setSortedPatients(patients.filter((patient: FrontendWaitlistPatient) => filterPatient(patient, appointment, LOCAL_TIMEZONE)));
    }
  }, []);
    
  useEffect(() => {
    setPatients(sortedPatients);
  }, [sortedPatients, setPatients]); 

  const moveRowUp = useCallback((index: number) => {
    if (index === 0) return;
    setSortedPatients((prevPatients: any[]) => {
      const newPatients = [...prevPatients];
      [newPatients[index], newPatients[index - 1]] = [newPatients[index - 1], newPatients[index]];
      return newPatients;
    });
  }, [setSortedPatients]);

  const moveRowDown = useCallback((index: number) => {
    if (index === sortedPatients.length - 1) return;
    setSortedPatients((prevPatients: any[]) => {
      const newPatients = [...prevPatients];
      [newPatients[index], newPatients[index + 1]] = [newPatients[index + 1], newPatients[index]];
      return newPatients;
    });
  }, [setSortedPatients, sortedPatients.length]);
  
  const columnTypes = ['Actions', 'Name', 'Phone Number', 'Priority', 'PT', 'Length', 'Dates', "AM/PM"];
  
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 4,
        boxShadow: 24,
        borderRadius: 2,
        width: '90vw',
        maxWidth: '1200px',
        maxHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Typography variant="h6" sx={{ mb: 2 }}>Contact Patients</Typography>
        {sortedPatients.length === 0 ? (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            textAlign: 'center',
            width: 400
          }}>
            <NoSortedPatientsCard />
            <Button variant="contained" onClick={onClose} sx={{ mr: 2 }}>Close</Button>
          </Box>
        ) : (
          <>
            <Box sx={{ maxHeight: '60vh', overflowY: 'auto', width: '100%' }}>
              <Table sx={{ minWidth: '100%' }}>
                <TableHead>
                  <TableRow>
                    {columnTypes.map((type, index) => (
                      <TableCell key={index}>{type}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedPatients.map((patient: FrontendWaitlistPatient, index: number) => (
                    <TableRow key={patient.patientPhoneNumber}>
                      <TableCell>
                        <IconButton onClick={() => setSortedPatients(sortedPatients.filter((filterPatient: FrontendWaitlistPatient) => patient.patientPhoneNumber !== filterPatient.patientPhoneNumber))}>
                          <CloseIcon />
                        </IconButton>
                        <IconButton onClick={() => moveRowUp(index)} disabled={index === 0}>
                          <KeyboardArrowUpIcon />
                        </IconButton>
                        <IconButton onClick={() => moveRowDown(index)} disabled={index === sortedPatients.length - 1}>
                          <KeyboardArrowDownIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>{patient.name}</TableCell>
                      <TableCell>{patient.patientPhoneNumber}</TableCell>
                      <TableCell>{patient.priority ? "Yes" : "No"}</TableCell>
                      <TableCell>{patient.pcpName}</TableCell>
                      <TableCell>{patient.appointmentLength}</TableCell>
                      <TableCell>{patient.dates.map((date: string) => formatIsoToCustomDateStringWithMD(date)).join(', ')}</TableCell>
                      <TableCell>{patient.prefersShift}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <Box sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              mt: 2
            }}>
              <Button variant="contained" onClick={onClose} sx={{ mr: 2 }}>Close</Button>
              <Button variant="contained" onClick={onRunWaitlist}>
                Run waitlist
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );    
};

export default RunWaitlistModal;
