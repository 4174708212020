import React, { useRef, useState, useCallback } from 'react';
import { Box, Tooltip, Typography, IconButton, FormControlLabel, Checkbox, TextField } from '@mui/material';
import MessageIcon from '@mui/icons-material/Message';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Colors } from '../../Colors';
import axios from 'axios';
import { DateTime } from 'luxon';
import { IconWithHover, OvalOutlineListItemWithoutHover, TwoChoiceModal } from '../styles';
import { useNavigate, useParams } from 'react-router-dom';
import { FrontendUser, WaitlistActions, WaitlistRun, WaitlistStatus } from '../../types';
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { deleteWaitlistRun } from '../../slices/waitlistRunsSlice';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { sessionExpired as sessionExpiredGlobally } from '../../slices/sessionSlice';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import LoadingWithSubtitle from './LoadingWithSubtitle';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import TextCampaignConfirmationModal from './modals/TextConfirmationModal';
import CallConfirmationModal from './modals/CallConfirmationModal';
import HybridCampaignConfirmationModal from './modals/HybridCampaignConfirmationModal';

const DEFAULT_NUMBER_OF_MINUTES_TO_WAIT_BEFORE_CALLING = 10;
const DEFAULT_MAX_WIDTH = 350;

interface CampaignActionsProps {
  waitlistRun: WaitlistRun;
  onAction: () => void;
}

const defaultDate: DateTime = DateTime.now().plus({ days: 1 });

const CampaignActions: React.FC<CampaignActionsProps> = React.memo(({ waitlistRun, onAction }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { assistantId } = useParams<{ assistantId: string, waitlistRunId: string }>();
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();
  const [showRunTextCampaignModal, setShowRunTextCampaignModal] = useState(false);
  const [showRunCallCampaignModal, setShowRunCallCampaignModal] = useState(false);
  const [showRunHybridCampaignModal, setShowRunHybridCampaignModal] = useState(false);
  const [showDeleteWaitlistModal, setShowDeleteWaitlistModal] = useState(false);
  const [showPauseWaitlistModal, setShowPauseWaitlistModal] = useState(false);
  const [automatedTextingEnabled, setAutomatedTextingEnabled] = useState(false);
  const [showUnpauseWaitlistModal, setShowUnpauseWaitlistModal] = useState(false);
  const [numberOfMinutesToWaitBeforeCalling, setNumberOfMinutesToWaitBeforeCalling] = useState(DEFAULT_NUMBER_OF_MINUTES_TO_WAIT_BEFORE_CALLING);
  const [cutoffDate, setCutoffDate] = useState<DateTime>(DateTime.fromISO(waitlistRun.appointmentDate).minus({ hour: 1 }));
  const { showMessage } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  // useEffect(() => {
  //   if (waitlistRun?.appointmentDate) {
  //     setCutoffDate(DateTime.fromISO(waitlistRun.appointmentDate).minus({ hour: 1 }));
  //   }
  // }, [waitlistRun?.appointmentDate]);

  const handleDeleteWaitlistRun = async (waitlistRunId: string) => {
    if (!user?.id) { return };
    try { 
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/${assistantId}/waitlist/${waitlistRunId}/${WaitlistActions.deleteRun}`, 
      {
        userId: user?.id,
      },
      {
        headers: {
          'Authorization': `Bearer ${user?.token}`,
        }
      });

      if (response.status === 200) {
        showMessage('Waitlist run deleted successfully.', 'success');
        // if nothing was returned, don't do the update
        dispatch(deleteWaitlistRun({ waitlistRunId: waitlistRunId }) as any);
        navigate(`/assistants/${assistantId}/runs`);
      } else {
        throw new Error(`Failed to delete the waitlist run with id: ${waitlistRunId}`);
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        showMessage('Session has expired', 'error');
        dispatch(sessionExpiredGlobally(true) as any);
      } else {
        console.error('Error deleting waitlist run:', error);
        // showMessage('Failed to delete waitlist run. Please try again.', 'error');
      }
    }
  };

  const handleTriggerCalls = async (waitlistRunId: string) => {
    if (!user?.id || !user?.token) { return };
    if (!waitlistRunId) { return };
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/${assistantId}/waitlist/${waitlistRunId}/${WaitlistActions.runWaitlistCalls}`, 
      {
        userId: user?.id,
      },
      {
        headers: {
          'Authorization': `Bearer ${user?.token}`,
        }
      });

      if (response.status === 200) {
        showMessage('Waitlist began run successfully.', 'success');
        onAction();
      } else {
        throw new Error(`Failed to run the waitlist run with id: ${waitlistRunId}`);
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        showMessage('Session has expired', 'error');
        dispatch(sessionExpiredGlobally(true) as any);
      } else {
        console.error('Error running waitlist run:', error);
        // showMessage('Failed to run waitlist run. Please try again.', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleTriggerHybridCampaign = async (waitlistRunId: string) => {
    if (!user?.id || !user?.token) { return };
    if (!waitlistRunId) { return };
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/${assistantId}/waitlist/${waitlistRunId}/${WaitlistActions.runWaitlistTextsAndCalls}`, 
      {
        userId: user?.id,
        automatedTextingEnabled: automatedTextingEnabled,
        cutoffDate: cutoffDate?.toISO(),
        numberOfMinutesToWaitBeforeCalling: numberOfMinutesToWaitBeforeCalling,
      },
      {
        headers: {
          'Authorization': `Bearer ${user?.token}`,
        }
      });

      if (response.status === 200) {
        showMessage('Waitlist began run successfully.', 'success');
        onAction();
      } else {
        throw new Error(`Failed to run the waitlist run with id: ${waitlistRunId}`);
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        showMessage('Session has expired', 'error');
        dispatch(sessionExpiredGlobally(true) as any);
      } else {
        console.error('Error running waitlist run:', error);
        // showMessage('Failed to run waitlist run. Please try again.', 'error');
      }
    } finally {
      setLoading(false);
    }
  }

  const handleTriggerTexts = async (waitlistRunId: string) => {
    if (!user?.id || !user?.token) { return };
    if (!waitlistRunId) { return };
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/${assistantId}/waitlist/${waitlistRunId}/${WaitlistActions.runWaitlistTexts}`, 
      {
        userId: user?.id,
        automatedTextingEnabled: automatedTextingEnabled,
        cutoffDate: cutoffDate?.toISO(),
      },
      {
        headers: {
          'Authorization': `Bearer ${user?.token}`,
        }
      });

      if (response.status === 200) {
        showMessage('Triggered text campaign to waitlist.', 'success');
        onAction();
      } else {
        throw new Error(`Failed to run the waitlist run with id: ${waitlistRunId}`);
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        showMessage('Session has expired', 'error');
        dispatch(sessionExpiredGlobally(true) as any);
      } else {
        console.error('Error running waitlist run:', error);
        // showMessage('Failed to run waitlist run. Please try again.', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePauseWaitlistRun = async (waitlistRunId: string) => {
    if (!user?.id || !user?.token) { return };
    if (!waitlistRunId) { return };
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/${assistantId}/waitlist/${waitlistRunId}/${WaitlistActions.pauseWaitlistRun}`, 
      {
        userId: user?.id,
      },
      {
        headers: {
          'Authorization': `Bearer ${user?.token}`,
        }
      });

      if (response.status === 200) {
        showMessage('Waitlist paused successfully.', 'success');
        onAction();
      } else {
        throw new Error(`Failed to pause the waitlist with id: ${waitlistRunId}`);
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        showMessage('Session has expired', 'error');
        dispatch(sessionExpiredGlobally(true) as any);
      } else {
        console.error('Error pausing waitlist run:', error);
        // showMessage('Failed to pause waitlist run. Please try again.', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleUnpauseWaitlistRun = async (waitlistRunId: string) => {
    if (!user?.id || !user?.token) { return };
    if (!waitlistRunId) { return };
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/${assistantId}/waitlist/${waitlistRunId}/${WaitlistActions.unpauseWaitlistRun}`, 
      {
        userId: user?.id,
      },
      {
        headers: {
          'Authorization': `Bearer ${user?.token}`,
        }
      });

      if (response.status === 200) {
        showMessage('Waitlist unpaused successfully.', 'success');
        onAction();
      } else {
        throw new Error(`Failed to unpause the waitlist with id: ${waitlistRunId}`);
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        showMessage('Session has expired', 'error');
        dispatch(sessionExpiredGlobally(true) as any);
      } else {
        console.error('Error unpausing waitlist run:', error);
        // showMessage('Failed to unpause waitlist run. Please try again.', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <TextCampaignConfirmationModal
        isOpen={showRunTextCampaignModal}
        onCancel={() => setShowRunTextCampaignModal(false)}
        onConfirm={() => {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null; // Reset the ref to null
          }

          handleTriggerTexts(waitlistRun?.waitlistRunId);
          setShowRunTextCampaignModal(false); // Close the modal upon confirmation
          navigate(`/assistants/${assistantId}/runs/${waitlistRun?.waitlistRunId}`);
        }}
        message="Are you sure you want to run a text campaign for this waitlist? This will initiate text messages to all of the patients on the waitlist."
        actionMessageOne='Cancel'
        actionMessageTwo='Confirm'
        waitlistRun={waitlistRun}
        automatedTextingEnabled={automatedTextingEnabled}
        setAutomatedTextingEnabled={setAutomatedTextingEnabled}
        cutoffDate={cutoffDate}
        setCutoffDate={setCutoffDate}
      />
      <CallConfirmationModal
        isOpen={showRunCallCampaignModal}
        onCancel={() => setShowRunCallCampaignModal(false)}
        onConfirm={() => {
          handleTriggerCalls(waitlistRun?.waitlistRunId);
          setShowRunCallCampaignModal(false); // Close the modal upon confirmation
          navigate(`/assistants/${assistantId}/runs/${waitlistRun?.waitlistRunId}`);
        }}
        message="Are you sure you want to trigger a call campaign for this waitlist? This will call each patient in the waitlist on a first-come first-serve basis until the appointment is taken."
        actionMessageOne='Cancel'
        actionMessageTwo='Confirm'
      />
      <HybridCampaignConfirmationModal
        isOpen={showRunHybridCampaignModal}
        onCancel={() => setShowRunHybridCampaignModal(false)}
        onConfirm={() => {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null; // Reset the ref to null
          }

          handleTriggerHybridCampaign(waitlistRun?.waitlistRunId);
          setShowRunHybridCampaignModal(false); // Close the modal upon confirmation
          navigate(`/assistants/${assistantId}/runs/${waitlistRun?.waitlistRunId}`);
        }}
        message="Are you sure you want to run a hybrid campaign for this waitlist? This will initiate both text and call campaigns."
        actionMessageOne='Cancel'
        actionMessageTwo='Confirm'
        waitlistRun={waitlistRun}
        automatedTextingEnabled={automatedTextingEnabled}
        setAutomatedTextingEnabled={setAutomatedTextingEnabled}
        cutoffDate={cutoffDate}
        setCutoffDate={setCutoffDate}
      />
      <TwoChoiceModal
        isOpen={showDeleteWaitlistModal}
        onCancel={() => setShowDeleteWaitlistModal(false)}
        onConfirm={() => {
          navigate(`/assistants/${assistantId}/runs`);
          handleDeleteWaitlistRun(waitlistRun?.waitlistRunId);
          setShowDeleteWaitlistModal(false); // Close the modal upon confirmation
        }}
        message="Are you sure you want to delete the waitlist? You will have to re-upload a CSV to run the waitlist again."
        actionMessageOne='Cancel'
        actionMessageTwo='Confirm'
      />
      <TwoChoiceModal
        isOpen={showPauseWaitlistModal}
        onCancel={() => setShowPauseWaitlistModal(false)}
        onConfirm={() => {
          handlePauseWaitlistRun(waitlistRun?.waitlistRunId);
          setShowPauseWaitlistModal(false); // Close the modal upon confirmation
        }}
        message="Are you sure you want to stop running the waitlist? This will halt all outbound communication to the patients on this waitlist."
        actionMessageOne='Cancel'
        actionMessageTwo='Confirm'
      />
      <TwoChoiceModal
        isOpen={showUnpauseWaitlistModal}
        onCancel={() => setShowUnpauseWaitlistModal(false)}
        onConfirm={() => {
          handleUnpauseWaitlistRun(waitlistRun?.waitlistRunId);
          setShowUnpauseWaitlistModal(false);
        }}
        message="Are you sure you want to unpause the waitlist? This will pick up where the waitlist was left off. Patients already contacted will not be contacted again."
        actionMessageOne='Cancel'
        actionMessageTwo='Confirm'
      />
      { loading && <LoadingWithSubtitle subtitle="Loading..." size={50} /> }
      { waitlistRun?.status === WaitlistStatus.notStarted && (
        <Box sx={{ flexDirection: 'column', alignItems: 'center', p: 2, DEFAULT_MAX_WIDTH }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
            <Tooltip title="Text all patients">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={() => setShowRunTextCampaignModal(true)}>
                  <MessageIcon sx={{ fontSize: 50, color: Colors.grey2 }} />
                </IconButton>
                <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 'bold' }}>Text all patients</Typography>
              </Box>
            </Tooltip>
            <Tooltip title="Call all patients">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={() => setShowRunCallCampaignModal(true)}>
                  <LocalPhoneIcon sx={{ fontSize: 50, color: Colors.grey2 }} />
                </IconButton>
                <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 'bold' }}>Call all patients</Typography>
              </Box>
            </Tooltip>
            <Tooltip title="Text and call patients">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={() => setShowRunHybridCampaignModal(true)}>
                  <MessageIcon sx={{ fontSize: 50, color: Colors.grey2 }} />
                  <AddIcon sx={{ fontSize: 30, marginX: 1 }} />
                  <LocalPhoneIcon sx={{ fontSize: 50, color: Colors.grey2 }} />
                </IconButton>
                <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 'bold' }}>Text and call patients</Typography>
              </Box>
            </Tooltip>
            <Tooltip title="Delete waitlist run">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={() => setShowDeleteWaitlistModal(true)}>
                  <DeleteIcon sx={{ fontSize: 50, color: Colors.grey2 }} />
                </IconButton>
                <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 'bold' }}>Delete waitlist run</Typography>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      )}
      {waitlistRun?.status === WaitlistStatus.started && (
        <>
        <OvalOutlineListItemWithoutHover sx={{
            flexDirection: 'column', 
            alignItems: 'flex-start', // Align items to the start (left)
            p: 2
          }}
          maxWidth="350px"
        >
          <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'flex-start', // Ensure content alignment to the left
              gap: 2 
            }}
          >
            <Tooltip title="Stop waitlist run">
              <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center' 
                }}
              >
                <IconWithHover onClick={() => setShowPauseWaitlistModal(true)}>
                  <PauseIcon sx={{ fontSize: 50, color: Colors.grey2 }} />
                </IconWithHover>
                <Typography variant="body1" sx={{ 
                    marginLeft: 1, 
                    fontWeight: 'bold' 
                  }}
                >
                  Pause waitlist
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </OvalOutlineListItemWithoutHover>
      </>
      
      )}
      {waitlistRun?.status === WaitlistStatus.paused && (
        <>
          <OvalOutlineListItemWithoutHover sx={{ flexDirection: 'column', alignItems: 'left', p: 2 }} maxWidth="350px">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', gap: 2 }}>
              <Tooltip title="Unpause waitlist run">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconWithHover onClick={() => setShowUnpauseWaitlistModal(true)}>
                    <PlayArrowIcon sx={{ fontSize: 50, color: Colors.grey2 }} />
                  </IconWithHover>
                  <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 'bold' }}>Unpause waitlist</Typography>
                </Box>
            </Tooltip>
            <Tooltip title="Delete waitlist run">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconWithHover onClick={() => setShowDeleteWaitlistModal(true)}>
                  <DeleteIcon sx={{ fontSize: 50, color: Colors.grey2 }} />
                </IconWithHover>
                <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 'bold' }}>Delete waitlist run</Typography>
              </Box>
            </Tooltip>
            </Box>
          </OvalOutlineListItemWithoutHover>
        </>
      )}
    </>
  );
});

export default CampaignActions;
