import React from 'react';
import { Box, IconButton, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { WaitlistPatient, WaitlistRun, WaitlistStatus } from '../../types';
import { OvalOutlineListItem } from '../styles';
import { PatientStatusAvatarTooltip } from '../../components/styles';
import { statusMessages } from '../../utils';

interface PatientCircleProps {
  patient: WaitlistPatient;
  waitlistRun: WaitlistRun;
  onClick: () => void;
  onCloseClicked: (waitlistPatientId: string) => void;
}

const PatientCircle: React.FC<PatientCircleProps> = ({ patient, waitlistRun, onClick, onCloseClicked }) => {
  return (
    <OvalOutlineListItem maxWidth="350px" key={patient.status} alignItems="center" onClick={onClick}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        {patient.consentHistory && patient.consentHistory.length > 0 ? (() => {
          const latestConsent = patient.consentHistory[patient.consentHistory.length - 1];
          return (
            <PatientStatusAvatarTooltip 
              patientStatus={patient.status} 
              sx={{ mr: 2 }} 
              consent={latestConsent.consent} 
              latestConsentMessage={latestConsent.message} 
              latestConsentDate={latestConsent.date} 
            />
          );
        })() : (
          <PatientStatusAvatarTooltip 
            patientStatus={patient.status} 
            sx={{ mr: 2 }} 
          />
        )}
        <ListItemText
          primary={`${patient.patientFirstName} ${patient.patientLastName}`}
          secondary={statusMessages[patient.status]}
        />
        {waitlistRun.status === WaitlistStatus.notStarted && (
          <IconButton onClick={(event: any) => {
            event.stopPropagation();
            onCloseClicked(patient.waitlistPatientId);
          }} 
          sx={{ ml: 'auto' }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </OvalOutlineListItem>
  );
}

export default PatientCircle;
