// src/auth/ProtectedRoute.tsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { RootState } from '../store';
import { useSelector } from 'react-redux';

interface ProtectedRouteProps {
    element: React.ReactElement;
    path?: string; // Include other props you might need
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, path }) => {
    const user = useSelector((state: RootState) => state.auth.user);

    if (!user) {
      return <Navigate to="/login" />;
    }

    // Render the component if user is authenticated
    return element;
};

export default ProtectedRoute;
