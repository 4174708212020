import React, { useEffect, useRef, useState } from 'react';
import Link from '@mui/material/Link';
import { Card, CardContent, Typography, IconButton, Box, Divider, List, ListItemIcon, ListItemText, ListItem, Avatar, CircularProgress, Icon, Tooltip } from '@mui/material';
import AudioPlayer from 'react-audio-player';
import { CallStatusesUi, AssistantTitles, CallOutcomesUi, CallOutcomes, FrontendUser, AssistantMetadata, FrontendCall, WaitlistOutcomes, WaitlistRun, WaitlistPatient, FrontendText, WaitlistPatientStatus, WaitlistStatusUi, WaitlistStatus, WaitlistActions } from '../types';
import Modal from '../components/Modal';
import AssistantModal from '../components/AssistantModal';
import styled from 'styled-components';
import ErrorIcon from '@mui/icons-material/Error';
import { usePostHog } from 'posthog-js/react';
import AudioScrubber from '../components/AudioScrubber';

// icons
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TimerIcon from '@mui/icons-material/Timer';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotesIcon from '@mui/icons-material/Notes';
import { OvalOutlineListItem, TranscriptContainer, TranscriptButton, PatientStatusAvatarTooltip, WaitlistActionAvatar, TwoChoiceModal, RefreshButton, StyledIconButton, StyledToggleButton, StatusMessage, OvalOutlineListItemWithoutHover, IconWithHover } from '../components/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectCalls } from '../slices/callsSlice';
import { selectAssistants } from '../slices/assistantsSlice';
import { useSnackbar } from '../providers/SnackbarProvider';
import { AppDispatch, RootState } from '../store';
import { selectWaitlistRuns, updateWaitlistRuns } from '../slices/waitlistRunsSlice';
import axios from 'axios';
import { sessionExpired as sessionExpiredGlobally } from '../slices/sessionSlice';
import { statusColors, statusIcons, statusMessages, formatIsoToCustomDateStringWithEEEEHHMMA, WebsocketEvents } from '../utils';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RefreshIcon from '@mui/icons-material/Refresh';
import PauseIcon from '@mui/icons-material/Pause';
import DeleteIcon from '@mui/icons-material/Delete';
import { Colors } from '../Colors';
import MessageIcon from '@mui/icons-material/Message';
import TextThreadDisplay from '../components/subcomponents/TextThreadDisplay';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import CallMetadataDisplay from '../components/subcomponents/CallMetadataDisplay';
import EventIcon from '@mui/icons-material/Event';

const StyledAvatar = styled(Avatar)`
  background-color: #4CAF50;
`;

const WaitlistPatientDetail: React.FC = () => {
  const { assistantId, waitlistRunId, waitlistPatientId } = useParams<{ assistantId: string, waitlistRunId: string, waitlistPatientId: string }>();
  const [waitlistRunIdState, setWaitlistRunIdState] = useState<string | undefined>(waitlistRunId);
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const posthog = usePostHog();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { showMessage } = useSnackbar();
  const calls: FrontendCall[] = useSelector(selectCalls);
  const assistants: AssistantMetadata[] = useSelector(selectAssistants);
  const waitlistRuns: WaitlistRun[] = useSelector(selectWaitlistRuns);
  const [waitlistRun, setWaitlistRun] = useState<WaitlistRun | undefined>(waitlistRuns.find((run: WaitlistRun) => run.waitlistRunId === waitlistRunIdState));
  const [assistant, setAssistant] = useState<AssistantMetadata | null>(assistants.find((assistant: AssistantMetadata) => assistant.assistantId === assistantId) || null);
  const [isAssistantModalOpen, setAssistantModalOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [waitlistPatient, setWaitlistPatient] = useState<WaitlistPatient | undefined>(waitlistRun?.patients.find((patient: WaitlistPatient) => patient.waitlistPatientId === waitlistPatientId));
  const [conservationDetail, setConversationDetail] = useState<FrontendText | FrontendCall | null>(null);
  const [textMetadata, setTextMetadata] = useState<FrontendText | undefined>(undefined);
  const [callMetadata, setCallMetadata] = useState<FrontendCall | undefined>(undefined);
  const [automatedTextsEnabled, setAutomatedTextsEnabled] = useState<boolean>(true);
  const lastFetchTimeRef = useRef<number>(0);
  const lastFailedFetchTimeRef = useRef<number>(0);
  const [isMounted, setIsMounted] = useState(true);
  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
        setIsMounted(false); // Dismount component on clicking away
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [componentRef]);

  useEffect(() => {
    if (!user?.id) { return };
    if (!user?.token) { return };

    const connectWebSocket = async () => {
      try {
        const socket = new WebSocket(`wss://${process.env.REACT_APP_BACKEND_URL_NO_HTTPS}/api/subscribe/${user.id}?token=${encodeURIComponent(user.token!)}`);

        socket.onopen = () => {
          console.log('Connected to WebSocket');
          socket.send(JSON.stringify({ action: 'subscribe', type: WebsocketEvents.WaitlistPatients}));
          socket.send(JSON.stringify({ action: 'subscribe', type: WebsocketEvents.Calls }));
          socket.send(JSON.stringify({ action: 'subscribe', type: WebsocketEvents.Texts }));
        };

        socket.onmessage = (event: any) => {
          getData();
        };

        socket.onclose = () => {
          console.log('Disconnected from WebSocket');
        };

        socket.onerror = (error) => {
          console.error('WebSocket error:', error);
          showMessage('WebSocket error. Please try again.', 'error');
        };

        return () => {
          socket.close();
        };
      } catch (error: any) {
        if (error.response?.status === 401) {
          showMessage('Session has expired', 'error');
        } else {
          console.error('Error connecting to WebSocket:', error);
          showMessage('Please refresh the page.', 'error');
        }
      }
    };

    connectWebSocket();
  }, []);

  const getData = async () => {
    handleGetPatient();
    handleGetWaitlist();
  };

  useEffect(() => {
    setLoading(true);
    getData();
    setLoading(false);
  }, []);

  useEffect(() => {
    getData();  // Initial fetch on component mount
  }, []);

  useEffect(() => {
    // React to changes in assistantId, waitlistRunId, or waitlistPatientId
    getData();  // Fetch data when parameters change
  }, [assistantId, waitlistRunId, waitlistPatientId]);

  useEffect(() => {
    if (waitlistPatient?.textMetadata) {
      setTextMetadata(waitlistPatient.textMetadata);
    } else if (waitlistPatient?.callMetadata) {
      setCallMetadata(waitlistPatient.callMetadata);
    }
  }, [waitlistPatient]);

  if (!isMounted) return null;

  const confirmedColor = "#53C43B"; // Green
  const errorColor = "#bc4a43"; // Red

  const renderIconBasedOnOutcome = (outcome: string) => {
    switch (outcome) {
      case WaitlistOutcomes.scheduled:
        return <CheckCircleIcon style={{ color: confirmedColor }} />;
      case WaitlistOutcomes.unscheduled:
        return <CloseIcon style={{ color: errorColor }} />;
      default:
        return null; // or any default icon
    }
  };

  const handleBack = () => {
    setWaitlistRunIdState(undefined);
    navigate(`/assistants/${assistant?.assistantId}/runs/${waitlistRunIdState}`); // Navigate back to the assistant history
  };

  // Function to handle opening/closing the assistant modal
  const toggleAssistantModal = () => {
    // register click in posthog
    if (!waitlistRunIdState) { return; }
    posthog?.capture('[SECRETARY] assistant_modal_opened', { waitlist_run_id: waitlistRunIdState, assistant_id: assistant?.assistantId, source: 'waitlist_run_detail' });
    setAssistantModalOpen(!isAssistantModalOpen);
  };

  const handleGetWaitlist = async () => {
    if (!user?.id) {
      console.error("No user id found");
      showMessage('No user found. Please refresh the page', 'error');
      return;
    }

    const now = Date.now();
    if (now - lastFetchTimeRef.current < 5000) {
      // console.log('Fetch operation is rate-limited. Please wait.');
      return;
    }
    lastFetchTimeRef.current = now;

    if (!waitlistRunIdState) { return; }
    if (!waitlistRuns.find((run: WaitlistRun) => run.waitlistRunId === waitlistRunIdState)) { return; }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/waitlist/${waitlistRunIdState}`,
        {
          headers: {
            'Authorization': `Bearer ${user?.token}`,
          }
        }
      );

      if (response.status === 200) {
        setWaitlistRun(response.data);
        dispatch(updateWaitlistRuns(response.data) as any);
      } else {
        throw new Error('Failed to get waitlist run.');
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        showMessage('Session has expired', 'error');
        dispatch(sessionExpiredGlobally(true) as any);
      } else {
        // console.error('Error getting waitlist run information.', error);
        // showMessage('Failed to get waitlist run information. Please try again.', 'error');
      }
    }
  };

  const firstContactedDate = () => {
    const initialTextDate = waitlistPatient?.textMetadata?.thread?.[0]?.date;
    if (!initialTextDate) {
      return null;
    }
    return formatIsoToCustomDateStringWithEEEEHHMMA(initialTextDate);
  };

  const firstContactedDateValue = firstContactedDate();

  const handleDisplayTextHistory = () => {
    if (!waitlistPatient?.textMetadata) { return; }
    setTextMetadata(waitlistPatient.textMetadata);
    setCallMetadata(undefined);
  };

  const handleDisplayCallHistory = () => {
    if (!waitlistPatient?.callMetadata) { return; }
    setCallMetadata(waitlistPatient.callMetadata);
    setTextMetadata(undefined);
  };


  const handleGetPatient = async (): Promise<void> => {
    try {
      if (!user?.id) {
        console.error("No user id found");
        showMessage('No user found. Please refresh the page', 'error');
        throw new Error('No user found. Please refresh the page');
      }

      const now = Date.now();
      if (now - lastFailedFetchTimeRef.current < 5000) {
        return;
      }

      if (!waitlistPatientId) { return; }
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/waitlistPatients/${waitlistPatientId}`,
        {
          headers: {
            'Authorization': `Bearer ${user?.token}`,
          }
        }
      );

      if (response.status === 200) {
        setWaitlistPatient(response.data);
        setTextMetadata(response.data.textMetadata);
      } else {
        throw new Error('Failed to get text.');
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        showMessage('Session has expired', 'error');
        dispatch(sessionExpiredGlobally(true) as any);
      } else {
        console.error('Error getting patient data.', error);
        // showMessage('Failed to get text metadata. Please try again.', 'error');
        const now = Date.now();
        lastFailedFetchTimeRef.current = now;
      }
    }
  };

  return (
    <>
      {loading && <CircularProgress />}
      {!loading && waitlistRun && assistant && waitlistPatient && (
        <Box sx={{ display: 'flex', width: '100%' }}>
          {/* Main content section on the left */}
          <Box sx={{ flex: 1, paddingRight: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <IconButton onClick={handleBack} sx={{ mr: 2 }}>
                <ArrowBackIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography gutterBottom variant="h4" component="div" sx={{ my: 2 }}>
                {`${waitlistPatient.patientFirstName} ${waitlistPatient.patientLastName}`}
              </Typography>
              <WaitlistActionAvatar>
                <Tooltip title="Refresh data">
                  <IconButton onClick={() => {
                    posthog?.capture('[SECRETARY] refresh_patient_data', { waitlist_patient_id: waitlistPatient.waitlistPatientId, assistant_id: assistant.assistantId });
                    handleGetPatient();
                  }}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </WaitlistActionAvatar>
            </Box>
            <OvalOutlineListItem onClick={toggleAssistantModal} maxWidth="350px">
              <ListItemIcon>
                <Avatar src={assistant?.avatarUrl} alt={assistant?.name} sx={{ width: 30, height: 30 }} />
              </ListItemIcon>
              {assistant && (
                <ListItemText
                  primary="Contacted by" 
                  secondary={assistant ? `${assistant.name} (${AssistantTitles[assistant.title]})` : ''} 
                />
              )}
            </OvalOutlineListItem>
            {firstContactedDateValue && (
              <ListItem>
                <ListItemIcon>
                  <AccessTimeIcon />
                </ListItemIcon>
                <ListItemText primary="Created at" secondary={firstContactedDateValue} />
              </ListItem>
            )}
            <ListItem>
              <ListItemIcon>
                <FingerprintIcon />
              </ListItemIcon>
              {waitlistRun && <ListItemText primary="Patient ID" secondary={waitlistPatient?.waitlistPatientId} />}
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText primary="Appointment date" secondary={formatIsoToCustomDateStringWithEEEEHHMMA(waitlistRun.appointmentDate)} />
            </ListItem>
            <ListItem alignItems="center">
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <PatientStatusAvatarTooltip patientStatus={waitlistPatient.status} sx={{ mr: 2 }} /> {/* Adjust the mr value as needed */}
                <ListItemText
                  primary={`Status: ${statusMessages[waitlistPatient.status]}`}
                />
              </Box>
            </ListItem>            
            <br />
            <Typography gutterBottom variant="h4" component="div" sx={{ my: 2 }}>
              Channels
            </Typography>
            {waitlistPatient?.textMetadata && (
              <OvalOutlineListItemWithoutHover 
                onClick={() => {
                  posthog?.capture('[SECRETARY] view_text_history', { userId: user?.id, waitlist_patient_id: waitlistPatient.waitlistPatientId, assistant_id: assistant.assistantId });
                  handleDisplayTextHistory();
                }}
                sx={{ flexDirection: 'row', alignItems: 'center', p: 2 }} 
                maxWidth="350px"
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Tooltip title="View text history">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <IconWithHover>
                        <MessageIcon sx={{ fontSize: 50, color: Colors.grey2 }} />
                      </IconWithHover>
                      <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 'bold' }}>View text history</Typography>
                    </Box>
                  </Tooltip>
                </Box>
                <br />
              </OvalOutlineListItemWithoutHover>
            )}

            {waitlistPatient?.callMetadata && (
              <OvalOutlineListItemWithoutHover 
                onClick={() => {
                  posthog?.capture('[SECRETARY] view_call_history', { userId: user?.id, waitlist_patient_id: waitlistPatient.waitlistPatientId, assistant_id: assistant.assistantId });
                  handleDisplayCallHistory();
                }}
                sx={{ flexDirection: 'column', alignItems: 'center', p: 2 }} 
                maxWidth="350px"
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
                  <Tooltip title="View call history">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <IconWithHover>
                        <LocalPhoneIcon sx={{ fontSize: 50, color: Colors.grey2 }} />
                      </IconWithHover>
                      <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 'bold' }}>View call history</Typography>
                    </Box>
                  </Tooltip>
                </Box>
                <br />
              </OvalOutlineListItemWithoutHover>
            )}
            {
              !waitlistPatient?.textMetadata && !waitlistPatient?.callMetadata && (
                <ListItem>
                  <Typography gutterBottom variant="h6" component="div" sx={{ my: 2 }}>
                    No channels found
                  </Typography>
                </ListItem>
              )
            }
  
            {/* Assistant Information Modal */}
            <AssistantModal
              assistant={assistant}
              open={isAssistantModalOpen}
              onClose={toggleAssistantModal}
            />
          </Box>
          {/* Patients section on the right */}
          <Box
            sx={{
              flex: 1,
              paddingLeft: 2,
              maxWidth: '50%',
              alignSelf: 'flex-start' // Adjusts vertical alignment to be at the top of the flex container
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              {textMetadata ? (
                <TextThreadDisplay
                  key={Date.now()}
                  frontendText={textMetadata}
                />
              ) : callMetadata ? (
                <CallMetadataDisplay
                  key={Date.now()}
                  frontendCall={callMetadata}
                />
              ) : null}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );  
};

export default WaitlistPatientDetail;
