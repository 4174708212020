import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { confirmUser } from '../auth/authSlice';
import { AppDispatch } from '../store';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { usePostHog } from 'posthog-js/react';

const ConfirmPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const posthog = usePostHog();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    posthog?.capture('confirmation_code_submitted');
    const resultAction = await dispatch(confirmUser({ email, code }) as any);
    if (confirmUser.fulfilled.match(resultAction)) {
      navigate('/login');
    } else {
      console.error('Failed to confirm user.');
    }
  };

  return (
    <Box boxShadow={5} sx={{ p: 6, position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
      <Box display="flex" flexDirection="column" alignItems="center" marginTop={8} >
        <Typography variant="h4" gutterBottom>
          Confirm Your Account
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="code"
            label="Confirmation Code"
            type="text"
            id="code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: 3, marginBottom: 2 }}
          >
            Confirm Account
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default ConfirmPage;