// slices/assistantsSlice.tsx

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios from 'axios';
import { AssistantMetadata } from '../types';
import { sessionExpired } from './sessionSlice';

type UpdateAssistantPayload = {
  assistant: AssistantMetadata;
};

export interface AssistantsSlice {
  assistants: AssistantMetadata[];
  loading: boolean;
  error: string | null; // Add an error field to store error messages
};

const initialState: AssistantsSlice = {
  assistants: [],
  loading: false,
  error: null, // Initial error state is null
};

export const fetchAssistants = createAsyncThunk<
  { assistants: AssistantMetadata[] }, // Expected return type
  { userId: string, token: string }, // Arguments passed to the thunk
  { rejectValue: Error }
>(
  'assistants/fetchAssistants', // Ensure the prefix matches the slice name
  async ({ userId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/assistants`, {
        params: { userId: userId },
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (response.data) { // Make sure you're accessing the right data property
        const assistantMetadata: AssistantMetadata[] = response.data;
        return { assistants: assistantMetadata };
      }
      throw new Error('Invalid response data');
    } catch (error: any) {
      if (error.response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error('Failed to fetch assistants: ' + error?.message));
    }
  }
);

export const updateAssistantThunk = createAsyncThunk(
  'assistants/updateAssistant',
  async ({ assistant, token }: UpdateAssistantPayload & { token: string }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/${assistant.assistantId}`, {
        assistant,
      }, 
      {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (response.status === 200) {
        return assistant; // Return the updated assistant data
      } else {
        return rejectWithValue('Failed to update assistant.');
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue('Failed to update assistant: ' + error.message);
    }
  }
);

export const updateRankings = createAsyncThunk(
  'assistants/updateRankings',
  async ({ assistantId, token, waitlistColumnRankings, variableSubRankings }: { assistantId: string, token: string, waitlistColumnRankings: any, variableSubRankings: any }, { getState, rejectWithValue }) => {
    try {
      const assistant: AssistantMetadata = { ...(getState() as any).assistants.assistants.find((assistant: AssistantMetadata) => assistant.assistantId === assistantId), waitlistColumnRankings, variableSubRankings };
      const response = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/${assistantId}`, 
      {
        assistant: assistant,
      },
      {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


const assistantsSlice = createSlice({
  name: 'assistants',
  initialState,
  reducers: {
    // Define a reducer to update an assistant directly in the state
    updateAssistant: (state, action: PayloadAction<AssistantMetadata>) => {
      const index = state.assistants.findIndex((assistant: AssistantMetadata) => assistant.assistantId === action.payload.assistantId);
      if (index !== -1) {
        state.assistants[index] = { ...state.assistants[index], ...action.payload };
      }
    },
  },
  extraReducers: (builder) => {
    // your existing cases
    builder
      .addCase(fetchAssistants.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAssistants.fulfilled, (state, action) => {
        state.assistants = action.payload.assistants;
        state.loading = false;
      })
      .addCase(fetchAssistants.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch assistants';
        state.loading = false;
      })
      .addCase(updateAssistantThunk.fulfilled, (state, action) => {
        const index = state.assistants.findIndex(assistant => assistant.assistantId === action.payload.assistantId);
        if (index !== -1) {
          state.assistants[index] = { ...state.assistants[index], ...action.payload };
        }
      })
      .addCase(updateAssistantThunk.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to update assistant';
      });
    builder
      .addCase(updateRankings.fulfilled, (state, action) => {
        const index = state.assistants.findIndex(ass => ass.assistantId === action.payload.assistantId);
        if (index !== -1) {
          state.assistants[index].waitlistColumnRankings = action.payload.waitlistColumnRankings;
          state.assistants[index].variableSubRankings = action.payload.variableSubRankings;
        }
      });
  },
});

export const selectAssistants = (state: RootState) => state.assistants.assistants;
export const { updateAssistant } = assistantsSlice.actions;

export default assistantsSlice.reducer;
