import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { formatIsoToCustomDateStringWithEEEE } from '../../utils';
import { FrontendWaitlistPatient } from '../../types';
import { Row } from '../styles';

const Info = styled.div`
  color: #333;
  font-size: 16px;
`;

interface PatientRowProps {
  index: number;
  patient: FrontendWaitlistPatient;
  onClick: () => void;
  onDelete: () => void;
}

// PatientRow component
const PatientRow: React.FC<PatientRowProps> = ({ index, patient, onClick, onDelete }) => {
  return (
    <Row onClick={onClick}>
      <div>
        <div>{index}. {patient.name}</div> 
        <div>{patient.pcpName}</div>
        <div>{patient.appointmentLength} min</div>
        { patient?.dates?.length > 0 && 
          <div>
            {patient?.dates?.map((date: string, index: number) => (
              <div key={index}>{formatIsoToCustomDateStringWithEEEE(date)}</div>
            ))}
          </div>
        }
        <div>{patient.prefersShift}</div>
      </div>
      <IconButton aria-label="close" size="small">
        <CloseIcon onClick={(e: any) => {
          e.stopPropagation();
          onDelete();
        }} 
        />
      </IconButton>
    </Row>
  );
};

export default PatientRow;
