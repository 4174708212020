import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Box, Typography, Paper, TextField, Button, CircularProgress, Tooltip } from '@mui/material';
import { FrontendText, FrontendUser, TextThreadMessage, WaitlistTextStates } from '../../types';
import { formatIsoToCustomDateStringWithEEEEHHMMA, statusColors, statusIcons, textStateColors, textStateIcons } from '../../utils';
import { StatusMessage, StatusMessageVariantOne, StyledToggleButton, StyledToggleButtonVariantOne } from '../styles';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import { Colors } from '../../Colors';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useParams } from 'react-router-dom';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import AddIcon from '@mui/icons-material/Add';
import { usePostHog } from 'posthog-js/react';

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 100%;
  background-color: #f9f9f9;
  border-radius: 8px;
  overflow: hidden;
`;

const TextThreadContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 16px;
`;

const TextMetadata = styled(Typography)`
  font-size: 12px;
  color: #666;
  text-align: right;
`;

interface TextMessageProps {
  role: "assistant" | "patient";
}

const TextMessage = styled(({ role, ...otherProps }: TextMessageProps & React.ComponentProps<typeof Paper>) => (
  <Paper {...otherProps} />
))`
  margin-bottom: 16px;
  margin-left: 10px;
  padding: 16px;
  background-color: ${(props: any) => (props.role === 'assistant' ? '#e0f7fa' : '#ffebee')};
`;

const InputContainer = styled(Box)`
  display: flex;
  padding: 16px;
  background-color: #fff;
  border-top: 1px solid #ddd;
`;


const StyledButton = styled(Button)`
  max-height: 60px;
  background-color: #007bff;
  color: #fff;
  margin-left: 8px;
  &:hover {
    background-color: #0056b3;
  }
  &:active {
    background-color: #004494;
  }
  &:disabled {
    background-color: #d6d6d6;
    color: #a0a0a0;
  }
`;

interface TextThreadDisplayProps {
  frontendText: FrontendText;
}

const TextThreadDisplay: React.FC<TextThreadDisplayProps> = ({ frontendText }) => {
  const [frontendTextState, setFrontendTextState] = useState(frontendText);
  const { waitlistPatientId } = useParams<{ waitlistPatientId: string }>();
  const [newMessage, setNewMessage] = useState('');
  const [automatedTextsEnabled, setAutomatedTextsEnabled] = useState<boolean | undefined>(true);
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const { showMessage } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [firstIndexConfirmed, setFirstIndexConfirmed] = useState<number | null>(null);
  const [firstIndexDeclined, setFirstIndexDeclined] = useState<number | null>(null);
  const [firstManualOverrideIndex, setFirstManualOverrideIndex] = useState<number | null>(null);
  const lastFetchTimeRef = useRef<number>(0);
  const posthog = usePostHog();
  
  useEffect(() => {
    handleGetAutomatedTextStatus();
    handleGetUpdatedText();

    handleUpdateFirstConfirmedIndex();
    handleUpdateFirstDeclinedIndex();
    handleUpdateFirstManualOverrideIndex();
  }, []);

  useEffect(() => {
    handleUpdateFirstConfirmedIndex();
    handleUpdateFirstDeclinedIndex();
    handleUpdateFirstManualOverrideIndex();
  }, [frontendTextState.thread]);

  const handleUpdateFirstConfirmedIndex = () => {
    const firstConfirmedIndex = frontendTextState.thread.findIndex(
      (message: TextThreadMessage) => message.state === WaitlistTextStates.accepted
    );

    if (firstConfirmedIndex !== -1) {
      setFirstIndexConfirmed(firstConfirmedIndex);
    }
  };

  const handleUpdateFirstManualOverrideIndex= () => {
    const firstManualOverrideIndex = frontendTextState.thread.findIndex(
      (message: TextThreadMessage) => message.state === WaitlistTextStates.manualOverride
    );

    if (firstManualOverrideIndex !== -1) {
      setFirstManualOverrideIndex(firstManualOverrideIndex);
    }
  };

  const handleUpdateFirstDeclinedIndex = () => {
    const firstDeclinedIndex = frontendTextState.thread.findIndex(
      (message: TextThreadMessage) => message.state === WaitlistTextStates.declined
    );

    if (firstDeclinedIndex !== -1) {
      setFirstIndexDeclined(firstDeclinedIndex);
    }
  };

  const handleSendCustomText = async () => {
    if (!user?.id) { return };
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/text/custom/${waitlistPatientId}`, 
      {
        userId: user?.id,
        textMessage: newMessage
      },
      {
        headers: {
          'Authorization': `Bearer ${user?.token}`,
        }
      });

      if (response.status === 200) {
        showMessage('Text sent successfully', 'success');
      } else {
        throw new Error(`Failed to send custom text to waitlist patient: ${waitlistPatientId}`);
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        showMessage('Session has expired', 'error');
      } else {
        console.error('Error sending custom text:', error);
        // showMessage('Failed to send custom text', 'error');
      }
    }
  };

  const handleToggleActive = async () => {
    posthog?.capture('[SECRETARY] toggle_automated_texts', { waitlistPatientId: waitlistPatientId });
    await handleToggleAutomatedTexts();
  };

  const handleGetUpdatedText = async () => {
    if (!user?.id) { return };
    const now = Date.now();
    if (now - lastFetchTimeRef.current < 5000) {
      // console.log('Fetch operation is rate-limited. Please wait.');
      return;
    }
    lastFetchTimeRef.current = now;
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/texts/${frontendText.textId}`, 
      {
        headers: {
          'Authorization': `Bearer ${user?.token}`,
        }
      });

      if (response.status === 200) {
        setFrontendTextState(response.data);
      } else {
        throw new Error(`Failed to get updated text metadata: ${waitlistPatientId}`);
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        showMessage('Session has expired', 'error');
      } else {
        console.error('Error getting updated text metadata:', error);
        // showMessage('Failed to get text metadata. Please try again.', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGetAutomatedTextStatus = async () => {
    if (!user?.id) { return };
    const now = Date.now();
    if (now - lastFetchTimeRef.current < 5000) {
      console.log('Fetch operation is rate-limited. Please wait.');
      return;
    }
    lastFetchTimeRef.current = now;
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/waitlistPatient/${waitlistPatientId}/automated-texts`, 
      {
        headers: {
          'Authorization': `Bearer ${user?.token}`,
        }
      });

      if (response.status === 200) {
        setAutomatedTextsEnabled(response.data);
      } else {
        throw new Error(`Failed to get automated text enabled status: ${waitlistPatientId}`);
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        showMessage('Session has expired', 'error');
      } else {
        console.error('Error getting automatic texts status:', error);
        // showMessage('Failed to get automatic text status. Please try again.', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleToggleAutomatedTexts = async ( toggleAutomatedTextsTo?: boolean) => {
    if (!user?.id) { return };
    try {
      setLoading(true);
      const reqBody = toggleAutomatedTextsTo === undefined ? {} : { automatedTextingEnabled: toggleAutomatedTextsTo };
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/waitlistPatient/${waitlistPatientId}/toggle-automated-texts`, 
      reqBody,
      {
        headers: {
          'Authorization': `Bearer ${user?.token}`,
        }
      });

      if (response.status === 200) {
        setAutomatedTextsEnabled(response.data);
        showMessage('Successfully toggled automated texts.', 'success');
      } else {
        throw new Error(`Failed to toggle automated texts: ${waitlistPatientId}`);
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        showMessage('Session has expired', 'error');
      } else {
        console.error('Error toggling automatic texts:', error);
        // showMessage('Failed to toggle automatic texts. Please try again.', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSendNewMessage = async () => {
    if (newMessage.trim() === '') return;
    setNewMessage('');

    // auto toggle to AI off
    if (automatedTextsEnabled === true) {
      await handleToggleAutomatedTexts(false);
      showMessage('Automated texts disabled to send custom text');
    }

    posthog?.capture('[SECRETARY] send_custom_text', { waitlistPatientId: waitlistPatientId });
    await handleSendCustomText();

    await handleGetUpdatedText();
  };
  
  return (
    <>
      <Container>
        <TextThreadContainer>
          {frontendTextState.thread.map((message: TextThreadMessage, index) => (
            <Box key={index} display="flex" alignItems="center">
              {/* The first accepted icon */}
              { message.state && message.stateJustification && (firstIndexConfirmed === index && message.state === WaitlistTextStates.accepted) && 
                <Tooltip title={message.stateJustification}>
                  <Box mr={1} color={textStateColors[message.state]}>
                    {textStateIcons[message.state]}
                  </Box>
                </Tooltip>
              }
              { message.state && message.stateJustification && (firstIndexDeclined === index && message.state === WaitlistTextStates.declined) && 
                <Tooltip title={message.stateJustification}>
                  <Box mr={1} color={textStateColors[message.state]}>
                    {textStateIcons[message.state]}
                  </Box>
                </Tooltip>
              }
              { message.state && message.stateJustification && (firstIndexDeclined !== index && message.state === WaitlistTextStates.declined) && 
                <Tooltip title={message.stateJustification}>
                  <Box mr={1} color={textStateColors[message.state]}>
                    <Box width={24} height={24} />
                  </Box>
                </Tooltip>
              }
              { !(message.state && message.stateJustification) && 
                <Tooltip title="State hasn't been updated yet">
                  <Box mr={1} color={Colors.grey1}>
                    <Box width={24} height={24} />
                  </Box>
                </Tooltip>
              }
              { (message.state && message.state !== WaitlistTextStates.accepted && message.state !== WaitlistTextStates.declined) && 
                <Tooltip title="State hasn't been updated yet">
                  <Box mr={1} color={Colors.grey1}>
                    <Box width={24} height={24} />
                  </Box>
                </Tooltip>
              }
              { message.state && message.stateJustification && message.state === WaitlistTextStates.accepted && firstIndexConfirmed !== index && 
                <Tooltip title="After confirmation message">
                  <Box mr={1} color={Colors.grey1}>
                    <Box width={24} height={24} />
                  </Box>
                </Tooltip>
              }
              { message.state && message.stateJustification && message.state === WaitlistTextStates.manualOverride && (
                <Tooltip title="Switched to manual override">
                  <Box mr={1} color={statusColors[message.state]}>
                    <React.Fragment>{statusIcons[message.state]}</React.Fragment>
                  </Box>
                </Tooltip>
              )}
              <TextMessage role={message.role}>
                <Typography variant="body1">{message.message}</Typography>
                <TextMetadata variant="caption">{`${formatIsoToCustomDateStringWithEEEEHHMMA(message.date)} - ${message.role}`}</TextMetadata>
              </TextMessage>
            </Box>
          ))}
        </TextThreadContainer>
        <InputContainer>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Type a message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                posthog?.capture('[SECRETARY] clicked_send_custom_text_via_enter_button', { waitlistPatientId: waitlistPatientId });
                handleSendNewMessage();
              }
            }}
            sx={{ marginRight: 2 }}
          />
          <StyledButton variant="contained" onClick={() => {
            posthog?.capture('[SECRETARY] clicked_send_custom_text_via_mouse_click', { waitlistPatientId: waitlistPatientId });
            handleSendNewMessage();
          }}>
            Send
          </StyledButton>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 2 }}>
            <StyledToggleButtonVariantOne
              value="toggle"
              selected={automatedTextsEnabled}
              onChange={handleToggleActive}
            >
              {automatedTextsEnabled === true ? <ToggleOnIcon style={{ color: Colors.confirmedColor1 }} /> : <ToggleOffIcon style={{ color: Colors.otherColor1 }} />}
              <StatusMessageVariantOne>
                {automatedTextsEnabled === true ? 'AI texts enabled' : 'AI texts disabled'}
              </StatusMessageVariantOne>
            </StyledToggleButtonVariantOne>
            { loading || (automatedTextsEnabled === undefined) && <CircularProgress />}
          </Box>
        </InputContainer>
      </Container>
    </>
  );
};

export default TextThreadDisplay;
