import React, { useCallback } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css'; // Ensure you import the necessary CSS

const PhoneNumberField: React.FC<{ phoneNumber: string; setPhoneNumber: (phone: string) => void }> = ({ phoneNumber, setPhoneNumber }) => {

  const handlePhoneChange = useCallback((value: string | undefined) => {
    setPhoneNumber(value || '');
  }, [setPhoneNumber]);

  const renderInputComponent = useCallback(
    React.forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => (
      <TextField
        {...props}
        inputRef={ref}
        fullWidth
        label="Patient phone number"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        sx={{ mb: 2 }}
      />
    )),
    [] // empty dependency array ensures the function is memoized once
  );

  return (
    <PhoneInput
      value={phoneNumber}
      onChange={handlePhoneChange}
      inputComponent={renderInputComponent}
      // No need for country or international props, this will just format the number as inputted
    />
  );
};

export default PhoneNumberField;
