// src/components/ResetPassword.tsx
import React, { useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { userPool } from '../auth/cognitoConfig';
import { Box, Button, TextField, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import { useSnackbar } from '../providers/SnackbarProvider';

const ResetPassword: React.FC = () => {
  const [step, setStep] = useState(1); // 1: Enter Email, 2: Enter Code and New Password, 3: Success Message
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { showMessage } = useSnackbar();

  const sendVerificationCode = () => {
    var userData = {
      Username: email,
      Pool: userPool,
    };
    var cognitoUser = new CognitoUser(userData);
    cognitoUser.forgotPassword({
      onSuccess: (data) => {
        console.log('Code sent to email:', data);
        showMessage("Verification code sent to your email", 'success');
        setStep(2); // Move to enter verification code and new password
      },
      onFailure: (err) => {
        alert(err.message || JSON.stringify(err));
      },
    });
  };

  const resetPassword = () => {
    var userData = {
      Username: email,
      Pool: userPool,
    };
    var cognitoUser = new CognitoUser(userData);
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => {
        console.log('Password reset successful');
        setStep(3); // Move to success message
      },
      onFailure: (err) => {
        alert(err.message || JSON.stringify(err));
      },
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (step === 1) {
      posthog?.capture('password_reset_button_clicked_send_code');
      sendVerificationCode();
    } else if (step === 2) {
      posthog?.capture('password_reset_button_clicked_reset_password_with_code');
      resetPassword();
    }
  };

  return (
    <Box boxShadow={3} sx={{ p: 4, maxWidth: 400, mx: 'auto', mt: 10 }}>
      {step !== 3 ? (
        <>
          <Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>
            {step === 1 ? 'Reset Password' : 'Set New Password'}
          </Typography>
          <form onSubmit={handleSubmit}>
            {step === 1 ? (
              <TextField
                fullWidth
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                margin="normal"
                required
              />
            ) : (
              <>
                <TextField
                  fullWidth
                  label="Verification Code"
                  type="text"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  required
                />
                <TextField
                  fullWidth
                  label="New Password"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  required
                />
              </>
            )}
            <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3 }}>
              {step === 1 ? 'Send Code' : 'Reset Password'}
            </Button>
          </form>
        </>
      ) : (
        <>
          <Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>
            Password Reset Successful!
          </Typography>
          <Typography sx={{ mb: 2, textAlign: 'center' }}>
            Your password has been successfully reset. You can now use your new password to log in.
          </Typography>
          <Button variant="contained" color="primary" fullWidth onClick={() => {
            posthog?.capture('password_successfully_reset_go_to_login_button_clicked');
            navigate('/login')}
          }>
            Go to Login
          </Button>
        </>
      )}
    </Box>
  );
};

export default ResetPassword;
