import { CallOutcomes, CallStatusesUi, OpenWaitlistAppointment, WaitlistCallStates, WaitlistOutcomes, WaitlistPatientStatus, WaitlistStatus, WaitlistTextStates, FrontendWaitlistPatient, ShiftOptions, Choices } from "./types";
import Papa from 'papaparse';
import { DateTime } from 'luxon';
import MessageIcon from '@mui/icons-material/Message';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { Box } from "@mui/material";
import { Colors } from "./Colors";
import LoopIcon from '@mui/icons-material/Loop';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import BuildIcon from '@mui/icons-material/Build';

const confirmedColor = "#53C43B"; // Green
const otherColor = "#BC4A43"; // Red
const intermediateColor = "#F9D71C"; // Yellow

export const chooseColorBasedOnOutcome = (outcome: string) => {
  switch (outcome) {
    case CallOutcomes.confirmed:
    case CallOutcomes.scheduled:
    case CallOutcomes.rescheduled:
    case CallOutcomes.voicemail:
    case CallOutcomes.accepted:
      return confirmedColor;
    case CallOutcomes.requested_callback:
    case CallOutcomes.transferred_to_human:
    case CallOutcomes.unsuccessful:
      return otherColor;
    default:
      return otherColor;
  }
};

export const chooseColorBasedOnWaitlistRunOutcome = (outcome: string) => {
  switch (outcome) {
    case WaitlistOutcomes.scheduled:
      return confirmedColor;
    case CallOutcomes.unsuccessful:
      return otherColor;
    default:
      return otherColor;
  }
};

export const chooseColorBasedOnWaitlistRunStatus = (outcome: string) => {
  switch (outcome) {
    case WaitlistStatus.completed:
      return confirmedColor;
    case WaitlistStatus.started:
      return intermediateColor;
    case WaitlistStatus.notStarted:
      return otherColor;
    default:
      return otherColor;
  }
};

export const chooseColorBasedOnStatus = (status: string) => {
  switch (status) {
    case CallStatusesUi.complete:
      return confirmedColor;
    default:
      return otherColor;
  }
};

/**
 * Generates a CryptoKey object from a PEM-formatted public key.
 * 
 * @param publicKeyPem - The PEM-formatted public key
 * @returns - The CryptoKey object
 */
export async function importPublicKey(publicKeyPem: string): Promise<CryptoKey> {
  if (!publicKeyPem) { 
    throw new Error('Public key not found');
  }

  // Correctly replace PEM header, footer, and newlines
  const base64String = publicKeyPem
    .replace(/-----BEGIN PUBLIC KEY-----/g, '')
    .replace(/-----END PUBLIC KEY-----/g, '')
    .replace(/\s+/g, ''); // Removes all whitespace including newlines

  if (!base64String) {
    throw new Error('Base64 string not found after replacements');
  }

  // Decode the base64 string to binary data
  const binaryDerString = window.atob(base64String);

  const binaryDer = new Uint8Array(binaryDerString.length);
  for (let i = 0; i < binaryDerString.length; i++) {
    binaryDer[i] = binaryDerString.charCodeAt(i);
  }

  return await window.crypto.subtle.importKey(
    "spki",
    binaryDer.buffer,
    {name: "RSA-OAEP", hash: "SHA-256"},
    true,
    ["encrypt"]
  );
}

/**
 * Given a public key `publicKey` and data `data`, encrypts the data using the public key.
 * 
 * @param publicKey - The public key to use for encryption
 * @param data - The data to encrypt
 * @returns - The encrypted data
 */
export async function encryptData(publicKey: CryptoKey, data: string): Promise<string> {
  const encoded = new TextEncoder().encode(data);
  const encrypted = await window.crypto.subtle.encrypt(
    {name: "RSA-OAEP"},
    publicKey,
    encoded
  );
  return window.btoa(String.fromCharCode(...new Uint8Array(encrypted)));
}

/**
 * Encrypts the given data `dataToEncrypt` using the public key stored in the environment variable `REACT_APP_PUBLIC_ENCRYPTION_KEY`.
 * 
 * @param dataToEncrypt - The data to encrypt
 */
export async function encryptTokenWithPublicKey(dataToEncrypt: string): Promise<string>{
  const publicKeyPem = process.env.REACT_APP_PUBLIC_ENCRYPTION_KEY?.replace(/\\n/g, '\n');

  try {
    if (!publicKeyPem) {
      console.error("Public key not found");
      throw new Error("Public key not found");
    }

    const publicKey: CryptoKey = await importPublicKey(publicKeyPem);
    return await encryptData(publicKey, dataToEncrypt);
  } catch (error: any) {
    console.error("Encryption error:", error);
    throw error;
  }
}

export const handleCSVUploadLocally = async (event: React.ChangeEvent<HTMLInputElement>): Promise<any> => {
  try {
    const file = event.target.files?.[0];
    if (file) {
      const cleanData = await readFileAsText(file);
      return cleanData;
    }
    throw new Error('No file found');
  } catch (error: any) {
    console.error('CSV upload error:', error);
  }
};

const readFileAsText = (file: File): Promise<any> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const csvString = event.target?.result as string;
      const parsedCsv = Papa.parse(csvString, { header: true, skipEmptyLines: true });
      const cleanData = parsedCsv.data.map((row: any) => {
        return Object.fromEntries(Object.entries(row).map(([key, value]) => [key, value]));
      });
      resolve(cleanData);
    };
    reader.onerror = () => {
      reject(new Error('Failed to read file'));
    };
    reader.readAsText(file);
  });
};


/**
 * Takes in a timezone-aware ISO 8601 date string `isoString` and returns a formatted date string in the format 'M/d/yy'.
 * 
 * If the input input is invalid, throw an error.
 * 
 * @param isoString - The timezone-aware ISO 8601 date string eg 2024-05-20T11:30:12.000-07:00
 * @returns The formatted date string in the format 'M/d/yy'
 * @throws An error if the input ISO string is invalid
 */
export function formatIsoToCustomDateString(isoString: string): string {
  const dateTime = DateTime.fromISO(isoString, { setZone: true }); // Assuming the input ISO string is in UTC
  if (!dateTime.isValid) {
    throw new Error('Invalid ISO 8601 date string');
  }
  return dateTime.toFormat('M/d/yy');
};

export const statusColors = {
  [WaitlistPatientStatus.expired]: "#757575",
  [WaitlistPatientStatus.confirmed]: "#4CAF50",
  [WaitlistPatientStatus.notContacted]: "#757575",
  [WaitlistPatientStatus.noResponse]: "#757575",
  [WaitlistPatientStatus.declined]: "#F44336",
  [WaitlistPatientStatus.inProgress]: "#90CAF9",
  [WaitlistPatientStatus.lateToConfirm]: "#757575",
  [WaitlistPatientStatus.unsuccessful]: "#757575",
  [WaitlistPatientStatus.initialContact]: "#757575",
  [WaitlistTextStates.manualOverride]: "#FF9800",
};

export const statusIcons: Record<WaitlistPatientStatus, React.ReactNode> = {
  [WaitlistPatientStatus.notContacted]: <Box width={24} height={24} />,
  [WaitlistPatientStatus.declined]: <EventBusyIcon />,
  [WaitlistPatientStatus.confirmed]: <EventAvailableIcon />  ,
  [WaitlistPatientStatus.inProgress]: <LoopIcon />,
  [WaitlistPatientStatus.initialContact]: <SendIcon />,
  [WaitlistPatientStatus.noResponse]: <Box width={24} height={24} />,
  [WaitlistPatientStatus.expired]: <CloseIcon />,
  [WaitlistPatientStatus.lateToConfirm]: <CloseIcon />,
  [WaitlistPatientStatus.unsuccessful]: <CloseIcon />,
  [WaitlistTextStates.manualOverride]: <BuildIcon />,
};

export const callOutcomeIcons: Record<CallOutcomes, React.ReactNode> = {
  [CallOutcomes.accepted]: <Box width={24} height={24} />,
  [CallOutcomes.confirmed]: <EventAvailableIcon />,
  [CallOutcomes.declined]: <EventBusyIcon />,
  [CallOutcomes.requested_callback]: <Box width={24} height={24} />,
  [CallOutcomes.scheduled]: <Box width={24} height={24} />,
  [CallOutcomes.transferred_to_human]: <Box width={24} height={24} />,
  [CallOutcomes.unsuccessful]: <Box width={24} height={24} />,
  [CallOutcomes.voicemail]: <Box width={24} height={24} />,
  [CallOutcomes.rescheduled]: <Box width={24} height={24} />,
};

export const statusMessages: Record<WaitlistPatientStatus, string> = {
  [WaitlistPatientStatus.notContacted]: 'Not Contacted',
  [WaitlistPatientStatus.declined]: 'Declined',
  [WaitlistPatientStatus.confirmed]: 'Confirmed',
  [WaitlistPatientStatus.inProgress]: 'In Progress',
  [WaitlistPatientStatus.noResponse]: 'No Response',
  [WaitlistPatientStatus.expired]: 'Expired',
  [WaitlistPatientStatus.lateToConfirm]: 'Late to confirm',
  [WaitlistPatientStatus.unsuccessful]: 'Unsuccessful',
  [WaitlistPatientStatus.initialContact]: 'Initial Contact',
  [WaitlistTextStates.manualOverride]: "Switched to manual",
};

export const callOutcomeMessages: Record<CallOutcomes, string> = {
  [CallOutcomes.accepted]: 'Accepted',
  [CallOutcomes.confirmed]: 'Confirmed',
  [CallOutcomes.declined]: 'Declined',
  [CallOutcomes.requested_callback]: 'Requested Callback',
  [CallOutcomes.scheduled]: 'Scheduled',
  [CallOutcomes.transferred_to_human]: 'Transferred to Human',
  [CallOutcomes.unsuccessful]: 'Unsuccessful',
  [CallOutcomes.voicemail]: 'Voicemail',
  [CallOutcomes.rescheduled]: 'Rescheduled',
};

export const textStateIcons: Record<WaitlistTextStates, React.ReactNode> = {
  [WaitlistTextStates.firstMessageSent]: <Box width={24} height={24} />,
  [WaitlistTextStates.inProgress]: <LoopIcon />,
  [WaitlistTextStates.accepted]: <EventAvailableIcon />,
  [WaitlistTextStates.declined]: <EventBusyIcon />,
  [WaitlistTextStates.notContacted]: <DoNotDisturbIcon />,
  [WaitlistTextStates.expired]: <Box width={24} height={24} />,
  [WaitlistTextStates.manualOverride]: <BuildIcon />,
};

// Define the colors for each state
export const textStateColors: Record<WaitlistTextStates, string> = {
  [WaitlistTextStates.firstMessageSent]: '#4CAF50',
  [WaitlistTextStates.inProgress]: '#757575',
  [WaitlistTextStates.accepted]: '#4CAF50',
  [WaitlistTextStates.declined]: '#F44336',
  [WaitlistTextStates.notContacted]: '#757575',
  [WaitlistTextStates.expired]: '#757575',
  [WaitlistTextStates.manualOverride]: '#FF9800',
};

/**
 * Takes in a timezone-aware ISO 8601 date string `isoString` and returns a formatted date string in the format 'M/d/yy'.
 * 
 * If the input input is invalid, throw an error.
 * 
 * @param isoString - The timezone-aware ISO 8601 date string eg 2024-05-20T11:30:12.000-07:00
 * @returns The formatted date string in the format 'Monday, M/d/yy at 4:30 PM'
 * @throws An error if the input ISO string is invalid
 */
export function formatIsoToCustomDateStringWithEEEEHHMMA(isoString: string): string {
  const dateTime = DateTime.fromISO(isoString, {setZone: true }); // Assuming the input ISO string is in UTC
  if (!dateTime.isValid) {
    throw new Error('Invalid ISO 8601 date string');
  }
  return dateTime.toFormat('EEEE, M/d/yy \'at\' h:mm a');
};

/**
 * Takes in a timezone-aware ISO 8601 date string `isoString` and returns a formatted date string in the format 'M/d/yy'.
 * 
 * If the input input is invalid, throw an error.
 * 
 * @param isoString - The timezone-aware ISO 8601 date string eg 2024-05-20T11:30:12.000-07:00
 * @returns The formatted date string in the format 'Monday, M/d/yy at 4:30 PM'
 * @throws An error if the input ISO string is invalid
 */
export function formatIsoToCustomDateStringWithMD(isoString: string): string {
  const dateTime = DateTime.fromISO(isoString, {setZone: true }); // Assuming the input ISO string is in UTC
  if (!dateTime.isValid) {
    throw new Error('Invalid ISO 8601 date string');
  }
  return dateTime.toFormat('M/d');
};

/**
 * Converts a phone number into E.164 format, which consists of a + followed by the country code and subscriber number. 
 * 
 * 
 * Accepts any format of phone number listed:
 * `+1xxxxxxxxxx`, (xxx) xxx-xxxx, xxx-xxx-xxxx, xxxxxxxxxx, and returns `+1xxxxxxxxxx`.
 * 
 * https://www.twilio.com/docs/glossary/what-e164
 * 
 * @param phoneNumber - The phone number to convert
 * @param countryCode - The country code to prepend to the phone number
 * @returns The phone number in the format '+[countryCode][phoneNumber]'
 */
export function convertToCallablePhoneNumber(phoneNumber: string, countryCode: string = "1"): string {
  if (!phoneNumber) {
    throw new Error('Invalid phone number');
  }

  // Remove all non-numeric characters except the leading plus sign if present
  const cleanedPhoneNumber = phoneNumber.replace(/(?!^\+)\D/g, '');

  // Check if the phone number is already in the format `+1xxxxxxxxxx`
  if (cleanedPhoneNumber.startsWith(`+${countryCode}`) && cleanedPhoneNumber.length === countryCode.length + 11) {
    return cleanedPhoneNumber;
  }

  // Ensure it returns a phone number in the format `+1xxxxxxxxxx`
  return `+${countryCode}${cleanedPhoneNumber.replace(/^\+/, '')}`;
};

/**
 * Takes in a timezone-aware ISO 8601 date string `isoString` and returns a formatted date string in the format 'Monday, M/d/yy'.
 * 
 * If the input is invalid, throw an error.
 * 
 * @param isoString - The timezone-aware ISO 8601 date string eg 2024-05-20T11:30:12.000-07:00
 * @returns The formatted date string in the format 'Monday, M/d/yy'
 * @throws An error if the input ISO string is invalid
 */
export function formatIsoToCustomDateStringWithEEEE(isoString: string): string {
  try {
    // Parse the ISO string with timezone
    const dateTime = DateTime.fromISO(isoString, { setZone: true });

    // Check if the DateTime object is valid
    if (!dateTime.isValid) {
      // throw new Error('Invalid ISO 8601 date string');
      return isoString;
    }

    // Extract the timezone from the input ISO string
    const timezone = dateTime.zoneName;

    // Set the DateTime object to the extracted timezone
    const dateTimeWithZone = dateTime.setZone(timezone);

    // Return the formatted date string
    return dateTimeWithZone.toFormat('EEEE, M/d/yy');
  } catch (error: any) {
    console.error("formatIsoToCustomDateStringWithEEEE Error: ", error);
    throw error; // Rethrow the error to ensure it propagates correctly
  }
};

/**
 * Checks if a provided ISO date string matches any date in an array of ISO date strings. The comparison is done in the timezone `timezone`.
 * 
 * @param isoDates Array of timezone-aware ISO date strings.
 * @param inputDate ISO date string to check against the array.
 * @returns Boolean indicating if the input date matches any date in the array (ignores time).
 */
export const isDateInArray = (isoDates: string[], inputDate: string, timezone: string): boolean => {
  const inputDateTime = DateTime.fromISO(inputDate, { zone: timezone });
  if (!inputDateTime.isValid) {
    console.error("Invalid input date.");
    return false;
  }

  const timezoneAwareDates: string[] = isoDates.map((isoDate: string) => {
    const dateTime = DateTime.fromISO(isoDate, { zone: timezone });
    return dateTime.isValid ? dateTime.toISO() : null;
  }).filter(isoDate => isoDate !== null) as string[];

  return timezoneAwareDates.some(isoDate => {
    const arrayDateTime = DateTime.fromISO(isoDate);
    return inputDateTime.hasSame(arrayDateTime, 'day');
  });
};

export enum WebsocketEvents {
  WaitlistRuns = 'waitlistRuns',
  WaitlistPatients = 'waitlistPatients',
  OpenWaitlistAppointments = 'openWaitlistAppointments',
  TakenWaitlistAppointments = 'takenWaitlistAppointments',
  Calls = 'calls',
  Texts = 'texts',
  AlternateWaitlistPatients = 'alternateWaitlistPatients',
  DeletedTakenWaitlistAppointments = 'deletedTakenWaitlistAppointments',
};

/**
 * Determines if a patient should be filtered based on the appointment and patient data.
 * 
 * @param patient - The patient to filter
 * @param appointment - The appointment to filter against
 * @param timezone - The timezone to use for date comparisons
 * @returns Boolean indicating if the patient should be filtered
 */
export const filterPatient = (
  patient: FrontendWaitlistPatient,
  appointment: OpenWaitlistAppointment | null,
  timezone: string
) => {
  if (!appointment) return false;

  const isShiftCompatible = (appointment.shift === ShiftOptions.anyShift) ||
                            (patient.prefersShift === ShiftOptions.anyShift) ||
                            (appointment.shift === patient.prefersShift);

  const filterResult: boolean = Number(patient.appointmentLength) <= Number(appointment?.appointmentLength) &&
  (patient.pcpName.includes(appointment?.pcpName) || patient.pcpName === Choices.noPreference || patient.pcpName.trim().toLowerCase() === "any" || patient.pcpName.trim().toLowerCase() === "any pt") &&
    isDateInArray(patient?.dates, appointment.appointmentDate, timezone) &&
    isShiftCompatible;

  return filterResult;
};
