// src/components/WaitlistRunStatus.tsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh';
import { CircularProgress, Typography } from '@mui/material';
import { HeaderContainer, RefreshButton, Title } from '../styles';
import ScheduledCallRow from '../row/ScheduledCallRow';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useParams } from 'react-router-dom';
import { AssistantMetadata, ScheduledCall, WaitlistActions, WaitlistRun } from '../../types';
import { FrontendUser } from '../../types';
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { selectAssistants } from '../../slices/assistantsSlice';
import WaitlistRunRow from '../row/WaitlistRunRow';
import { sessionExpired as sessionExpiredGlobally } from '../../slices/sessionSlice';
import { fetchWaitlistRuns, selectWaitlistRuns } from '../../slices/waitlistRunsSlice';
import { get } from 'http';

const WaitlistRuns: React.FC = () => {
  const { assistantId } = useParams<{ assistantId: string }>();
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const assistants = useSelector(selectAssistants);
  const [assistant, setAssistant] = useState<AssistantMetadata | null>(assistants.find((assistant: AssistantMetadata) => assistant.assistantId === assistantId) || null);
  const waitlistRuns = useSelector(selectWaitlistRuns);
  const [loading, setLoading] = useState(false);
  const { showMessage } = useSnackbar();
  const sessionExpired = useSelector((state: RootState) => state.session.sessionExpired);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!assistant) {
      setAssistant(assistants.find((assistant: AssistantMetadata) => assistant.assistantId === assistantId) || null);
    }
  }, [user, assistants.length]);

  useEffect(() => {
    getData();
  }, [assistant, user?.token]); // Depend on assistant and user.token to refetch when they change

  const getData = async () => {
    getAssistantWaitlistRuns();
  };

  useEffect(() => {
    if (!user?.id) { return };
    if (!user?.token) { return };

    const connectWebSocket = async () => {
      try {
        const socket = new WebSocket(`wss://${process.env.REACT_APP_BACKEND_URL_NO_HTTPS}/api/subscribe/${user.id}?token=${encodeURIComponent(user.token!)}`);

        socket.onopen = () => {
          console.log('Connected to WebSocket');
          socket.send(JSON.stringify({ action: 'subscribe', type: "waitlistRuns" }));
        };

        socket.onmessage = (event: any) => {
          const data = JSON.parse(event.data);
          getData();
        };

        socket.onclose = () => {
          console.log('Disconnected from WebSocket');
        };

        socket.onerror = (error) => {
          console.error('WebSocket error:', error);
          // showMessage('WebSocket error. Please try again.', 'error');
        };

        return () => {
          socket.close();
        };
      } catch (error: any) {
        if (error.response?.status === 401) {
          showMessage('Session has expired', 'error');
        } else {
          console.error('Error connecting to WebSocket:', error);
          // showMessage('Please refresh the page.', 'error');
        }
      }
    };

    connectWebSocket();
  }, []);

  const fetchGlobalWaitlistRuns = async () => {
    if (!assistant?.assistantId) { return };
    if (user && user.id && user.token) {
      try {
        await dispatch(fetchWaitlistRuns({ userId: user.id, token: user.token, assistantId: assistant?.assistantId }) as any);
      } catch (error: any) {
        if (error.response?.status === 401) {
          showMessage('Session has expired', 'error');
          dispatch(sessionExpiredGlobally(true) as any);
        }
        console.error('Failed to fetch calls:', error.message);
      }
    }
  };

  const getAssistantWaitlistRuns = async () => {
    setLoading(true);
    if (!user?.id) { 
      showMessage('Failed to retrieve waitlist runs. Please refresh the page and try again.', 'error');
      setLoading(false);
      return;
    };
    await fetchGlobalWaitlistRuns();
    setLoading(false);
  };

  const handleDeleteWaitlistRun = async (waitlistRunId: string) => {
    if (!user?.id) { return };
    try { 
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/${assistant?.assistantId}/waitlist/${waitlistRunId}/${WaitlistActions.deleteRun}`, 
      {
        userId: user?.id,
      },
      {
        headers: {
          'Authorization': `Bearer ${user?.token}`,
        }
      });

      if (response.status === 200) {
        showMessage('Waitlist run deleted successfully.', 'success');
        dispatch({ type: 'UPDATE_WAITLIST_RUNS', payload: waitlistRuns.filter((run: WaitlistRun) => run.waitlistRunId !== waitlistRunId) } as any);
        getAssistantWaitlistRuns();
      } else {
        throw new Error(`Failed to delete the waitlist run with id: ${waitlistRunId}`);
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        showMessage('Session has expired', 'error');
        dispatch(sessionExpiredGlobally(true) as any);
      } else {
        console.error('Error deleting waitlist run:', error);
        // showMessage('Failed to delete waitlist run. Please try again.', 'error');
      }
    }
  };

  return (
    <>
      {assistant ? (
        <>
          <HeaderContainer>
            <Title>{assistant.name || "Assistant"}'s Runs</Title>
            <RefreshButton onClick={getAssistantWaitlistRuns}>
              <RefreshIcon />
            </RefreshButton>
          </HeaderContainer>
          {loading && <CircularProgress />}
          {!loading && waitlistRuns.length > 0 ? (
            waitlistRuns.map((waitlistRun: WaitlistRun, index: number) => (
              <WaitlistRunRow
                key={index}
                waitlistRun={waitlistRun}
                assistant={assistant}
                onDelete={() => handleDeleteWaitlistRun(waitlistRun.waitlistRunId)}
              />
            ))
          ) : (
            <Typography>No waitlist runs to display.</Typography>
          )}
        </>
      ) : null}
    </>
  );  
};

export default WaitlistRuns;
