// src/components/Queue.tsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh';
import { CircularProgress, Typography } from '@mui/material';
import { HeaderContainer, RefreshButton, Title } from '../styles';
import ScheduledCallRow from '../row/ScheduledCallRow';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useParams } from 'react-router-dom';
import { AssistantMetadata, ScheduledCall } from '../../types';
import { FrontendUser } from '../../types';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { selectAssistants } from '../../slices/assistantsSlice';

const Queue: React.FC = () => {
  const { assistantId } = useParams<{ assistantId: string }>();
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const assistants = useSelector(selectAssistants);
  const [assistant, setAssistant] = useState<AssistantMetadata | null>(assistants.find((assistant: AssistantMetadata) => assistant.assistantId === assistantId) || null);
  const [scheduledCalls, setScheduledCalls] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showMessage } = useSnackbar();

  useEffect(() => {
    if (!assistant) {
      setAssistant(assistants.find((assistant: AssistantMetadata) => assistant.assistantId === assistantId) || null);
    }
  }, [user, assistants.length]);

  useEffect(() => {
    getAssistantQueue();
  }, [assistant, user?.token]); // Depend on assistant and user.token to refetch when they change

  const getAssistantQueue = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/assistants/${assistant?.assistantId}/queue`,
        {
          params: {
            userId: user?.id,
          },
          headers: {
            'Authorization': `Bearer ${user?.token}`,
          }
        }
      );

      if (response.status === 200) {
        setScheduledCalls(response.data);
      } else {
        throw new Error('Failed to fetch scheduled calls');
      }
    } catch (error) {
      console.error('Error getting scheduled calls:', error);
      // showMessage('Failed to retrieve scheduled calls. Please refresh the page and try again.', 'error');
    }
    setLoading(false);
  };

  const handleDeleteScheduledCall = async (scheduledCallId: string) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/${assistant?.assistantId}/queue/${scheduledCallId}`, {
        headers: {
          'Authorization': `Bearer ${user?.token}`,
        }
      });

      if (response.status === 200) {
        showMessage('Scheduled call deleted successfully.', 'success');
        setScheduledCalls(scheduledCalls.filter((call: ScheduledCall) => call?.scheduledCallId !== scheduledCallId));
      } else {
        throw new Error('Failed to delete the scheduled call');
      }
    } catch (error) {
      console.error('Error deleting scheduled call:', error);
      // showMessage('Failed to delete scheduled call. Please try again.', 'error');
    }
  };

  return (
    <>
      <HeaderContainer>
        <Title>{assistant?.name || "Assistant"}'s Queue</Title>
        <RefreshButton onClick={getAssistantQueue}>
          <RefreshIcon />
        </RefreshButton>
      </HeaderContainer>
      {loading && <CircularProgress />}
      {!loading && scheduledCalls.length > 0 ? (
        scheduledCalls.map((call: ScheduledCall, index: number) => (
          <ScheduledCallRow
            key={index}
            scheduledCall={call}
            assistant={assistant}
            handleDeleteScheduledCall={handleDeleteScheduledCall} // Passing the function here
          />
        ))
      ) : (
        <Typography>No scheduled calls to display.</Typography>
      )}
    </>
  );
};

export default Queue;
