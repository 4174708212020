import React, { useCallback } from 'react';
import { Modal, Box, Typography, Button, IconButton } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { usePostHog } from 'posthog-js/react';
import { FrontendUser } from '../../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface UploadCSVModalProps {
  open: boolean;
  onClose: () => void;
  onFileUpload: (file: File) => void;
}

const UploadCSVModal: React.FC<UploadCSVModalProps> = ({ open, onClose, onFileUpload }) => {
  const posthog = usePostHog();
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  
  // Handler for drag and drop
  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    posthog?.capture('[SECRETARY] dropped_csv_file', { userId: user?.id });
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      const file = files[0];
      onFileUpload(file);
    }
  }, [onFileUpload]);

  // Handler for file input change
  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    posthog?.capture('[SECRETARY] click_upload_csv_then_dragged_file', { userId: user?.id });
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      onFileUpload(file);
    }
  }, [onFileUpload]);

  // Prevent default behavior (Prevent file from being opened)
  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="upload-csv-modal-title">
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        width: 500,
        borderRadius: 2,
        textAlign: 'center'
      }}>
        <Typography id="upload-csv-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
          Upload CSV
        </Typography>
        <label htmlFor="file-input">
          <input
            style={{ display: 'none' }}
            id="file-input"
            type="file"
            accept=".csv"
            onChange={handleChange}
          />
          <Box
            sx={{
              border: '1px dashed grey',
              padding: 2,
              borderRadius: 2,
              cursor: 'pointer',
              ':hover': {
                borderColor: 'primary.main',
                backgroundColor: 'primary.lighter'
              }
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <IconButton component="span">
              <UploadFileIcon sx={{ fontSize: 60 }} />
            </IconButton>
            <Typography variant="body1">Click to upload or drag files here</Typography>
          </Box>
          <Button onClick={onClose} variant="outlined" sx={{ mt: 2 }}>Cancel</Button>
        </label>
      </Box>
    </Modal>
  );
};

export default UploadCSVModal;
