import React, { ElementType, useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { FrontendUser } from '../../types';
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { StyledCircularProgress, StyledDisconnectButton, StyledIntegrateButton } from '../styles';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { IntegrationCard, StyledFormBox, StyledProgressContainer } from '../styles';
import { sessionExpired } from '../../slices/sessionSlice';

interface IntegrationCardProps {
  Icon: ElementType;
  name: string;
  subtitle: string;
}

const CalendlyIntegrationCard: React.FC<IntegrationCardProps> = ({ Icon, name, subtitle }) => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [calendlyIsIntegrated, setCalendlyIsIntegrated] = useState(false);
  const [trackedCalendlyMeetingNames, setTrackedCalendlyMeetingNames] = useState<string[]>([]);
  const [selectedMeetingName, setSelectedMeetingName] = useState<string>('');
  const { showMessage } = useSnackbar();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const checkIntegrationStatus = async () => {
      isCalendlyIntegrated();

      // wait 3 seconds, then check again
      await new Promise(resolve => setTimeout(resolve, 3000));
      isCalendlyIntegrated();
    }
    checkIntegrationStatus();
  }, []);

  useEffect(() => {
    getTrackedCalendlyMeetingName();
  }, [calendlyIsIntegrated]);

  const handleDisconnectCalendly = async () => {
    try {
      setLoading(true);
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      if (!backendUrl) {
        throw new Error('Backend URL not found');
      }

      // Assuming user is defined somewhere in the component
      const userId = user?.id;

      if (!userId) {
        throw new Error('User ID not found');
      }

      const response = await axios.post(
        `${backendUrl}/api/integrations/calendly/disconnect`,
        { userId },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );

      if (response.status === 200) {
        setCalendlyIsIntegrated(response.data.status !== 'disconnected');
        console.log('Successfully disconnected from Calendly');
      } else {
        console.log('Failed to disconnect from Calendly');
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        showMessage('Session has expired', 'error');
        dispatch(sessionExpired(true) as any);
      } else {
        console.error('Error disconnecting from Calendly:', error);
        // showMessage('Failed to disconnect from Calendly', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const isCalendlyIntegrated = async () => {
    try {
      setLoading(true);
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      if (!backendUrl) {
        throw new Error('Backend URL not found');
      }

      const userId = user?.id;

      if (!userId) {
        throw new Error('User ID not found');
      }

      const response = await axios.get(`${backendUrl}/api/calendly/check-if-integrated`, {
        params: {
          userId,
        },
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });

      setCalendlyIsIntegrated(response.data);
    } catch (error: any) {
      if (error.response?.status === 401) {
        showMessage('Session has expired', 'error');
        dispatch(sessionExpired(true) as any);
      } else {
        console.error('Error checking Calendly integration:', error);
        // showMessage('Failed to check Calendly integration', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const getTrackedCalendlyMeetingName = async () => {
    setLoading(true);
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      if (!backendUrl) {
        throw new Error('Backend URL not found');
      }

      const userId = user?.id;

      if (!userId) {
        throw new Error('User ID not found');
      }

      const response = await axios.get(`${backendUrl}/api/calendly/meeting-names`, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
        params: {
          userId,
        },
      });

      setTrackedCalendlyMeetingNames(response.data.meetingNames);
    } catch (error: any) {
      if (error.response?.status === 401) {
        showMessage('Session has expired', 'error');
        dispatch(sessionExpired(true) as any);
      } else {
        setCalendlyIsIntegrated(false);

        // Only show error message if Calendly is integrated
        if (calendlyIsIntegrated) {
          console.error('Error getting tracked Calendly meeting names:', error);
          // showMessage('Failed to get tracked Calendly meeting names', 'error');
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const setTrackedCalendlyMeetingName = async (selectedMeetingNameInput: string) => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      if (!backendUrl) {
        throw new Error('Backend URL not found');
      }

      if (!user?.id) {
        throw new Error('User ID not found');
        // logout
      }

      const userId = user.id;

      const response = await axios.post(`${backendUrl}/api/calendly/update-meeting-name`, 
      {
        userId: userId,
        meetingName: selectedMeetingNameInput
      },
      {
        headers: {
          'Authorization': `Bearer ${user?.token}`,
        },
      });

      if (response.data.message === "OK") {
        showMessage("Successfully updated tracked meeting name");
      } else {
        // showMessage("Failed to update meeting name");
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        showMessage('Session has expired', 'error');
        dispatch(sessionExpired(true) as any);
      } else {
        console.error('Error exchanging code for token:', error);
        // showMessage('Failed to update meeting name', 'error');
      }
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    if (!event.target.value) {
      return;
    }
    setSelectedMeetingName(event.target.value as string);
    setTrackedCalendlyMeetingName(event.target.value as string);
  };

  const handleCalendlyIntegration = () => {
    if (!loading && !calendlyIsIntegrated) {
      const clientId = process.env.REACT_APP_CALENDLY_CLIENT_ID; // Replace with your actual Calendly client ID

      if (!clientId) {
        console.error('Calendly client ID not set');
        throw new Error('Calendly client ID not set');
      }

      const calendlyRedirectUrl = `${process.env.REACT_APP_FRONTEND_URL}/calendly/auth`;

      const redirectUri = encodeURIComponent(calendlyRedirectUrl); // Replace with your actual redirect URI
      const calendlyAuthUrl = `https://auth.calendly.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}`;
      window.location.href = calendlyAuthUrl;
    }
  };

  const StyledIcon = styled(Icon)`
    width: 80px;
    height: 80px;
    margin-bottom: 1rem;
  `;

  return (
    <IntegrationCard>
      <StyledIcon />
      <h3>{name}</h3>
      <p>{subtitle}</p>
      {loading && (
        <StyledProgressContainer>
          <StyledCircularProgress size={12} />
        </StyledProgressContainer>
      )}
      {!loading && calendlyIsIntegrated && (
        <>
          <StyledFormBox sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="select-meeting-name-label">Meeting name</InputLabel>
              <Select
                labelId="select-meeting-name-label"
                id="select-meeting-name"
                value={selectedMeetingName}
                label={selectedMeetingName || 'Tracked meeting name'}
                onChange={handleChange}
              >
                {trackedCalendlyMeetingNames?.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </StyledFormBox>
          <StyledDisconnectButton onClick={handleDisconnectCalendly}>
            Disconnect Calendly
          </StyledDisconnectButton>
        </>
      )}
      {!loading && !calendlyIsIntegrated && <StyledIntegrateButton onClick={handleCalendlyIntegration}>Connect Calendly</StyledIntegrateButton>}
    </IntegrationCard>
  );
};

export default CalendlyIntegrationCard;
