import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setCalendlyOAuthToken } from '../auth/authSlice';
import { AppDispatch, RootState } from '../store';
import { get } from 'http';
import { FrontendUser } from '../types';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from '../providers/SnackbarProvider';
import styled from 'styled-components';
import { ReactComponent as CalendlyIntegrationLogo } from "../assets/calendly.svg";
import LoadingWithSubtitle from '../components/subcomponents/LoadingWithSubtitle';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const LogoContainer = styled.div`
  margin-bottom: 24px;

  svg {
    width: 100px; /* Adjust the width as needed */
    height: auto;
  }
`;

const CalendlyIntegration: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [token, setToken] = useState<string | null>(null);
  const [userData, setUserData] = useState<any>(null);
  const [alreadyExchanged, setAlreadyExchanged] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const { showMessage } = useSnackbar();
  const [isIntegratingCalendly, setIsIntegratingCalendly] = useState(true);
  const [isExchangingCodeForToken, setIsExchangingCodeForToken] = useState(false);
  const [isGettingIntegrationStatus, setIsGettingIntegrationStatus] = useState(false);

  useEffect(() => {
    const code = searchParams.get('code');
    if (code && !alreadyExchanged) {
      exchangeCodeForToken(code);
      setAlreadyExchanged(true); // Add state to track if the token exchange has been done
  
      // Function to continuously check integration status until it's complete
      const checkIntegrationStatus = async () => {
        try {
          for (let i = 0; i < 5; i++) {
            await getIntegrationStatus();
            await new Promise(resolve => setTimeout(resolve, 5000)); // wait for 5 seconds before checking again
          }
        } catch (error: any) {
          console.error('Error checking integration status:', error);
          // showMessage('Error checking integration status', 'error');
        }
      };
  
      checkIntegrationStatus();
    } else {
      console.error('No code found in URL or code already exchanged');
      // Handle redirection or error display
    }
  }, [searchParams, alreadyExchanged, isIntegratingCalendly]);

  useEffect(() => {
    if (!isIntegratingCalendly) {
      navigate('/settings');
    }
  }, [isIntegratingCalendly]);

  const getIntegrationStatus = async () => {
    try {
      if (isGettingIntegrationStatus) {
        console.log("Already getting integration status");
        return;
      }

      setIsGettingIntegrationStatus(true);
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      if (!backendUrl) {
        throw new Error('Backend URL not found');
      }

      if (!user?.id) {
        throw new Error('User ID not found');
      }

      const userId = user.id;

      const response = await axios.get(`${backendUrl}/api/calendly/integration-status?userId=${userId}`, 
      {
        headers: {
          'Authorization': `Bearer ${user?.token}`,
        },
      });

      if (response.status === 200) {
        const calendlyIntegrationStatus = response.data.status;
        if (calendlyIntegrationStatus === 'connected') {
          setIsIntegratingCalendly(false);
        } else {
          setIsIntegratingCalendly(true);
        }
      } else {
        // showMessage('Failed to get Calendly integration status', 'error');
      }
    } catch (error: any) {
      console.error('Error exchanging code for token:', error);
      // showMessage('Failed to disconnect from Calendly', 'error');
    } finally {
      setIsGettingIntegrationStatus(false);
    }
  };

  const exchangeCodeForToken = async (code: string) => {
    try {
      if (isExchangingCodeForToken) {
        console.log("Already exchanging code for token");
        return;
      }
      setIsExchangingCodeForToken(true);

      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      if (!backendUrl) {
        throw new Error('Backend URL not found');
      }

      if (!user?.id) {
        throw new Error('User ID not found');
        // logout
      }

      // get userId from first part of email before @
      const userId = user.id;

      const response = await axios.post(`${backendUrl}/api/exchange-code`, { code, userId }, 
      {
        headers: {
          'Authorization': `Bearer ${user?.token}`,
        }
      });
      // Process the response, store the access token, etc.
      const calendlyToken: string = response.data.token;
      if (calendlyToken) {
        dispatch(setCalendlyOAuthToken(calendlyToken) as any);
      }
    } catch (error) {
      console.error('Error exchanging code for token:', error);
    } finally {
      setIsExchangingCodeForToken(false);
    }
  };

  return (
    <Container>
      <LogoContainer>
        <CalendlyIntegrationLogo />
      </LogoContainer>
      <LoadingWithSubtitle subtitle="Integrating with Calendly, please wait..." size={40} />
    </Container>
  );
};

export default CalendlyIntegration;
