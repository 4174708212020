export const enum Colors {
  confirmedColor1 = "#53C43B", // Green
  darkerGreen1 = "#3A8D2C",
  otherColor1 = "#BC4A43", // Red
  intermediateColor1 = "#F9D71C", // Yellow
  grey1 = "#757575",
  grey2 = "#6c757d",
  blue1 = "#007bff",
  white = "#ffffff",
};
