import React, { useEffect, createContext, useContext, ReactNode, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../store';
import { setUser, clearUser, setCheckingAuth } from './authSlice';
import LoadingIndicator from '../LoadingIndicator';
import { CognitoUserPool, CognitoUserSession, CognitoUser, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { userPool } from './cognitoConfig';
import { AuthState, FrontendUser } from '../types';

interface AuthContextType {
  auth: AuthState;
  dispatch: AppDispatch;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const authState = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    dispatch(setCheckingAuth(true) as any);
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.getSession((err: Error | null, session: CognitoUserSession | null) => {
        if (err || !session || !session.isValid()) {
          if (session && session.getRefreshToken()) {
            refreshCognitoSession(cognitoUser);
          } else {
            dispatch(clearUser() as any);
          }
        } else {
          const user = mapCognitoSessionToUser(session);
          dispatch(setUser(user) as any);
        }
        dispatch(setCheckingAuth(false) as any);
        setIsInitialized(true);
      });
    } else {
      dispatch(clearUser() as any);
      dispatch(setCheckingAuth(false) as any);
      setIsInitialized(true);
    }
  }, [dispatch]);

  const refreshCognitoSession = (cognitoUser: CognitoUser) => {
    const refreshToken = cognitoUser?.getSignInUserSession()?.getRefreshToken().getToken();
    if (!refreshToken) {
      dispatch(clearUser() as any);
      return;
    }
    cognitoUser.refreshSession(new CognitoRefreshToken({ RefreshToken: refreshToken }), (err, session) => {
      if (err) {
        dispatch(clearUser() as any);
      } else {
        const user = mapCognitoSessionToUser(session);
        dispatch(setUser(user) as any);
      }
      dispatch(setCheckingAuth(false) as any);
    });
  };

  // Always return a JSX element
  if (!isInitialized || authState.checkingAuth) {
    return <LoadingIndicator />;
  }

  return (
    <AuthContext.Provider value={{ auth: authState, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

const mapCognitoSessionToUser = (session: CognitoUserSession): FrontendUser => {
  return {
    id: session.getIdToken().payload.sub,
    email: session.getIdToken().payload.email,
    token: session.getIdToken().getJwtToken(),
  };
};
