import Avatar from '@mui/material/Avatar';
import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import { AssistantPurposes } from '../types';

type AssistantModalProps = {
  assistant: any;
  open: boolean;
  onClose: () => void;
};

const AssistantModal: React.FC<AssistantModalProps> = ({ assistant, open, onClose }) => {

  const assistantPurpose: string = AssistantPurposes[assistant.purpose as keyof typeof AssistantPurposes];

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Assistant Information
        <IconButton onClick={onClose} style={{ position: 'absolute', right: '10px', top: '10px' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center" padding={2}>
          <Avatar src={assistant.avatarUrl} alt={assistant.name} sx={{ width: 80, height: 80, mb: 2 }} />
          <Typography variant="h6">{assistant.name}</Typography>
          <DialogContentText>Bot type purpose: {assistantPurpose}</DialogContentText>
          <br />
          <DialogContentText>Bot description: {assistant.description}</DialogContentText>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AssistantModal;
