import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Box, Typography, Paper, TextField, Button, CircularProgress, Tooltip, Avatar } from '@mui/material';
import { CallOutcomes, FrontendCall, FrontendText, FrontendUser, TextThreadMessage, WaitlistTextStates } from '../../types';
import { formatIsoToCustomDateStringWithEEEEHHMMA, textStateColors, textStateIcons } from '../../utils';
import { CallOutcomeAvatarTooltip, GiantEventAvailableIcon, GiantEventBusyIcon, PatientStatusAvatarTooltip, StatusMessage, StatusMessageVariantOne, StyledToggleButton, StyledToggleButtonVariantOne } from '../styles';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import { Colors } from '../../Colors';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useParams } from 'react-router-dom';
import AudioScrubber from '../AudioScrubber';

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 100%;
  background-color: #f9f9f9;
  border-radius: 8px;
  overflow: hidden;
`;

const CallTranscriptContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 16px;
`;

const TextMetadata = styled(Typography)`
  font-size: 12px;
  color: #666;
  text-align: right;
`;

const StyledHeading = styled(Typography)`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 16px;
`;

interface TextMessageProps {
  role: "assistant" | "patient";
}

const TextMessage = styled(({ role, ...otherProps }: TextMessageProps & React.ComponentProps<typeof Paper>) => (
  <Paper {...otherProps} />
))`
  margin-bottom: 16px;
  margin-left: 10px;
  padding: 16px;
  background-color: ${(props: any) => (props.role === 'assistant' ? '#e0f7fa' : '#ffebee')};
`;

const InputContainer = styled(Box)`
  display: flex;
  padding: 16px;
  background-color: #fff;
  border-top: 1px solid #ddd;
`;


const StyledButton = styled(Button)`
  max-height: 60px;
  background-color: #007bff;
  color: #fff;
  margin-left: 8px;
  &:hover {
    background-color: #0056b3;
  }
  &:active {
    background-color: #004494;
  }
  &:disabled {
    background-color: #d6d6d6;
    color: #a0a0a0;
  }
`;

interface CallMetadataDisplayProps {
  frontendCall: FrontendCall;
}

const CallMetadataDisplay: React.FC<CallMetadataDisplayProps> = ({ frontendCall }) => {
  const [frontendCallState, setFrontendCallState] = useState(frontendCall);
  const { waitlistPatientId } = useParams<{ waitlistPatientId: string }>();
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const { showMessage } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const audioContextRef = useRef<AudioContext>(new AudioContext());
  const [audioBuffer, setAudioBuffer] = useState<AudioBuffer | null>(null);
  const [audioLoading, setAudioLoading] = useState(false);
  const [processingMessage, setProcessingMessage] = useState<string>('');

  const [transcriptLines, setTranscriptLines] = useState<any>([]);

  useEffect(() => {
    if (frontendCall?.transcript) {
      const lines = frontendCall.transcript.split('\n').map((line: string, index: number) => {
        const isAI = line.trim().startsWith('assistant:');
        return (
          <Typography key={index} component="div" sx={{ color: isAI ? 'blue' : 'inherit' }}>
            {line}
          </Typography>
        );
      });
      setTranscriptLines(lines);
    } else {
      setTranscriptLines([]);
    }
  }, []);

  useEffect(() => {
    loadAudio('', audioContextRef.current, setAudioBuffer);
  }, []);

  const loadAudio = async (range: string, audioContext: AudioContext, setAudioBuffer: any) => {
    try {
      setAudioLoading(true);
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${user?.token}`);
      headers.append("user_id", user?.id!);
      if (range) {
        headers.append("Range", range);
      }
  
      const callId = frontendCall?.callId;
      if (callId) {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/streamMp3File?callId=${callId}`, { headers });
        if (response.status === 202) {  // Assuming 202 means still processing
          setProcessingMessage('Call still processing. Please check back later for call recording');
          return;
        }
        const arrayBuffer = await response.arrayBuffer();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        setAudioBuffer(audioBuffer);
        setProcessingMessage('');
      } else {
        console.error('Failed to load audio: callId not found');
        // showMessage('Failed to load audio. Please try again later.', 'error');
      }
    } catch (error: any) {
      console.error('Failed to load and play audio:', error);
      // showMessage('Failed to load and play audio. Please try again later.', 'error');
    } finally {
      setAudioLoading(false);
    }
  };  

  return (
    <>
      <Container>
        <CallTranscriptContainer>
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <StyledHeading sx={{ margin: 0 }}>
                Call transcript
                { audioLoading && <CircularProgress /> }
                {!audioLoading && audioBuffer && (
                  <AudioScrubber audioBuffer={audioBuffer} audioContext={audioContextRef.current} />
                )}
                {!audioLoading && !audioBuffer && processingMessage && <p>{processingMessage}</p>}
              </StyledHeading>
              {frontendCall?.outcome && (frontendCall?.outcome === CallOutcomes.confirmed || frontendCall?.outcome === CallOutcomes.declined) && (
                <Box sx={{ ml: 2, mb: 2 }}>
                  <CallOutcomeAvatarTooltip callOutcome={frontendCall.outcome as CallOutcomes} />
                </Box>
              )}
            </Box>
            {transcriptLines}
          </>
        </CallTranscriptContainer>
      </Container>
    </>
  );
};

export default CallMetadataDisplay;
