import React, { useEffect, useState } from 'react';
import { Box, Button as MuiButton, TextField, TextFieldProps, FormControl, InputLabel, Select, MenuItem, Typography, List, ListItem, ListItemText, IconButton, Modal } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DateTime } from 'luxon';
import { Button } from 'reactstrap';
import { Colors } from '../../Colors';
import styled from 'styled-components';
import { formatIsoToCustomDateStringWithEEEE } from '../../utils';
import { Organization, ShiftOptions, Choices } from '../../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import LoadingWithSubtitle from '../subcomponents/LoadingWithSubtitle';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import PhoneNumberField from './PhoneNumberField';

const StyledButton = styled(MuiButton)`
  padding-top: 10px;
  margin-top: 16px;
  background-color: #1976d2;
  color: white;

  &:hover {
    background-color: #115293;
  }
`;

interface AddPatientModalProps {
  open: boolean;
  onClose: (open: boolean) => void;
  onSuccess: () => void;
  patientName: string;
  setPatientName: (name: string) => void;
  primaryProvider: string;
  setPrimaryProvider: (provider: string) => void;
  phoneNumber: string;
  setPhoneNumber: (string: string) => void;
  appointmentDates: string[];
  setAppointmentDates: (dates: string[]) => void;
  handleRemoveDate: (index: number) => void;
  appointmentLength: number;
  setAppointmentLength: (length: number) => void;
  priority: boolean;
  setPriority: (priority: boolean) => void;
  prefersShift: ShiftOptions;
  setPrefersShift: (prefersMorning: ShiftOptions) => void;
  errorMessage: string;
  loading: boolean;
}

const StyledAddDateButton = styled(Button)`
  background-color: #1976d2; // This is a shade of blue, adjust the color to fit your design
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  text-transform: none; // Removes uppercase transformation if that's the default in your theme
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
  &:hover {
    background-color: #115293; // Darker shade of the button color for hover state
  }
  &:active {
    background-color: #0d3c6e; // Even darker shade for the active state
  }
  &:disabled {
    background-color: #cccccc; // Grey out the button if disabled
    color: #666666;
  }
`;

const AddPatientModal: React.FC<AddPatientModalProps> = ({
  open, onClose, onSuccess, patientName, setPatientName, primaryProvider, setPrimaryProvider,
  phoneNumber, setPhoneNumber, appointmentDates, setAppointmentDates, handleRemoveDate,
  appointmentLength, setAppointmentLength, priority, setPriority, prefersShift, setPrefersShift, errorMessage, loading
}) => {
  const organization: Organization | null = useSelector((state: RootState) => state.organization.organization);
  const [physicianNames, setPhysicianNames] = useState<string[]>(organization?.physicianNames || []);
  const [localErrorMessage, setLocalErrorMessage] = useState<string>('');
  const [dateRange, setDateRange] = useState<[DateTime | null, DateTime | null]>([null, null]);
  const [firstDate, setFirstDate] = useState<DateTime | null>(null);

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [value, setValue] = useState<[DateTime | null, DateTime | null]>([null, null]);

  const handleCalendarChange = (newValue: [DateTime | null, DateTime | null]) => {
    setValue(newValue);

    if (newValue[0] && !newValue[1]) {
      // User selects only the start date
      setFirstDate(newValue[0]);
    } else if (newValue[1]) {
      // User completes the date range
      setCalendarOpen(false);
      let start = (newValue[0] || firstDate)!.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      let end = newValue[1]!.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      let dates: string[] = [];

      while (start <= end) {
        const dateTimes: string[] = appointmentDates.map((date: string) => DateTime.fromISO(date, { setZone: true }).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })).map((date: DateTime) => date.toISO()!);
        const newStart = start.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        if (!dateTimes.includes(newStart.toISO()!) && start.weekday !== 6 && start.weekday !== 7) { 
          // Exclude weekends (Saturday is 6, Sunday is 7)
          dates.push(start.toISO()!);
        }
        start = start.plus({ days: 1 });
      }

      setAppointmentDates(Array.from(new Set([...appointmentDates, ...dates.filter(date => !appointmentDates.includes(date))])));
      setValue([null, null]); // Reset date range
    }
  };

  useEffect(() => {
    return () => {
      setLocalErrorMessage('');
    }
  }, []);

  useEffect(() => {
    return () => {
      setDateRange([null, null]);
    };
  }, []);

  const handleChange = (event: any) => {
    const { target: { value } } = event;
    const valueArray = typeof value === 'string' ? value.split(',') : value;
    const filteredValues = valueArray.filter((v: any) => v.trim() !== '');
    setPrimaryProvider(filteredValues.join(','));
  };

  useEffect(() => {
    if (!organization) return;
    setPhysicianNames(organization?.physicianNames || {});
  }, [organization?.physicianNames]);

  // useEffect(() => {
  //   setAppointmentDates(Array.from(new Set(appointmentDates)));
  // }, [appointmentDates]);

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 400,
        borderRadius: 2,
        outline: 'none',
        maxHeight: '90vh',
        overflowY: 'auto',
      }}>
        <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
          Add Patient
        </Typography>
        <br />
        <TextField
          label="Patient name"
          variant="outlined"
          fullWidth
          value={patientName}
          onChange={(e) => setPatientName(e.target.value)}
          sx={{ mb: 2 }}
        />
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="pcpName-label">Primary provider</InputLabel>
          <Select
            labelId="pcpName-label"
            id="pcpName"
            multiple
            value={primaryProvider.split(',') || Choices.noPreference}
            label="Primary provider"
            onChange={handleChange}
            renderValue={(selected) => selected.join(', ')}
          >
            { physicianNames.map((pt) => (
              <MenuItem value={pt} key={pt}>{pt}</MenuItem>
            )) }
            <MenuItem value={Choices.noPreference}>No preference</MenuItem>
          </Select>
        </FormControl>
        <PhoneNumberField phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DateRangePicker
            value={value}
            onChange={handleCalendarChange}
            localeText={{ start: 'Start date', end: 'End date' }}
          />
        </LocalizationProvider>
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {appointmentDates.map((date, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveDate(index)}>
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText primary={formatIsoToCustomDateStringWithEEEE(date)} />
            </ListItem>
          ))}
        </List>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="appointment-length-label">Length</InputLabel>
          <Select
            labelId="appointment-length-label"
            id="appointment-length"
            value={appointmentLength}
            onChange={(e: any) => setAppointmentLength(parseInt(e.target.value))}
          >
            <MenuItem value={30}>30 mins</MenuItem>
            <MenuItem value={40}>40 mins</MenuItem>
            <MenuItem value={45}>45 mins</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="priority-label">Priority</InputLabel>
          <Select
            labelId="priority-label"
            id="priority"
            value={String(priority)}
            onChange={(e: any) => setPriority(e.target.value === 'true')}
          >
            <MenuItem value="true">Yes</MenuItem>
            <MenuItem value="false">No</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="shiftOptions-label">AM/PM</InputLabel>
          <Select
            labelId="shiftOptions-label"
            id="shiftOptions"
            value={prefersShift}
            onChange={(e: any) => setPrefersShift(e.target.value as ShiftOptions)}
          >
            <MenuItem value={ShiftOptions.AM}>AM</MenuItem>
            <MenuItem value={ShiftOptions.PM}>PM</MenuItem>
            <MenuItem value={ShiftOptions.anyShift}>AM/PM</MenuItem>
          </Select>
        </FormControl>
        <StyledButton onClick={() => {
          if (!patientName || !primaryProvider || !phoneNumber || appointmentDates.length === 0 || appointmentLength === 0) {
            return;
          }
          setLocalErrorMessage('');
          onSuccess();
        }} 
          variant="contained">Add patient</StyledButton>
        <Typography color={Colors.otherColor1} sx={{ mt: 2 }}>{errorMessage}</Typography>
        <Typography color={Colors.otherColor1} sx={{ mt: 2 }}>{localErrorMessage}</Typography>
        {loading && <LoadingWithSubtitle subtitle="Adding patient..." size={40} />}
        { localErrorMessage && <Typography color="error">{localErrorMessage}</Typography> }
      </Box>
    </Modal>
  );
};

export default AddPatientModal;
