// src/pages/Signup.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { signUp } from '../auth/authSlice';
import { TemporaryUserData } from '../types';
import { Link, useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box, Alert } from '@mui/material';
import { ReactComponent as SomnLogoSvg } from "../assets/somnLogo.svg";
import { usePostHog } from 'posthog-js/react'

const SignUp: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [verifyPassword, setVerifyPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const posthog = usePostHog();

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const { user, isSuccess, isError, message } = useSelector((state: RootState) => state.auth);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      posthog?.capture('clicked_sign_up');

      if (password !== verifyPassword) {
        setErrorMsg("Passwords do not match");
        return;
      }

      const userData: TemporaryUserData = { email, password };

      try {
        await dispatch(signUp(userData) as any).unwrap();
        navigate('/confirm');
      } catch (error: any) {
        setErrorMsg(error.message || "An unknown error occurred");
      }
    };

    useEffect(() => {
      if (isSuccess) {
        navigate('/confirm');
      }
    }, [isSuccess, navigate]);

    return (
            <Box boxShadow={5} sx={{ p: 6, position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
          {/* <SomnLogoSvg /> */}

            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >

          <Typography component="h1" variant="h5">Sign Up</Typography>

                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="verifyPassword"
                        label="Verify Password"
                        type="password"
                        id="verifyPassword"
                        value={verifyPassword}
                        onChange={(e) => setVerifyPassword(e.target.value)}
                    />
                    {errorMsg && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            {errorMsg}
                        </Alert>
                    )}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign Up
                    </Button>
                    <Typography variant="body2" align="center">
                        Have an account?
                        <Link to="/login" style={{ textDecoration: 'none', marginLeft: '5px' }}>
                            Log in
                        </Link>
                    </Typography>
                </Box>
            </Box>
        </Box>
        // </Container>
    );
};

export default SignUp;