import React, { useEffect, useRef, useState } from 'react';
import Link from '@mui/material/Link';
import { Card, CardContent, Typography, IconButton, Box, Divider, List, ListItemIcon, ListItemText, ListItem, Avatar, CircularProgress } from '@mui/material';
import AudioPlayer from 'react-audio-player';
import { CallStatusesUi, AssistantTitles, CallOutcomesUi, CallOutcomes, FrontendUser, AssistantMetadata, FrontendCall } from '../types';
import Modal from './Modal';
import AssistantModal from './AssistantModal';
import styled from 'styled-components';
import ErrorIcon from '@mui/icons-material/Error';
import { usePostHog } from 'posthog-js/react';
import AudioScrubber from './AudioScrubber';

// icons
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TimerIcon from '@mui/icons-material/Timer';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotesIcon from '@mui/icons-material/Notes';
import { OvalOutlineListItem, TranscriptContainer, TranscriptButton } from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCalls } from '../slices/callsSlice';
import { selectAssistants } from '../slices/assistantsSlice';
import { useSnackbar } from '../providers/SnackbarProvider';
import { RootState } from '../store';

const CallDetail: React.FC = () => {
  const { callId } = useParams<{ callId: string }>();
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const posthog = usePostHog();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [audioLoading, setAudioLoading] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [duration, setDuration] = useState<number | null>(null);
  const [transcriptLines, setTranscriptLines] = useState<any>([]);
  const [patientFirstName, setPatientFirstName] = useState('');
  const [patientLastName, setPatientLastName] = useState('');
  const [callOutcome, setCallOutcome] = useState('');
  const { showMessage } = useSnackbar();
  const calls: FrontendCall[] = useSelector(selectCalls);
  const assistants: AssistantMetadata[] = useSelector(selectAssistants);
  const [call, setCall] = useState<FrontendCall | undefined>(undefined);
  const [assistant, setAssistant] = useState<AssistantMetadata | null | undefined>(null);
  const audioContextRef = useRef<AudioContext>(new AudioContext());
  const [audioBuffer, setAudioBuffer] = useState<AudioBuffer | null>(null);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const sourceRef = useRef<AudioBufferSourceNode | null>(null);

  useEffect(() => {
    if (!call) {
      setCall(calls.find((c: FrontendCall | undefined) => c?.callId === callId));
    }
  }, []);

  if (!calls) {
    // fetch data
    showMessage('Calls not found', 'error');
  }

  // const handlePlayAudio = async () => {
  //   try {
  //     if (user?.id && user?.token && audioContextRef.current) {
  //       await loadAndPlayAudio(user?.id, user?.token, `${process.env.REACT_APP_BACKEND_URL}/streamMp3File?callId=${call?.callId}`, audioContextRef.current);
  //     }
  //   } catch (error: any) {
  //     console.error('Failed to play audio:', error.message);
  //     showMessage('Failed to play audio. Please refresh and try again later.', 'error');
  //   }
  // };

  useEffect(() => {
    const call = calls.find((c: FrontendCall) => c.callId === callId);
    const assistant = call ? assistants.find((a: AssistantMetadata) => a.assistantId === call.assistantId) : null;
    setCall(call);
    setAssistant(assistant);
  }, [calls, assistants]);

  useEffect(() => {
    try {
      if (call) {
        setStartDate(new Date(call.createdAt));
        setCallOutcome(call.outcome);
        const transcriptLines = call?.transcript ? call.transcript.split('\n').map((line: string, index: number) => {
          const isAI = line.trim().startsWith('assistant:');
          return (
            <>
            <Typography key={index} component="div" sx={{ color: isAI ? 'blue' : 'inherit' }}>
              {line}
            </Typography>
            </>
          );
        }) : '';
        setTranscriptLines(transcriptLines);
        setPatientFirstName(call?.variables?.patientFirstName);
        setPatientLastName(call?.variables?.patientLastName);
        setDuration(call.callLength);
      }
    } catch (error: any) {
      console.error('Failed to fetch call details:', error.message);
      // showMessage('Failed to fetch call details. Please try again.', 'error');
      navigate('/calls');
    }
  }, [call]);

  useEffect(() => {
    if (call && !loading) {
      const transcriptLines = call?.transcript ? call.transcript.split('\n').map((line: string, index: number) => {
        const isAI = line.trim().startsWith('assistant:');
        return (
          <>
          <Typography key={index} component="div" sx={{ color: isAI ? 'blue' : 'inherit' }}>
            {line}
          </Typography>
          </>
        );
      }) : '';
      setTranscriptLines(transcriptLines);
      setPatientFirstName(call?.variables?.patientFirstName);
      setPatientLastName(call?.variables?.patientLastName);
    }
  }, [call]);

  const startDateUi = startDate ? startDate.toLocaleString() : '';

  const [isTranscriptModalOpen, setTranscriptModalOpen] = useState(false);
  const [isAssistantModalOpen, setAssistantModalOpen] = useState(false);

  // Function to handle opening/closing the transcript modal
  const toggleTranscriptModal = () => {
    // register click in posthog
    posthog?.capture('transcript_modal_opened', { call_id: call?.callId, assistant_id: assistant?.assistantId });
    setTranscriptModalOpen(!isTranscriptModalOpen);
  };

  // Function to handle opening/closing the assistant modal
  const toggleAssistantModal = () => {
    // register click in posthog
    posthog?.capture('assistant_modal_opened', { call_id: call?.callId, assistant_id: assistant?.assistantId, source: 'call_detail' });
    setAssistantModalOpen(!isAssistantModalOpen);
  }

  const confirmedColor = "#53C43B"; // Green
  const errorColor = "#bc4a43"; // Red

  const renderIconBasedOnOutcome = (outcome: string) => {
    switch (outcome) {
      case CallOutcomes.accepted:
      case CallOutcomes.confirmed:
      case CallOutcomes.scheduled:
      case CallOutcomes.rescheduled:
        case CallOutcomes.voicemail:
        return <CheckCircleIcon style={{ color: confirmedColor }} />;
      case CallOutcomes.requested_callback:
      case CallOutcomes.transferred_to_human:
      case CallOutcomes.unsuccessful:
        return <ErrorIcon style={{ color: errorColor }} />;
      default:
        return null; // or any default icon
    }
  };

  function convertToString(details: any) {
    if (typeof details === 'object' && details !== null) {
      return Object.entries(details)
        .map(([key, value]) => `${capitalizeFirstLetter(key.replace(/_/g, ' '))}: ${value}`)
        .join('\n');
    }
    return details;
  }
  
  function capitalizeFirstLetter(string: any) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const callDetails = typeof(call?.outcomeDetails) === "object" && call?.outcomeDetails !== null ? convertToString(call?.outcomeDetails) : call?.outcomeDetails;

  const handleBack = () => {
    const origin = localStorage.getItem('origin');
    if (origin === 'assistant_history') {
      localStorage.removeItem('origin');
      navigate(`/assistants/${call?.assistantId}`); // Navigate back to the assistant history
    } else {
      navigate('/calls');
    }
  };

  useEffect(() => {
    loadAudio('', audioContextRef.current, setAudioBuffer);
  }, []);

  const loadAudio = async (range: string, audioContext: AudioContext, setAudioBuffer: any) => {
    try {
      setAudioLoading(true);
      // Create an instance of Headers to append our custom headers
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${user?.token}`);
      headers.append("user_id", user?.id!);
      if (range) {
        headers.append("Range", range);
      }
  
      // Fetch the audio file from the server
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/streamMp3File?callId=${callId}`, { headers });
      const arrayBuffer = await response.arrayBuffer();
  
      // Decode the audio data and set up the audio context
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  
      // Use a setter function to update the audio buffer state if using React, or handle accordingly
      setAudioBuffer(audioBuffer);
    } catch (error: any) {
      console.error('Failed to load and play audio:', error);
      // showMessage('Failed to load and play audio. Please try again later.', 'error');
    } finally {
      setAudioLoading(false);
    }
  }

  return (
    <>
      {loading && <CircularProgress />}
      {!loading && call && assistant && (
        <Box sx={{ display: 'flex', width: '100%' }}>
          {/* Main content section on the left */}
          <Box sx={{ flex: 1, paddingRight: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <IconButton onClick={handleBack} sx={{ mr: 2 }}>
                <ArrowBackIcon />
              </IconButton>
            </Box>
            <Typography gutterBottom variant="h4" component="div" sx={{ my: 2 }}>
              {`${patientFirstName} ${patientLastName}`}
            </Typography>
            <OvalOutlineListItem maxWidth="350px" onClick={toggleAssistantModal}>
              <ListItemIcon>
                <Avatar src={assistant?.avatarUrl} alt={assistant?.name} sx={{ width: 30, height: 30 }} />
              </ListItemIcon>
              { assistant && 
                <ListItemText 
                  primary="Called by" 
                  secondary={assistant ? `${assistant.name} (${AssistantTitles[assistant.title]})` : ''} 
                />
              }
            </OvalOutlineListItem>
            <ListItem>
              <ListItemIcon>
                <AccessTimeIcon />
              </ListItemIcon>
              <ListItemText primary="Call date" secondary={startDateUi} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <TimerIcon />
              </ListItemIcon>
              <ListItemText primary="Call duration" secondary={`${duration ? duration.toFixed(2) : 'Unknown'} minutes`} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FingerprintIcon />
              </ListItemIcon>
              { call && <ListItemText primary="Call ID" secondary={call?.callId} /> }
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <LocalPhoneIcon />
              </ListItemIcon>
              { call && <ListItemText primary="Call status" secondary={CallStatusesUi[call.status as keyof typeof CallStatusesUi] || call.status} /> }
            </ListItem>
            <ListItem>
              <ListItemIcon>
                {renderIconBasedOnOutcome(call.outcome)}
              </ListItemIcon>
              <ListItemText primary="Call outcome" secondary={callOutcome} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                {renderIconBasedOnOutcome(call.outcome)}
              </ListItemIcon>
              <ListItemText primary="Call outcome justification" secondary={call.outcomeJustification} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                {renderIconBasedOnOutcome(call.outcome)}
              </ListItemIcon>
              <ListItemText primary="Call outcome details" secondary={callDetails} />
            </ListItem>
            <IconButton aria-label="call recording" sx={{ position: 'absolute', top: '10px', right: '10px' }}>
              <RecordVoiceOverIcon />
            </IconButton>
      
            {/* Assistant Information Modal */}
            <AssistantModal
              assistant={assistant}
              open={isAssistantModalOpen}
              onClose={toggleAssistantModal}
            />
          </Box>
      
          {/* Transcript and recording section on the right */}
          <Box
            sx={{
              flex: 1,
              paddingLeft: 2,
              maxWidth: '50%',
              alignSelf: 'flex-start' // Adjusts vertical alignment to be at the top of the flex container
            }}
            style={{
              marginTop: '64px' // Adjust this value to lower the right box as needed
            }}
          >
            { audioLoading && <CircularProgress /> }
            { !audioLoading && audioBuffer && (
            <AudioScrubber audioBuffer={audioBuffer} audioContext={audioContextRef.current} />
            )}
            <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
              Summary: {call.summary}
            </Typography>

            <TranscriptButton onClick={toggleTranscriptModal}>
              <NotesIcon />
              View Transcript
            </TranscriptButton>

            <Modal
              title="Call Transcript"
              open={isTranscriptModalOpen}
              onClose={toggleTranscriptModal}
            >
              {transcriptLines}
            </Modal>
          </Box>
        </Box>
        )}
    </>
  );
};

export default CallDetail;
