import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store';
import axios from 'axios';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { FrontendUser, WaitlistUserOverrideAction } from '../../types';
import { sessionExpired } from '../../slices/sessionSlice';

const DropdownStatusSelector: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { waitlistRunId } = useParams<{ waitlistRunId: string }>();
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const { showMessage } = useSnackbar();
  const dispatch = useDispatch<AppDispatch>();

  const handleStatusChange = async (action: WaitlistUserOverrideAction) => {
    try {
      console.log("Action: ", action);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/waitlist/${waitlistRunId}/override`, {
        overrideAction: action,
      }, {
        headers: {
          'Authorization': `Bearer ${user?.token}`,
        }
      });

      if (response.status === 200) {
        showMessage('Waitlist run status updated successfully', 'success'); 
      } else {
        throw new Error('Failed to update waitlist run status.');
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        dispatch(sessionExpired(true) as any);
      }
      console.error('Error updating waitlist run status.', error);
      // showMessage('Failed to update waitlist run status. Please try again.', 'error');
    }
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action: WaitlistUserOverrideAction) => {
    handleStatusChange(action);
    handleClose();
  };

  return (
    <>
      <Tooltip title="Change status">
        <IconButton onClick={handleClick} size="small">
          <ArrowDropDownIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleMenuItemClick(WaitlistUserOverrideAction.complete)}>End</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick(WaitlistUserOverrideAction.quietlyComplete)}>End quietly</MenuItem>
      </Menu>
    </>
  );
};

export default DropdownStatusSelector;
