export type ScheduledCall = {
  scheduledCallId: string;
  assistantId: string;
  organizationId: string;
  scheduledDate: string;
  enqueuedDate?: string;
  executedDate?: string;
  variables: Record<string, any>;
  retries?: number;
};

export interface CallActionProps {
  color: string;
}

export enum AssistantView {
  history = "history",
  queue = "queue",
  runs = "runs",
  waitlist = "waitlist",
  fax = "fax",
}

export enum WaitlistPatientStatus {
  confirmed = "confirmed",
  declined = "declined",
  inProgress = "in-progress",
  initialContact = "initial-contact",
  notContacted = "not-contacted",
  noResponse = "no-response",
  lateToConfirm = "late-to-confirm",
  unsuccessful = "unsuccessful",
  expired = "expired",
  manualOverride = "manual-override",
};

export enum WaitlistTextStates {
  firstMessageSent = "first-message-sent",
  inProgress = "in-progress",
  accepted = "accepted",
  declined = "declined",
  notContacted = "not-contacted",
  expired = "expired",
  manualOverride = "manual-override",
};

export enum WaitlistCallStates {
  accepted = "accepted",
  declined = "declined",
  voicemail = "voicemail",
  unsuccessful = "unsuccessful",
  notContacted = "not-contacted",
  expired = "expired",
};

export type TextMetadata = {
  textId: string;
  organizationId: string;
  waitlistRunId: string;
  thread: { date: string, message: string, role: "assistant" | "patient" }[];
  patientPhoneNumber: string;
  fromPhoneNumber: string;
  variables: Record<string, any>;
  state: string;
  stateJustification: string;
};

export type WaitlistPatient = {
  waitlistPatientId: string;
  waitlistRunId: string;
  patientFirstName: string;
  patientLastName: string;
  patientPhoneNumber: string;
  status: WaitlistPatientStatus;
  state: WaitlistTextStates | WaitlistCallStates;
  stateJustification: string;
  textMetadata?: FrontendText;
  callMetadata?: FrontendCall;
  automatedTextsEnabled: boolean;
  consentHistory: { consent: Consent, message: string, date: string }[];
};

export enum Consent {
  given = "given",
  notYetGiven = "not-yet-given",
  revoked = "revoked",
};

export enum EHR {
  Calendly = "calendly",
  Healthie = "healthie",
  Elation = "elation",
  Faxplus = "faxplus",
};

export type Organization = {
  organizationId: string;
  name: string;
  addresses: { [key: string]: string };
  adminFirstName: string;
  adminLastName: string;
  adminEmail: string;
  integrations: Record<EHR, string>;
  timezone: string;
  ehr: string;
  ehrProviderId: string;
  bucketName: string;
  phoneNumber: string;
  textingPhoneNumber: string;
  physicianNames: string[];
};

type WaitlistAppointment = {
  id: string;
  pcpName: string;
  appointmentLength: number;
  appointmentDate: string;
  organizationId: string;
  shift: ShiftOptions;
};

export type OpenWaitlistAppointment = WaitlistAppointment;

export type TakenWaitlistAppointment = WaitlistAppointment & {
  patient: WaitlistPatient;
};

export enum ShiftOptions {
  AM = "AM",
  PM = "PM",
  anyShift = "AM/PM"
};

export enum Choices {
  noPreference = "No preference",
}

export type FrontendWaitlistPatient = {
  name: string,
  pcpName: string,
  appointmentLength: number,
  patientPhoneNumber: string,
  priority: boolean,
  prefersShift: ShiftOptions,
  dates: string[], // timezone-aware ISO date strings
  contactMethod?: ('text' | 'call')[],
};

export enum WaitlistOutcomes {
  scheduled = "scheduled",
  unscheduled = "unscheduled",
};

export enum WaitlistOutcomesUi {
  scheduled = "Scheduled",
  unscheduled = "Unscheduled",
};

export enum WaitlistStatusUi {
  started = "Started",
  notStarted = "Not started",
  completed = "Completed",
};

export enum WaitlistActions {
  uploadCsv = "upload-csv",
  uploadWaitlistRun = "upload-waitlist-run",
  runWaitlistTexts = "run-waitlist-texts",
  runWaitlistCalls = "run-waitlist-calls",
  runWaitlistTextsAndCalls = "run-waitlist-texts-and-calls",
  deleteRun = "delete-run",
  getWaitlistRuns = "get-waitlist-runs",
  pauseWaitlistRun = "pause-waitlist-run",
  unpauseWaitlistRun = "unpause-waitlist-run",
};

export enum WaitlistStatus {
  started = "started",
  notStarted = "notStarted",
  completed = "completed",
  paused = "paused",
};

export enum WaitlistUserOverrideAction {
  complete = "completed",
  quietlyComplete = "quietlyComplete",
}

export type WaitlistRun = {
  waitlistRunId: string;
  organizationId: string;
  patients: WaitlistPatient[];
  appointmentDate: string;
  physicianName: string;
  appointmentLength: number;
  outcome: WaitlistOutcomes;
  status: WaitlistStatus;
  createdAt: string;
};

export enum Integrations {
  csv = "csv",
  fax = "fax",
  waitlist = "waitlist",
}; 

export type AssistantDetailTabs = AssistantView | Integrations;

export enum TabName {
  Calls = "Calls",
  Assistants = "Assistants",
  Settings = "Settings",
  SignOut = "Sign out",
}

export type Tab = { 
  name: TabName, 
  icon: any, 
  route: string 
};

export interface Fax {
  url: string, 
  fileName: string,
  bucketName: string,
  faxProvider: string,
  downloadedDate: string,
  enqueuedDate?: string,
  executedDate?: string
  isReferral?: boolean
  isReferralJustification?: string
  scheduledCallId?: string
};

export interface SignUpResult {
  email: string;
  status: string; // 'Pending Verification'
}

// Adjust your initial state to include a placeholder for signUpResult
export interface AuthState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  user: FrontendUser | null; // Assuming FrontendUser is your user model
  message: string;
  signUpResult?: SignUpResult; // Add this line
}

export enum AssistantTitles {
  referral_specialist = "Referral specialist",
  reminder_specialist = "Reminder specialist",
  scheduling_specialist = "Scheduling specialist",
}

export enum AssistantPurposes {
  to_remind = "Reminds patients of their appointments",
  to_schedule = "Schedules appointments",
}

export enum AssistantPurposesOneLiners {
  to_remind = "Reminder specialist",
  to_schedule = "Scheduling specialist",
}

export type CSVRowData = {
  id: string;
  patientFirstName: string;
  patientLastName: string;
  patientEmail: string;
  patientPhoneNumber: string;
  pcpName: string;
  visitType: string;
  callbackNumber: string;
};

export enum IntegrationTypes {
  csv = "csv",
  fax = "fax",
};

export enum CallOutcomesUi {
  confirmed = "Confirmed",
  accepted = "Accepted",
  rescheduled = "Rescheduled to a better time",
  requested_callback = "Requested callback",
  transferred_to_human = "Transferred to human",
  unsuccessful = "Unsuccessful",
  scheduled = "Scheduled",
  voicemail = "Voicemail"
};

export enum CallOutcomes {
  confirmed = "confirmed",
  declined = "declined",
  rescheduled = "rescheduled",
  requested_callback = "requested_callback",
  transferred_to_human = "transferred_to_human",
  unsuccessful = "unsuccessful",
  scheduled = "scheduled",
  voicemail = "voicemail",
  accepted = "accepted"
};

export enum CallStatusesUi {
  complete = "Completed",
  voicemail = "Voicemail",
}

export type AssistantType = {
  assistantType: string;
  outcomes: string[];
  purpose: string;
}

export interface FrontendUser {
  id?: string;
  email: string;
  token?: string;
}

export type TemporaryUserData = { // not saved in the database! Just used for authentication
  email: string;
  password: string;
};

export interface SomnError {
  code: string;
  message: string;
}

export type AssistantMetadata = {
  assistantId: string;
  assistantType: string;
  isActive: boolean;
  language: string;
  name: string;
  organizationId: string;
  voiceId: string;
  purpose: string;
  outcomes: string[];
  avatarUrl: string;
  createdAt: Date;
  lastModified: string;
  promptType: string;
  title: keyof typeof AssistantTitles;
  description: string;
  phoneNumber: string;
  provider: "vapi" | "retell" | "bland";
  integrations: Integrations[];
  prompt: string;
  voicemail: string;
  maxNumberOfRedials: number;
  redialGapMinutes: number;
  promptVariables: Record<string, any>;
  workflowType: Integrations.csv | Integrations.fax | Integrations.waitlist | null;
  waitlistColumnRankings?: string[];
  variableSubRankings?: Record<string, string[]>;
  interruptionThreshold?: number;
};

export type TextThreadMessage = {
  threadMessageId: string, 
  date: string,
  message: string, 
  role: "assistant" | "patient", 
  state?: WaitlistTextStates, 
  stateJustification?: string 
};

export type FrontendText = {
  textId: string;
  organizationId: string;
  waitlistRunId: string;
  thread: TextThreadMessage[];
  patientPhoneNumber: string;
  assistantPhoneNumber: string;
  variables: Record<string, any>;
  state: string;
  stateJustification: string;
  waitlistPatientId: string;
};

export type FrontendCall = {
  avatarUrl: string;
  createdAt: string;
  callLength: number;
  to: string;
  from: string;
  inbound: boolean,
  errorMessage: string | null,
  answeredBy: string;
  summary: string;
  callEndedBy: string;
  callId: string;
  patientFirstName: string;
  patientLastName: string;
  patientEmail: string;
  patientPhoneNumber: string;
  outcome: string;
  outcomeJustification: string;
  outcomeDetails: string;
  transcript: string;
  appointmentDate: string;
  recordingUrl: string;
  assistantId: string;
  status: string;
  variables: Record<string, any>;
};

export type Prompt = {
  type: string;
  content: string;
};

export type User = {
  userId: string;
  agentId: string;
  name: string;
  email: string;
  phoneNumber: string;
  lastModified: string; // ISO 8601 format date string
  companyId: string;
  firstName: string;
  lastName: string;
};

export type CalendlyToken = {
  accessToken: string;
  tokenType: string;
  expiresIn: number;
  refreshToken: string;
  scope: string;
  createdAt: number;
  owner: string;
  organization: string;
  lastModified: string; // ISO 8601 format date string
};

export type Patient = {
  patientId: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  organizationId: string;
  lastModified: string; // ISO 8601 format date string
  createdAt: string; // ISO 8601 format date string
};

// export type CallMetadata = {
//   callDetail: CallDetail;
//   patient: Patient;
//   agentMetadata: AgentMetadata;
//   somnCallDetails: Call;
//   outcome: CallOutcome;
//   callStatus: SomnCallStatus;
// }

export type DynamicObject = {
  [key: string]: any;
};

export type CallOutcome = {
  outcome: string;
};

export type SomnCallStatus = {
  status: string; // Assuming each row in 'callStatuses' table has a 'status' column
};
